
import { computed, defineComponent } from "vue";
import HelloWorld from "./components/HelloWorld.vue";
import { useAuth } from "./composables/auth";
import { useUser } from "./composables/user";
import { useVendor } from "./composables/vendor";
import TheNavBar from "./components/TheNavBar.vue";
import Grid from "./components/loaders/Grid.vue";
import Puff from "./components/loaders/Puff.vue";
import { ExclamationIcon } from "@heroicons/vue/solid";
import Spinner from "./components/Spinner.vue";

const { isLoadingClient, error: authError } = useAuth();
const { user, isLoading: isLoadingUser, error: userError } = useUser();
const { currentVendor, setVendor, error: vendorError } = useVendor();

const error = computed(() => {
  return authError.value || userError.value || vendorError.value || null;
});

export default defineComponent({
  name: "App",
  components: {
    HelloWorld,
    TheNavBar,
    Grid,
    Puff,
    ExclamationIcon,
    Spinner,
  },

  setup() {
    return {
      isLoadingClient,
      isLoadingUser,
      user,
      currentVendor,
      userError,
      vendorError,
      error,
    };
  },
});
