import createAuth0Client, { Auth0Client, User } from "@auth0/auth0-spa-js";
import { readonly, ref } from "vue";

const auth0Client = ref(null as Auth0Client | null);
const token = ref(null as null | string);
const user = ref(null as null | User);
const isLoadingClient = ref(false);
const error = ref(null as null | any);

/**
 * Populates the auth0Client ref
 * @returns
 */
function createClient() {
  if (isLoadingClient.value) return;
  isLoadingClient.value = true;
  createAuth0Client({
    domain: "auth.jc.supply",
    client_id: "9785D8Rycjq2uLx8wrn3sFhhkMVMUWEr",
    audience: "https://concord.jcjewels.com.au",
    redirect_uri: window.location.origin,
    useRefreshTokens: true,
  })
    .then(async (client) => {
      auth0Client.value = client;

      // Load auth token
      await getToken();
    })
    .catch((error) => {
      console.error("Failed creating auth0 client", error);
    })
    .finally(() => {
      isLoadingClient.value = false;
    });
}

async function getToken() {
  if (!auth0Client.value) throw "Client not ready";

  if (token.value) return token.value;

  try {
    token.value = await auth0Client.value.getTokenSilently();
    user.value = (await auth0Client.value.getUser()) || null;
  } catch (err: any) {
    console.error(err);
    if (err.error === "login_required")
      await auth0Client.value.loginWithRedirect();
    else {
      error.value = err;
    }
  }

  return token.value;
}

export function useAuth() {
  if (!auth0Client.value) createClient();

  return {
    isLoadingClient,
    client: readonly(auth0Client),
    token: readonly(token),
    user: readonly(user),
    error: readonly(error),
    logout: async () => {
      let client = auth0Client.value;
      if (client) {
        await client.logout({
          returnTo: "https://vendor.jc.supply",
        });
      }
    },
  };
}
