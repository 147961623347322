import {
  createClient as createClientUrql,
  provideClient as provideClientUrql,
  cacheExchange as defaultCacheExchange,
  dedupExchange,
  fetchExchange,
  errorExchange,
  Client,
} from "@urql/vue";
import {
  CacheExchangeOpts,
  cacheExchange,
  Cache,
  FieldInfo,
} from "@urql/exchange-graphcache";
import { readonly, ref } from "vue";
import { useAuth } from "./auth";

const auth = useAuth();

const SERVICE_URL = process.env.VUE_APP_SERVICE_URL;

const client = ref(null as Client | null);

function createClient() {
  client.value = createClientUrql({
    exchanges: [
      createCacheExchange(),
      // defaultCacheExchange,
      createErrorExchange(),
      dedupExchange,
      fetchExchange,
    ],
    url: SERVICE_URL,
    fetchOptions: () => {
      let headers: [string, string][] = [];

      let token = auth.token.value;

      if (token) headers.push(["Authorization", "Bearer " + token]);

      return {
        headers: Object.fromEntries(headers),
      };
    },
  });
}

export function useUrql() {
  if (!client.value) createClient();

  return {
    urql: readonly(client),
  };
}

/**
 * utility to invalidate cache items based on a predicate function
 * @param cache
 * @param entity
 * @param predicate
 */
function invalidateFieldsBy(
  cache: Cache,
  entity: string,
  predicate: (field: FieldInfo) => boolean
) {
  cache
    .inspectFields(entity)
    .filter(predicate)
    .forEach((field: any) => {
      cache.invalidate("Query", field.fieldKey);
    });
}

function createCacheExchange() {
  return cacheExchange(<Partial<CacheExchangeOpts>>{
    keys: {},
    updates: {
      Mutation: {
        updateOneItemOrder(result: any, args: any, cache: Cache) {
          invalidateFieldsBy(cache, "Query", (field) =>
            field.fieldName.includes("itemOrders")
          );
        },
        createConsigmentForItemOrders(result: any, args: any, cache: Cache) {
          invalidateFieldsBy(cache, "Query", (field) =>
            field.fieldName.includes("itemOrders")
          );
        },
      },
    },
  });
}

function createErrorExchange() {
  return errorExchange({
    onError(error: any, operation: any) {
      console.error("An Error Occurred", error, { operation });
    },
  });
}
