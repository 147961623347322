import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from '@urql/vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  createdAt: Scalars['DateTime'];
  defaultShippingAddress?: Maybe<PhysicalAddress>;
  defaultShippingAddressId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  feedPerspective?: Maybe<DiamondFeedPerspective>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner: Vendor;
  ownerId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type AccountAggregateGroupBy = {
  __typename?: 'AccountAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
};

export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** Array of edges. */
  edges: Array<AccountEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type AccountContact = {
  __typename?: 'AccountContact';
  account: Account;
  accountId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  feedPerspective?: Maybe<DiamondFeedPerspective>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AccountContactAggregateGroupBy = {
  __typename?: 'AccountContactAggregateGroupBy';
  accountId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['ID']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export type AccountContactConnection = {
  __typename?: 'AccountContactConnection';
  /** Array of edges. */
  edges: Array<AccountContactEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type AccountContactCountAggregate = {
  __typename?: 'AccountContactCountAggregate';
  accountId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['Int']>;
  feedPerspectiveId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type AccountContactCreateInput = {
  accountId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type AccountContactDeleteFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<AccountContactDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  externalId?: InputMaybe<IdFilterComparison>;
  feedPerspectiveId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AccountContactDeleteFilter>>;
};

export type AccountContactDeleteResponse = {
  __typename?: 'AccountContactDeleteResponse';
  accountId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountContactEdge = {
  __typename?: 'AccountContactEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the AccountContact */
  node: AccountContact;
};

export type AccountContactFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<AccountContactFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  externalId?: InputMaybe<IdFilterComparison>;
  feedPerspectiveId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AccountContactFilter>>;
};

export type AccountContactMaxAggregate = {
  __typename?: 'AccountContactMaxAggregate';
  accountId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['ID']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export type AccountContactMinAggregate = {
  __typename?: 'AccountContactMinAggregate';
  accountId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['ID']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export type AccountContactSort = {
  direction: SortDirection;
  field: AccountContactSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AccountContactSortFields {
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  FeedPerspectiveId = 'feedPerspectiveId',
  Id = 'id'
}

export type AccountContactUpdateFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<AccountContactUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  externalId?: InputMaybe<IdFilterComparison>;
  feedPerspectiveId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AccountContactUpdateFilter>>;
};

export type AccountContactUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['ID']>;
  feedPerspectiveId?: InputMaybe<Scalars['ID']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type AccountCountAggregate = {
  __typename?: 'AccountCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  feedPerspectiveId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['Int']>;
};

export type AccountCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
};

export type AccountDeleteFilter = {
  and?: InputMaybe<Array<AccountDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  feedPerspectiveId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AccountDeleteFilter>>;
  ownerId?: InputMaybe<IdFilterComparison>;
};

export type AccountDeleteResponse = {
  __typename?: 'AccountDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultShippingAddressId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Account */
  node: Account;
};

export type AccountFilter = {
  and?: InputMaybe<Array<AccountFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  feedPerspectiveId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AccountFilter>>;
  ownerId?: InputMaybe<IdFilterComparison>;
};

export type AccountMaxAggregate = {
  __typename?: 'AccountMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
};

export type AccountMinAggregate = {
  __typename?: 'AccountMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedPerspectiveId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
};

export type AccountSort = {
  direction: SortDirection;
  field: AccountSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AccountSortFields {
  CreatedAt = 'createdAt',
  FeedPerspectiveId = 'feedPerspectiveId',
  Id = 'id',
  OwnerId = 'ownerId'
}

export type AccountUpdateFilter = {
  and?: InputMaybe<Array<AccountUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  feedPerspectiveId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<AccountUpdateFilter>>;
  ownerId?: InputMaybe<IdFilterComparison>;
};

export type AccountUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  feedPerspectiveId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
};

export type ActivityEvent = {
  __typename?: 'ActivityEvent';
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['ID']>;
  authorType: ActivityEventAuthorType;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  topicId: Scalars['ID'];
  type: ActivityEventType;
  updatedAt: Scalars['DateTime'];
};

export type ActivityEventAggregateGroupBy = {
  __typename?: 'ActivityEventAggregateGroupBy';
  authorId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['ID']>;
};

export enum ActivityEventAuthorType {
  System = 'SYSTEM',
  User = 'USER'
}

export type ActivityEventConnection = {
  __typename?: 'ActivityEventConnection';
  /** Array of edges. */
  edges: Array<ActivityEventEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ActivityEventCountAggregate = {
  __typename?: 'ActivityEventCountAggregate';
  authorId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  topicId?: Maybe<Scalars['Int']>;
};

export type ActivityEventDeleteResponse = {
  __typename?: 'ActivityEventDeleteResponse';
  authorId?: Maybe<Scalars['ID']>;
  authorType?: Maybe<ActivityEventAuthorType>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  topicId?: Maybe<Scalars['ID']>;
  type?: Maybe<ActivityEventType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActivityEventEdge = {
  __typename?: 'ActivityEventEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ActivityEvent */
  node: ActivityEvent;
};

export type ActivityEventFilter = {
  and?: InputMaybe<Array<ActivityEventFilter>>;
  authorId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<ActivityEventIdFilterComparison>;
  or?: InputMaybe<Array<ActivityEventFilter>>;
  topicId?: InputMaybe<IdFilterComparison>;
};

export type ActivityEventIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type ActivityEventMaxAggregate = {
  __typename?: 'ActivityEventMaxAggregate';
  authorId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['ID']>;
};

export type ActivityEventMinAggregate = {
  __typename?: 'ActivityEventMinAggregate';
  authorId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['ID']>;
};

export type ActivityEventSort = {
  direction: SortDirection;
  field: ActivityEventSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ActivityEventSortFields {
  AuthorId = 'authorId',
  Id = 'id',
  TopicId = 'topicId'
}

export type ActivityEventTopic = {
  __typename?: 'ActivityEventTopic';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  events: ActivityEventTopicEventsConnection;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vendor: Vendor;
  vendorId: Scalars['String'];
};


export type ActivityEventTopicEventsArgs = {
  filter?: InputMaybe<ActivityEventFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ActivityEventSort>>;
};

export type ActivityEventTopicAggregateGroupBy = {
  __typename?: 'ActivityEventTopicAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['String']>;
};

export type ActivityEventTopicConnection = {
  __typename?: 'ActivityEventTopicConnection';
  /** Array of edges. */
  edges: Array<ActivityEventTopicEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ActivityEventTopicCountAggregate = {
  __typename?: 'ActivityEventTopicCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  entityId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type ActivityEventTopicCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  entityId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type ActivityEventTopicEdge = {
  __typename?: 'ActivityEventTopicEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ActivityEventTopic */
  node: ActivityEventTopic;
};

export type ActivityEventTopicEventsConnection = {
  __typename?: 'ActivityEventTopicEventsConnection';
  /** Array of edges. */
  edges: Array<ActivityEventEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ActivityEventTopicFilter = {
  and?: InputMaybe<Array<ActivityEventTopicFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  entityId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<ActivityEventTopicIdFilterComparison>;
  or?: InputMaybe<Array<ActivityEventTopicFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  vendorId?: InputMaybe<StringFieldComparison>;
};

export type ActivityEventTopicIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type ActivityEventTopicMaxAggregate = {
  __typename?: 'ActivityEventTopicMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['String']>;
};

export type ActivityEventTopicMinAggregate = {
  __typename?: 'ActivityEventTopicMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['String']>;
};

export type ActivityEventTopicSort = {
  direction: SortDirection;
  field: ActivityEventTopicSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ActivityEventTopicSortFields {
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  VendorId = 'vendorId'
}

export type ActivityEventTopicUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum ActivityEventType {
  EntityCreate = 'ENTITY_CREATE',
  EntityUpdate = 'ENTITY_UPDATE',
  EntityUpdateStatus = 'ENTITY_UPDATE_STATUS',
  UserComment = 'USER_COMMENT'
}

export type ActivityEventUpdateInput = {
  body: Scalars['String'];
};

export type AddEventsToActivityEventTopicInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMediaToDiamondProductExternalInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddMediaToDiamondProductInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddUpstreamVendorsToDiamondOnsellControllerInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddVendorsToClientTableConfigInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddVendorsToUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export enum BlackInclusion {
  B0 = 'B0',
  B01 = 'B01',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4'
}

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
};

export enum ClarityGrade {
  Fl = 'FL',
  I1 = 'I1',
  I2 = 'I2',
  I3 = 'I3',
  If = 'IF',
  Si1 = 'SI1',
  Si2 = 'SI2',
  Si3 = 'SI3',
  Vs1 = 'VS1',
  Vs2 = 'VS2',
  Vs3 = 'VS3',
  Vvs1 = 'VVS1',
  Vvs2 = 'VVS2',
  Vvs3 = 'VVS3'
}

export type ClientTableConfig = {
  __typename?: 'ClientTableConfig';
  config?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vendors: Array<Vendor>;
};


export type ClientTableConfigVendorsArgs = {
  filter?: InputMaybe<VendorFilter>;
  sorting?: InputMaybe<Array<VendorSort>>;
};

export type ClientTableConfigAggregateGroupBy = {
  __typename?: 'ClientTableConfigAggregateGroupBy';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
};

export type ClientTableConfigConnection = {
  __typename?: 'ClientTableConfigConnection';
  /** Array of edges. */
  edges: Array<ClientTableConfigEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type ClientTableConfigCountAggregate = {
  __typename?: 'ClientTableConfigCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['Int']>;
};

export type ClientTableConfigDeleteResponse = {
  __typename?: 'ClientTableConfigDeleteResponse';
  config?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientTableConfigEdge = {
  __typename?: 'ClientTableConfigEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the ClientTableConfig */
  node: ClientTableConfig;
};

export type ClientTableConfigFilter = {
  and?: InputMaybe<Array<ClientTableConfigFilter>>;
  id?: InputMaybe<ClientTableConfigIdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ClientTableConfigFilter>>;
  ownerId?: InputMaybe<StringFieldComparison>;
  vendors?: InputMaybe<ClientTableConfigFilterVendorFilter>;
};

export type ClientTableConfigFilterVendorFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<ClientTableConfigFilterVendorFilter>>;
  id?: InputMaybe<VendorIdFilterComparison>;
  or?: InputMaybe<Array<ClientTableConfigFilterVendorFilter>>;
  shortname?: InputMaybe<VendorShortnameFilterComparison>;
};

export type ClientTableConfigIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type ClientTableConfigInput = {
  config?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClientTableConfigMaxAggregate = {
  __typename?: 'ClientTableConfigMaxAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
};

export type ClientTableConfigMinAggregate = {
  __typename?: 'ClientTableConfigMinAggregate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
};

export type ClientTableConfigSort = {
  direction: SortDirection;
  field: ClientTableConfigSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ClientTableConfigSortFields {
  Id = 'id',
  Name = 'name',
  OwnerId = 'ownerId'
}

export type CommentCreateInput = {
  body: Scalars['String'];
  topicId: Scalars['ID'];
};

export enum CountryCode {
  Au = 'AU',
  Us = 'US'
}

export type CreateManyAccountContactsInput = {
  /** Array of records to create */
  accountContacts: Array<AccountContactCreateInput>;
};

export type CreateManyAccountsInput = {
  /** Array of records to create */
  accounts: Array<AccountCreateInput>;
};

export type CreateManyPhysicalAddressesInput = {
  /** Array of records to create */
  physicalAddresses: Array<PhysicalAddressCreateInput>;
};

export type CreateManyVendorsInput = {
  /** Array of records to create */
  vendors: Array<VendorCreateInput>;
};

export type CreateOneAccountContactInput = {
  /** The record to create */
  accountContact: AccountContactCreateInput;
};

export type CreateOneAccountInput = {
  /** The record to create */
  account: AccountCreateInput;
};

export type CreateOneActivityEventTopicInput = {
  /** The record to create */
  activityEventTopic: ActivityEventTopicCreateInput;
};

export type CreateOneClientTableConfigInput = {
  /** The record to create */
  clientTableConfig: ClientTableConfigInput;
};

export type CreateOneCommentInput = {
  /** The record to create */
  comment: CommentCreateInput;
};

export type CreateOneDiamondBatchPricingJobInput = {
  /** The record to create */
  diamondBatchPricingJob: DiamondBatchPricingJobInput;
};

export type CreateOneDiamondBatchUploadJobInput = {
  /** The record to create */
  diamondBatchUploadJob: DiamondBatchUploadJobInput;
};

export type CreateOneDiamondBatchUploadProfileInput = {
  /** The record to create */
  diamondBatchUploadProfile: DiamondBatchUploadProfileCreateInput;
};

export type CreateOneDiamondFeedPerspectiveInput = {
  /** The record to create */
  diamondFeedPerspective: DiamondFeedPerspectiveInput;
};

export type CreateOneDiamondOnsellControllerInput = {
  /** The record to create */
  diamondOnsellController: DiamondOnsellControllerCreateInput;
};

export type CreateOneDiamondProductPricingCalculatorInput = {
  /** The record to create */
  diamondProductPricingCalculator: DiamondProductPricingCalculatorInput;
};

export type CreateOnePhysicalAddressInput = {
  /** The record to create */
  physicalAddress: PhysicalAddressCreateInput;
};

export type CreateOneVendorInput = {
  /** The record to create */
  vendor: VendorCreateInput;
};

export type CreateOneVendorVariableInput = {
  /** The record to create */
  vendorVariable: VendorVariableCreateInput;
};

export enum CrownInclusion {
  C0 = 'C0',
  C01 = 'C01',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4'
}

export enum Currency {
  Aud = 'AUD',
  Usd = 'USD'
}

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']>;
};

export enum CutGrade {
  Ex = 'EX',
  Fr = 'FR',
  Gd = 'GD',
  Id = 'ID',
  Pr = 'PR',
  Vg = 'VG'
}

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type DeleteManyAccountContactsInput = {
  /** Filter to find records to delete */
  filter: AccountContactDeleteFilter;
};

export type DeleteManyAccountsInput = {
  /** Filter to find records to delete */
  filter: AccountDeleteFilter;
};

export type DeleteManyDiamondFeedPerspectivesInput = {
  /** Filter to find records to delete */
  filter: DiamondFeedPerspectiveDeleteFilter;
};

export type DeleteManyPhysicalAddressesInput = {
  /** Filter to find records to delete */
  filter: PhysicalAddressDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManyVendorsInput = {
  /** Filter to find records to delete */
  filter: VendorDeleteFilter;
};

export type DeleteOneAccountContactInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneAccountInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneActivityEventInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneClientTableConfigInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneDiamondBatchUploadProfileInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneDiamondFeedPerspectiveInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneDiamondOnsellControllerInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneDiamondProductPricingCalculatorInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePhysicalAddressInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneVendorInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneVendorVariableInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DiamondBatchPricingJob = {
  __typename?: 'DiamondBatchPricingJob';
  calculator?: Maybe<DiamondProductPricingCalculator>;
  calculatorId?: Maybe<Scalars['ID']>;
  calculatorVariablesSnapshot: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  error?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isDone: Scalars['Boolean'];
  isManual: Scalars['Boolean'];
  jobId: Scalars['Float'];
  logs?: Maybe<Array<Scalars['String']>>;
  mode: DiamondBatchPricingJobMode;
  progress?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['JSON']>;
  state: JobState;
  updatedAt: Scalars['DateTime'];
  upstreamVendors: Array<Vendor>;
  vendor: Vendor;
  vendorId: Scalars['ID'];
};


export type DiamondBatchPricingJobUpstreamVendorsArgs = {
  filter?: InputMaybe<VendorFilter>;
  sorting?: InputMaybe<Array<VendorSort>>;
};

export type DiamondBatchPricingJobAggregateGroupBy = {
  __typename?: 'DiamondBatchPricingJobAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchPricingJobConnection = {
  __typename?: 'DiamondBatchPricingJobConnection';
  /** Array of edges. */
  edges: Array<DiamondBatchPricingJobEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type DiamondBatchPricingJobCountAggregate = {
  __typename?: 'DiamondBatchPricingJobCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type DiamondBatchPricingJobEdge = {
  __typename?: 'DiamondBatchPricingJobEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondBatchPricingJob */
  node: DiamondBatchPricingJob;
};

export type DiamondBatchPricingJobFilter = {
  and?: InputMaybe<Array<DiamondBatchPricingJobFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DiamondBatchPricingJobFilter>>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export type DiamondBatchPricingJobInput = {
  calculatorId: Scalars['ID'];
  mode: DiamondBatchPricingJobMode;
  upstreamVendorIds: Array<Scalars['ID']>;
  vendorId: Scalars['ID'];
};

export type DiamondBatchPricingJobMaxAggregate = {
  __typename?: 'DiamondBatchPricingJobMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchPricingJobMinAggregate = {
  __typename?: 'DiamondBatchPricingJobMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export enum DiamondBatchPricingJobMode {
  Reset = 'RESET',
  Sync = 'SYNC'
}

export type DiamondBatchPricingJobSort = {
  direction: SortDirection;
  field: DiamondBatchPricingJobSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondBatchPricingJobSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  VendorId = 'vendorId'
}

export type DiamondBatchUploadJob = {
  __typename?: 'DiamondBatchUploadJob';
  createdAt: Scalars['DateTime'];
  error?: Maybe<Scalars['JSON']>;
  failedDocuments?: Maybe<Scalars['Float']>;
  file: Scalars['String'];
  id: Scalars['ID'];
  isDone: Scalars['Boolean'];
  isPreview: Scalars['Boolean'];
  jobId: Scalars['Float'];
  logs?: Maybe<Array<Scalars['String']>>;
  profile: DiamondBatchUploadProfile;
  profileId?: Maybe<Scalars['ID']>;
  progress?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['JSON']>;
  state: JobState;
  totalDocuments?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  vendor: Vendor;
  vendorId: Scalars['ID'];
};

export type DiamondBatchUploadJobAggregateGroupBy = {
  __typename?: 'DiamondBatchUploadJobAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchUploadJobConnection = {
  __typename?: 'DiamondBatchUploadJobConnection';
  /** Array of edges. */
  edges: Array<DiamondBatchUploadJobEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type DiamondBatchUploadJobCountAggregate = {
  __typename?: 'DiamondBatchUploadJobCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isPreview?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type DiamondBatchUploadJobEdge = {
  __typename?: 'DiamondBatchUploadJobEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondBatchUploadJob */
  node: DiamondBatchUploadJob;
};

export type DiamondBatchUploadJobFilter = {
  and?: InputMaybe<Array<DiamondBatchUploadJobFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isPreview?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<DiamondBatchUploadJobFilter>>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export type DiamondBatchUploadJobInput = {
  file: Scalars['String'];
  isPreview: Scalars['Boolean'];
  profileId: Scalars['ID'];
};

export type DiamondBatchUploadJobMaxAggregate = {
  __typename?: 'DiamondBatchUploadJobMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchUploadJobMinAggregate = {
  __typename?: 'DiamondBatchUploadJobMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchUploadJobSort = {
  direction: SortDirection;
  field: DiamondBatchUploadJobSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondBatchUploadJobSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsPreview = 'isPreview',
  VendorId = 'vendorId'
}

export type DiamondBatchUploadProfile = {
  __typename?: 'DiamondBatchUploadProfile';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  resolvers?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTime'];
  vendor: Vendor;
  vendorId: Scalars['ID'];
};

export type DiamondBatchUploadProfileAggregateGroupBy = {
  __typename?: 'DiamondBatchUploadProfileAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchUploadProfileConnection = {
  __typename?: 'DiamondBatchUploadProfileConnection';
  /** Array of edges. */
  edges: Array<DiamondBatchUploadProfileEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type DiamondBatchUploadProfileCountAggregate = {
  __typename?: 'DiamondBatchUploadProfileCountAggregate';
  id?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type DiamondBatchUploadProfileCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  resolvers?: InputMaybe<Scalars['JSON']>;
  vendorId: Scalars['ID'];
};

export type DiamondBatchUploadProfileDeleteResponse = {
  __typename?: 'DiamondBatchUploadProfileDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  resolvers?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchUploadProfileEdge = {
  __typename?: 'DiamondBatchUploadProfileEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondBatchUploadProfile */
  node: DiamondBatchUploadProfile;
};

export type DiamondBatchUploadProfileFilter = {
  and?: InputMaybe<Array<DiamondBatchUploadProfileFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DiamondBatchUploadProfileFilter>>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export type DiamondBatchUploadProfileMaxAggregate = {
  __typename?: 'DiamondBatchUploadProfileMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchUploadProfileMinAggregate = {
  __typename?: 'DiamondBatchUploadProfileMinAggregate';
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondBatchUploadProfileSort = {
  direction: SortDirection;
  field: DiamondBatchUploadProfileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondBatchUploadProfileSortFields {
  Id = 'id',
  VendorId = 'vendorId'
}

export type DiamondBatchUploadProfileUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resolvers?: InputMaybe<Scalars['JSON']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export enum DiamondColor {
  Black = 'BLACK',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Indigo = 'INDIGO',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Violet = 'VIOLET',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export type DiamondFeedPerspective = {
  __typename?: 'DiamondFeedPerspective';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  priceExpression?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vendorId: Scalars['ID'];
};

export type DiamondFeedPerspectiveAggregateGroupBy = {
  __typename?: 'DiamondFeedPerspectiveAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondFeedPerspectiveConnection = {
  __typename?: 'DiamondFeedPerspectiveConnection';
  /** Array of edges. */
  edges: Array<DiamondFeedPerspectiveEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type DiamondFeedPerspectiveCountAggregate = {
  __typename?: 'DiamondFeedPerspectiveCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type DiamondFeedPerspectiveDeleteFilter = {
  and?: InputMaybe<Array<DiamondFeedPerspectiveDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DiamondFeedPerspectiveDeleteFilter>>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export type DiamondFeedPerspectiveDeleteResponse = {
  __typename?: 'DiamondFeedPerspectiveDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  priceExpression?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondFeedPerspectiveEdge = {
  __typename?: 'DiamondFeedPerspectiveEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondFeedPerspective */
  node: DiamondFeedPerspective;
};

export type DiamondFeedPerspectiveFilter = {
  and?: InputMaybe<Array<DiamondFeedPerspectiveFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DiamondFeedPerspectiveFilter>>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export type DiamondFeedPerspectiveInput = {
  description?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  priceExpression?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type DiamondFeedPerspectiveMaxAggregate = {
  __typename?: 'DiamondFeedPerspectiveMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondFeedPerspectiveMinAggregate = {
  __typename?: 'DiamondFeedPerspectiveMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondFeedPerspectiveSort = {
  direction: SortDirection;
  field: DiamondFeedPerspectiveSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondFeedPerspectiveSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  VendorId = 'vendorId'
}

export type DiamondFeedPerspectiveUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  priceExpression?: InputMaybe<Scalars['String']>;
};

export type DiamondOnsellController = {
  __typename?: 'DiamondOnsellController';
  calculator?: Maybe<DiamondProductPricingCalculator>;
  calculatorId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  upstreamVendors: Array<Vendor>;
  vendorId: Scalars['ID'];
};


export type DiamondOnsellControllerUpstreamVendorsArgs = {
  filter?: InputMaybe<VendorFilter>;
  sorting?: InputMaybe<Array<VendorSort>>;
};

export type DiamondOnsellControllerAggregateGroupBy = {
  __typename?: 'DiamondOnsellControllerAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondOnsellControllerConnection = {
  __typename?: 'DiamondOnsellControllerConnection';
  /** Array of edges. */
  edges: Array<DiamondOnsellControllerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type DiamondOnsellControllerCountAggregate = {
  __typename?: 'DiamondOnsellControllerCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type DiamondOnsellControllerCreateInput = {
  calculatorId?: InputMaybe<Scalars['ID']>;
  enabled: Scalars['Boolean'];
  vendorId: Scalars['ID'];
};

export type DiamondOnsellControllerDeleteResponse = {
  __typename?: 'DiamondOnsellControllerDeleteResponse';
  calculatorId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondOnsellControllerEdge = {
  __typename?: 'DiamondOnsellControllerEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondOnsellController */
  node: DiamondOnsellController;
};

export type DiamondOnsellControllerFilter = {
  and?: InputMaybe<Array<DiamondOnsellControllerFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DiamondOnsellControllerFilter>>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export type DiamondOnsellControllerMaxAggregate = {
  __typename?: 'DiamondOnsellControllerMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondOnsellControllerMinAggregate = {
  __typename?: 'DiamondOnsellControllerMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondOnsellControllerSort = {
  direction: SortDirection;
  field: DiamondOnsellControllerSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondOnsellControllerSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  VendorId = 'vendorId'
}

export type DiamondOnsellControllerUpdateInput = {
  calculatorId?: InputMaybe<Scalars['ID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type DiamondProduct = {
  __typename?: 'DiamondProduct';
  caratWeight: Scalars['Float'];
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorDescription?: Maybe<Scalars['String']>;
  colorGrade?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  crownAngle?: Maybe<Scalars['Float']>;
  crownHeightPercentage?: Maybe<Scalars['Float']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  currency: Currency;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceDescription?: Maybe<Scalars['String']>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  hasCertificateMedia: Scalars['Boolean'];
  hasImageMedia: Scalars['Boolean'];
  hasVideoMedia: Scalars['Boolean'];
  id: Scalars['ID'];
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  media: Array<DiamondProductMedia>;
  mediaAggregate: Array<DiamondProductMediaAggregateResponse>;
  milkyGrade?: Maybe<CutGrade>;
  origin?: Maybe<Scalars['String']>;
  pavillionAngle?: Maybe<Scalars['Float']>;
  pavillionDepthPercentage?: Maybe<Scalars['Float']>;
  polishGrade?: Maybe<CutGrade>;
  price: Scalars['Float'];
  pricePerCarat: Scalars['Float'];
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  reflectUpstreamStatus: Scalars['Boolean'];
  sarineJourney?: Maybe<Scalars['Boolean']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status: DiamondProductAvailability;
  supplier?: Maybe<Vendor>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  upstreamProduct?: Maybe<DiamondProduct>;
  upstreamProductId?: Maybe<Scalars['ID']>;
  vendor: Vendor;
  vendorId: Scalars['ID'];
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};


export type DiamondProductMediaArgs = {
  filter?: InputMaybe<DiamondProductMediaFilter>;
  sorting?: InputMaybe<Array<DiamondProductMediaSort>>;
};


export type DiamondProductMediaAggregateArgs = {
  filter?: InputMaybe<DiamondProductMediaAggregateFilter>;
};

export type DiamondProductAggregateFilter = {
  and?: InputMaybe<Array<DiamondProductAggregateFilter>>;
  caratWeight?: InputMaybe<NumberFieldComparison>;
  certificationId?: InputMaybe<StringFieldComparison>;
  certificationProvider?: InputMaybe<StringFieldComparison>;
  clarityGrade?: InputMaybe<DiamondProductClarityGradeFilterComparison>;
  colorGrade?: InputMaybe<StringFieldComparison>;
  crownInclusion?: InputMaybe<DiamondProductCrownInclusionFilterComparison>;
  crownInclusionBlack?: InputMaybe<DiamondProductCrownInclusionBlackFilterComparison>;
  customPrice?: InputMaybe<NumberFieldComparison>;
  customPricePerCarat?: InputMaybe<NumberFieldComparison>;
  cutGrade?: InputMaybe<DiamondProductCutGradeFilterComparison>;
  depth?: InputMaybe<NumberFieldComparison>;
  depthPercentage?: InputMaybe<NumberFieldComparison>;
  fancyColorIntensityGrade?: InputMaybe<DiamondProductFancyColorIntensityGradeFilterComparison>;
  fancyColorPrimary?: InputMaybe<DiamondProductFancyColorPrimaryFilterComparison>;
  fancyColorSecondary?: InputMaybe<DiamondProductFancyColorSecondaryFilterComparison>;
  fluorescenceColorPrimary?: InputMaybe<DiamondProductFluorescenceColorPrimaryFilterComparison>;
  fluorescenceIntensityGrade?: InputMaybe<DiamondProductFluorescenceIntensityGradeFilterComparison>;
  girdleMax?: InputMaybe<DiamondProductGirdleMaxFilterComparison>;
  girdleMin?: InputMaybe<DiamondProductGirdleMinFilterComparison>;
  girdleThicknessPercentage?: InputMaybe<NumberFieldComparison>;
  growthProcess?: InputMaybe<DiamondProductGrowthProcessFilterComparison>;
  hasCertificateMedia?: InputMaybe<BooleanFieldComparison>;
  hasImageMedia?: InputMaybe<BooleanFieldComparison>;
  hasVideoMedia?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<DiamondProductIdFilterComparison>;
  length?: InputMaybe<NumberFieldComparison>;
  lengthWidthRatio?: InputMaybe<NumberFieldComparison>;
  lusterGrade?: InputMaybe<DiamondProductLusterGradeFilterComparison>;
  milkyGrade?: InputMaybe<DiamondProductMilkyGradeFilterComparison>;
  or?: InputMaybe<Array<DiamondProductAggregateFilter>>;
  polishGrade?: InputMaybe<DiamondProductPolishGradeFilterComparison>;
  price?: InputMaybe<NumberFieldComparison>;
  pricePerCarat?: InputMaybe<NumberFieldComparison>;
  rapnetDiscountPercentage?: InputMaybe<NumberFieldComparison>;
  reflectUpstreamStatus?: InputMaybe<BooleanFieldComparison>;
  sarineJourney?: InputMaybe<BooleanFieldComparison>;
  sarineLightPerformance?: InputMaybe<DiamondProductSarineLightPerformanceFilterComparison>;
  shape?: InputMaybe<DiamondProductShapeFilterComparison>;
  status?: InputMaybe<DiamondProductStatusFilterComparison>;
  supplierId?: InputMaybe<DiamondProductSupplierIdFilterComparison>;
  symmetryGrade?: InputMaybe<DiamondProductSymmetryGradeFilterComparison>;
  table?: InputMaybe<NumberFieldComparison>;
  tableInclusion?: InputMaybe<DiamondProductTableInclusionFilterComparison>;
  tableInclusionBlack?: InputMaybe<DiamondProductTableInclusionBlackFilterComparison>;
  tablePercentage?: InputMaybe<NumberFieldComparison>;
  upstreamProductId?: InputMaybe<DiamondProductUpstreamProductIdFilterComparison>;
  vendorId?: InputMaybe<DiamondProductVendorIdFilterComparison>;
  vendorRef?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

export type DiamondProductAggregateGroupBy = {
  __typename?: 'DiamondProductAggregateGroupBy';
  caratWeight?: Maybe<Scalars['Float']>;
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorGrade?: Maybe<Scalars['String']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  hasCertificateMedia?: Maybe<Scalars['Boolean']>;
  hasImageMedia?: Maybe<Scalars['Boolean']>;
  hasVideoMedia?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  milkyGrade?: Maybe<CutGrade>;
  polishGrade?: Maybe<CutGrade>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  reflectUpstreamStatus?: Maybe<Scalars['Boolean']>;
  sarineJourney?: Maybe<Scalars['Boolean']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status?: Maybe<DiamondProductAvailability>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  upstreamProductId?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductAggregateResponse = {
  __typename?: 'DiamondProductAggregateResponse';
  avg?: Maybe<DiamondProductAvgAggregate>;
  count?: Maybe<DiamondProductCountAggregate>;
  groupBy?: Maybe<DiamondProductAggregateGroupBy>;
  max?: Maybe<DiamondProductMaxAggregate>;
  min?: Maybe<DiamondProductMinAggregate>;
  sum?: Maybe<DiamondProductSumAggregate>;
};

export type DiamondProductArchive = {
  __typename?: 'DiamondProductArchive';
  certificate?: Maybe<Scalars['String']>;
  diamondProduct?: Maybe<Scalars['JSON']>;
  diamondProductId: Scalars['ID'];
  event?: Maybe<Scalars['String']>;
  expiresAt: Scalars['Float'];
  id: Scalars['ID'];
  ts: Scalars['Float'];
  upstreamProductId?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
};

export type DiamondProductArchiveCertificateKey = {
  __typename?: 'DiamondProductArchiveCertificateKey';
  certificate?: Maybe<Scalars['String']>;
  diamondProductId: Scalars['ID'];
  id: Scalars['ID'];
  ts: Scalars['Float'];
  vendor?: Maybe<Scalars['String']>;
};

export type DiamondProductArchiveCertificateQueryInput = {
  certificationId: Scalars['String'];
  certificationProvider?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type DiamondProductArchiveVendorKey = {
  __typename?: 'DiamondProductArchiveVendorKey';
  diamondProductId: Scalars['ID'];
  id: Scalars['ID'];
  ts: Scalars['Float'];
  vendor?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
};

export type DiamondProductArchiveVendorQueryInput = {
  vendor?: InputMaybe<Scalars['String']>;
  vendorRef: Scalars['String'];
};

export enum DiamondProductAvailability {
  Available = 'AVAILABLE',
  Delay = 'DELAY',
  OnMemo = 'ON_MEMO',
  Unavailable = 'UNAVAILABLE'
}

export type DiamondProductAvgAggregate = {
  __typename?: 'DiamondProductAvgAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  table?: Maybe<Scalars['Float']>;
  tablePercentage?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductClarityGradeFilterComparison = {
  eq?: InputMaybe<ClarityGrade>;
  gt?: InputMaybe<ClarityGrade>;
  gte?: InputMaybe<ClarityGrade>;
  in?: InputMaybe<Array<ClarityGrade>>;
  lt?: InputMaybe<ClarityGrade>;
  lte?: InputMaybe<ClarityGrade>;
};

export type DiamondProductConnection = {
  __typename?: 'DiamondProductConnection';
  /** Array of edges. */
  edges: Array<DiamondProductEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type DiamondProductCountAggregate = {
  __typename?: 'DiamondProductCountAggregate';
  caratWeight?: Maybe<Scalars['Int']>;
  certificationId?: Maybe<Scalars['Int']>;
  certificationProvider?: Maybe<Scalars['Int']>;
  clarityGrade?: Maybe<Scalars['Int']>;
  colorGrade?: Maybe<Scalars['Int']>;
  crownInclusion?: Maybe<Scalars['Int']>;
  crownInclusionBlack?: Maybe<Scalars['Int']>;
  customPrice?: Maybe<Scalars['Int']>;
  customPricePerCarat?: Maybe<Scalars['Int']>;
  cutGrade?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  depthPercentage?: Maybe<Scalars['Int']>;
  fancyColorIntensityGrade?: Maybe<Scalars['Int']>;
  fancyColorPrimary?: Maybe<Scalars['Int']>;
  fancyColorSecondary?: Maybe<Scalars['Int']>;
  fluorescenceColorPrimary?: Maybe<Scalars['Int']>;
  fluorescenceIntensityGrade?: Maybe<Scalars['Int']>;
  girdleMax?: Maybe<Scalars['Int']>;
  girdleMin?: Maybe<Scalars['Int']>;
  girdleThicknessPercentage?: Maybe<Scalars['Int']>;
  growthProcess?: Maybe<Scalars['Int']>;
  hasCertificateMedia?: Maybe<Scalars['Int']>;
  hasImageMedia?: Maybe<Scalars['Int']>;
  hasVideoMedia?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  lengthWidthRatio?: Maybe<Scalars['Int']>;
  lusterGrade?: Maybe<Scalars['Int']>;
  milkyGrade?: Maybe<Scalars['Int']>;
  polishGrade?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  pricePerCarat?: Maybe<Scalars['Int']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Int']>;
  reflectUpstreamStatus?: Maybe<Scalars['Int']>;
  sarineJourney?: Maybe<Scalars['Int']>;
  sarineLightPerformance?: Maybe<Scalars['Int']>;
  shape?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['Int']>;
  symmetryGrade?: Maybe<Scalars['Int']>;
  table?: Maybe<Scalars['Int']>;
  tableInclusion?: Maybe<Scalars['Int']>;
  tableInclusionBlack?: Maybe<Scalars['Int']>;
  tablePercentage?: Maybe<Scalars['Int']>;
  upstreamProductId?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
  vendorRef?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type DiamondProductCrownInclusionBlackFilterComparison = {
  eq?: InputMaybe<BlackInclusion>;
  in?: InputMaybe<Array<BlackInclusion>>;
  notIn?: InputMaybe<Array<BlackInclusion>>;
};

export type DiamondProductCrownInclusionFilterComparison = {
  eq?: InputMaybe<CrownInclusion>;
  in?: InputMaybe<Array<CrownInclusion>>;
  notIn?: InputMaybe<Array<CrownInclusion>>;
};

export type DiamondProductCutGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductEdge = {
  __typename?: 'DiamondProductEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondProduct */
  node: DiamondProduct;
};

export type DiamondProductExternal = {
  __typename?: 'DiamondProductExternal';
  caratWeight: Scalars['Float'];
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorDescription?: Maybe<Scalars['String']>;
  colorGrade?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  crownAngle?: Maybe<Scalars['Float']>;
  crownHeightPercentage?: Maybe<Scalars['Float']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  currency: Currency;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceDescription?: Maybe<Scalars['String']>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  hasCertificateMedia: Scalars['Boolean'];
  hasImageMedia: Scalars['Boolean'];
  hasVideoMedia: Scalars['Boolean'];
  id: Scalars['ID'];
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  media: Array<DiamondProductMedia>;
  milkyGrade?: Maybe<CutGrade>;
  origin?: Maybe<Scalars['String']>;
  pavillionAngle?: Maybe<Scalars['Float']>;
  pavillionDepthPercentage?: Maybe<Scalars['Float']>;
  polishGrade?: Maybe<CutGrade>;
  price: Scalars['Float'];
  pricePerCarat: Scalars['Float'];
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  sarineJourney?: Maybe<Scalars['Boolean']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status: DiamondProductAvailability;
  supplier?: Maybe<Vendor>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  vendorId: Scalars['ID'];
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};


export type DiamondProductExternalMediaArgs = {
  filter?: InputMaybe<DiamondProductMediaFilter>;
  sorting?: InputMaybe<Array<DiamondProductMediaSort>>;
};

export type DiamondProductExternalAggregateGroupBy = {
  __typename?: 'DiamondProductExternalAggregateGroupBy';
  caratWeight?: Maybe<Scalars['Float']>;
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorGrade?: Maybe<Scalars['String']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  hasCertificateMedia?: Maybe<Scalars['Boolean']>;
  hasImageMedia?: Maybe<Scalars['Boolean']>;
  hasVideoMedia?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  milkyGrade?: Maybe<CutGrade>;
  polishGrade?: Maybe<CutGrade>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  sarineJourney?: Maybe<Scalars['Boolean']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status?: Maybe<DiamondProductAvailability>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductExternalAvgAggregate = {
  __typename?: 'DiamondProductExternalAvgAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  table?: Maybe<Scalars['Float']>;
  tablePercentage?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductExternalClarityGradeFilterComparison = {
  eq?: InputMaybe<ClarityGrade>;
  gt?: InputMaybe<ClarityGrade>;
  gte?: InputMaybe<ClarityGrade>;
  in?: InputMaybe<Array<ClarityGrade>>;
  lt?: InputMaybe<ClarityGrade>;
  lte?: InputMaybe<ClarityGrade>;
};

export type DiamondProductExternalConnection = {
  __typename?: 'DiamondProductExternalConnection';
  /** Array of edges. */
  edges: Array<DiamondProductExternalEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type DiamondProductExternalCountAggregate = {
  __typename?: 'DiamondProductExternalCountAggregate';
  caratWeight?: Maybe<Scalars['Int']>;
  certificationId?: Maybe<Scalars['Int']>;
  certificationProvider?: Maybe<Scalars['Int']>;
  clarityGrade?: Maybe<Scalars['Int']>;
  colorGrade?: Maybe<Scalars['Int']>;
  crownInclusion?: Maybe<Scalars['Int']>;
  crownInclusionBlack?: Maybe<Scalars['Int']>;
  customPrice?: Maybe<Scalars['Int']>;
  customPricePerCarat?: Maybe<Scalars['Int']>;
  cutGrade?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  depthPercentage?: Maybe<Scalars['Int']>;
  fancyColorIntensityGrade?: Maybe<Scalars['Int']>;
  fancyColorPrimary?: Maybe<Scalars['Int']>;
  fancyColorSecondary?: Maybe<Scalars['Int']>;
  fluorescenceColorPrimary?: Maybe<Scalars['Int']>;
  fluorescenceIntensityGrade?: Maybe<Scalars['Int']>;
  girdleMax?: Maybe<Scalars['Int']>;
  girdleMin?: Maybe<Scalars['Int']>;
  girdleThicknessPercentage?: Maybe<Scalars['Int']>;
  growthProcess?: Maybe<Scalars['Int']>;
  hasCertificateMedia?: Maybe<Scalars['Int']>;
  hasImageMedia?: Maybe<Scalars['Int']>;
  hasVideoMedia?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  lengthWidthRatio?: Maybe<Scalars['Int']>;
  lusterGrade?: Maybe<Scalars['Int']>;
  milkyGrade?: Maybe<Scalars['Int']>;
  polishGrade?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  pricePerCarat?: Maybe<Scalars['Int']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Int']>;
  sarineJourney?: Maybe<Scalars['Int']>;
  sarineLightPerformance?: Maybe<Scalars['Int']>;
  shape?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['Int']>;
  symmetryGrade?: Maybe<Scalars['Int']>;
  table?: Maybe<Scalars['Int']>;
  tableInclusion?: Maybe<Scalars['Int']>;
  tableInclusionBlack?: Maybe<Scalars['Int']>;
  tablePercentage?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
  vendorRef?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type DiamondProductExternalCrownInclusionBlackFilterComparison = {
  eq?: InputMaybe<BlackInclusion>;
  in?: InputMaybe<Array<BlackInclusion>>;
  notIn?: InputMaybe<Array<BlackInclusion>>;
};

export type DiamondProductExternalCrownInclusionFilterComparison = {
  eq?: InputMaybe<CrownInclusion>;
  in?: InputMaybe<Array<CrownInclusion>>;
  notIn?: InputMaybe<Array<CrownInclusion>>;
};

export type DiamondProductExternalCutGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductExternalEdge = {
  __typename?: 'DiamondProductExternalEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondProductExternal */
  node: DiamondProductExternal;
};

export type DiamondProductExternalFancyColorIntensityGradeFilterComparison = {
  eq?: InputMaybe<FancyColorIntensityGrade>;
  gt?: InputMaybe<FancyColorIntensityGrade>;
  gte?: InputMaybe<FancyColorIntensityGrade>;
  in?: InputMaybe<Array<FancyColorIntensityGrade>>;
  lt?: InputMaybe<FancyColorIntensityGrade>;
  lte?: InputMaybe<FancyColorIntensityGrade>;
};

export type DiamondProductExternalFancyColorPrimaryFilterComparison = {
  eq?: InputMaybe<DiamondColor>;
  in?: InputMaybe<Array<DiamondColor>>;
  notIn?: InputMaybe<Array<DiamondColor>>;
};

export type DiamondProductExternalFancyColorSecondaryFilterComparison = {
  eq?: InputMaybe<DiamondColor>;
  in?: InputMaybe<Array<DiamondColor>>;
  notIn?: InputMaybe<Array<DiamondColor>>;
};

export type DiamondProductExternalFilter = {
  and?: InputMaybe<Array<DiamondProductExternalFilter>>;
  caratWeight?: InputMaybe<NumberFieldComparison>;
  certificationId?: InputMaybe<StringFieldComparison>;
  certificationProvider?: InputMaybe<StringFieldComparison>;
  clarityGrade?: InputMaybe<DiamondProductExternalClarityGradeFilterComparison>;
  colorGrade?: InputMaybe<StringFieldComparison>;
  crownInclusion?: InputMaybe<DiamondProductExternalCrownInclusionFilterComparison>;
  crownInclusionBlack?: InputMaybe<DiamondProductExternalCrownInclusionBlackFilterComparison>;
  customPrice?: InputMaybe<NumberFieldComparison>;
  customPricePerCarat?: InputMaybe<NumberFieldComparison>;
  cutGrade?: InputMaybe<DiamondProductExternalCutGradeFilterComparison>;
  depth?: InputMaybe<NumberFieldComparison>;
  depthPercentage?: InputMaybe<NumberFieldComparison>;
  fancyColorIntensityGrade?: InputMaybe<DiamondProductExternalFancyColorIntensityGradeFilterComparison>;
  fancyColorPrimary?: InputMaybe<DiamondProductExternalFancyColorPrimaryFilterComparison>;
  fancyColorSecondary?: InputMaybe<DiamondProductExternalFancyColorSecondaryFilterComparison>;
  fluorescenceColorPrimary?: InputMaybe<DiamondProductExternalFluorescenceColorPrimaryFilterComparison>;
  fluorescenceIntensityGrade?: InputMaybe<DiamondProductExternalFluorescenceIntensityGradeFilterComparison>;
  girdleMax?: InputMaybe<DiamondProductExternalGirdleMaxFilterComparison>;
  girdleMin?: InputMaybe<DiamondProductExternalGirdleMinFilterComparison>;
  girdleThicknessPercentage?: InputMaybe<NumberFieldComparison>;
  growthProcess?: InputMaybe<DiamondProductExternalGrowthProcessFilterComparison>;
  hasCertificateMedia?: InputMaybe<BooleanFieldComparison>;
  hasImageMedia?: InputMaybe<BooleanFieldComparison>;
  hasVideoMedia?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<DiamondProductExternalIdFilterComparison>;
  length?: InputMaybe<NumberFieldComparison>;
  lengthWidthRatio?: InputMaybe<NumberFieldComparison>;
  lusterGrade?: InputMaybe<DiamondProductExternalLusterGradeFilterComparison>;
  media?: InputMaybe<DiamondProductExternalFilterDiamondProductMediaFilter>;
  milkyGrade?: InputMaybe<DiamondProductExternalMilkyGradeFilterComparison>;
  or?: InputMaybe<Array<DiamondProductExternalFilter>>;
  polishGrade?: InputMaybe<DiamondProductExternalPolishGradeFilterComparison>;
  price?: InputMaybe<NumberFieldComparison>;
  pricePerCarat?: InputMaybe<NumberFieldComparison>;
  rapnetDiscountPercentage?: InputMaybe<NumberFieldComparison>;
  sarineJourney?: InputMaybe<BooleanFieldComparison>;
  sarineLightPerformance?: InputMaybe<DiamondProductExternalSarineLightPerformanceFilterComparison>;
  shape?: InputMaybe<DiamondProductExternalShapeFilterComparison>;
  status?: InputMaybe<DiamondProductExternalStatusFilterComparison>;
  supplier?: InputMaybe<DiamondProductExternalFilterVendorFilter>;
  supplierId?: InputMaybe<DiamondProductExternalSupplierIdFilterComparison>;
  symmetryGrade?: InputMaybe<DiamondProductExternalSymmetryGradeFilterComparison>;
  table?: InputMaybe<NumberFieldComparison>;
  tableInclusion?: InputMaybe<DiamondProductExternalTableInclusionFilterComparison>;
  tableInclusionBlack?: InputMaybe<DiamondProductExternalTableInclusionBlackFilterComparison>;
  tablePercentage?: InputMaybe<NumberFieldComparison>;
  vendorId?: InputMaybe<DiamondProductExternalVendorIdFilterComparison>;
  vendorRef?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

export type DiamondProductExternalFilterDiamondProductMediaFilter = {
  and?: InputMaybe<Array<DiamondProductExternalFilterDiamondProductMediaFilter>>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DiamondProductExternalFilterDiamondProductMediaFilter>>;
};

export type DiamondProductExternalFilterVendorFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<DiamondProductExternalFilterVendorFilter>>;
  id?: InputMaybe<VendorIdFilterComparison>;
  or?: InputMaybe<Array<DiamondProductExternalFilterVendorFilter>>;
  shortname?: InputMaybe<VendorShortnameFilterComparison>;
};

export type DiamondProductExternalFluorescenceColorPrimaryFilterComparison = {
  eq?: InputMaybe<DiamondColor>;
  in?: InputMaybe<Array<DiamondColor>>;
  notIn?: InputMaybe<Array<DiamondColor>>;
};

export type DiamondProductExternalFluorescenceIntensityGradeFilterComparison = {
  eq?: InputMaybe<FluorescenceIntensityGrade>;
  gt?: InputMaybe<FluorescenceIntensityGrade>;
  gte?: InputMaybe<FluorescenceIntensityGrade>;
  in?: InputMaybe<Array<FluorescenceIntensityGrade>>;
  lt?: InputMaybe<FluorescenceIntensityGrade>;
  lte?: InputMaybe<FluorescenceIntensityGrade>;
};

export type DiamondProductExternalGirdleMaxFilterComparison = {
  eq?: InputMaybe<GirdleSize>;
  gt?: InputMaybe<GirdleSize>;
  gte?: InputMaybe<GirdleSize>;
  in?: InputMaybe<Array<GirdleSize>>;
  lt?: InputMaybe<GirdleSize>;
  lte?: InputMaybe<GirdleSize>;
};

export type DiamondProductExternalGirdleMinFilterComparison = {
  eq?: InputMaybe<GirdleSize>;
  gt?: InputMaybe<GirdleSize>;
  gte?: InputMaybe<GirdleSize>;
  in?: InputMaybe<Array<GirdleSize>>;
  lt?: InputMaybe<GirdleSize>;
  lte?: InputMaybe<GirdleSize>;
};

export type DiamondProductExternalGrowthProcessFilterComparison = {
  eq?: InputMaybe<GrowthProcess>;
  in?: InputMaybe<Array<GrowthProcess>>;
  notIn?: InputMaybe<Array<GrowthProcess>>;
};

export type DiamondProductExternalIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type DiamondProductExternalLusterGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductExternalMaxAggregate = {
  __typename?: 'DiamondProductExternalMaxAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorGrade?: Maybe<Scalars['String']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  id?: Maybe<Scalars['ID']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  milkyGrade?: Maybe<CutGrade>;
  polishGrade?: Maybe<CutGrade>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status?: Maybe<DiamondProductAvailability>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductExternalMilkyGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductExternalMinAggregate = {
  __typename?: 'DiamondProductExternalMinAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorGrade?: Maybe<Scalars['String']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  id?: Maybe<Scalars['ID']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  milkyGrade?: Maybe<CutGrade>;
  polishGrade?: Maybe<CutGrade>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status?: Maybe<DiamondProductAvailability>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductExternalPolishGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductExternalSarineLightPerformanceFilterComparison = {
  eq?: InputMaybe<SarineLightPerformance>;
  in?: InputMaybe<Array<SarineLightPerformance>>;
  notIn?: InputMaybe<Array<SarineLightPerformance>>;
};

export type DiamondProductExternalShapeFilterComparison = {
  eq?: InputMaybe<ShapeCut>;
  in?: InputMaybe<Array<ShapeCut>>;
  notIn?: InputMaybe<Array<ShapeCut>>;
};

export type DiamondProductExternalSort = {
  direction: SortDirection;
  field: DiamondProductExternalSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondProductExternalSortFields {
  CaratWeight = 'caratWeight',
  CertificationId = 'certificationId',
  CertificationProvider = 'certificationProvider',
  ClarityGrade = 'clarityGrade',
  ColorGrade = 'colorGrade',
  CrownInclusion = 'crownInclusion',
  CrownInclusionBlack = 'crownInclusionBlack',
  CustomPrice = 'customPrice',
  CustomPricePerCarat = 'customPricePerCarat',
  CutGrade = 'cutGrade',
  Depth = 'depth',
  DepthPercentage = 'depthPercentage',
  FancyColorIntensityGrade = 'fancyColorIntensityGrade',
  FancyColorPrimary = 'fancyColorPrimary',
  FancyColorSecondary = 'fancyColorSecondary',
  FluorescenceColorPrimary = 'fluorescenceColorPrimary',
  FluorescenceIntensityGrade = 'fluorescenceIntensityGrade',
  GirdleMax = 'girdleMax',
  GirdleMin = 'girdleMin',
  GirdleThicknessPercentage = 'girdleThicknessPercentage',
  GrowthProcess = 'growthProcess',
  HasCertificateMedia = 'hasCertificateMedia',
  HasImageMedia = 'hasImageMedia',
  HasVideoMedia = 'hasVideoMedia',
  Id = 'id',
  Length = 'length',
  LengthWidthRatio = 'lengthWidthRatio',
  LusterGrade = 'lusterGrade',
  MilkyGrade = 'milkyGrade',
  PolishGrade = 'polishGrade',
  Price = 'price',
  PricePerCarat = 'pricePerCarat',
  RapnetDiscountPercentage = 'rapnetDiscountPercentage',
  SarineJourney = 'sarineJourney',
  SarineLightPerformance = 'sarineLightPerformance',
  Shape = 'shape',
  Status = 'status',
  SupplierId = 'supplierId',
  SymmetryGrade = 'symmetryGrade',
  Table = 'table',
  TableInclusion = 'tableInclusion',
  TableInclusionBlack = 'tableInclusionBlack',
  TablePercentage = 'tablePercentage',
  VendorId = 'vendorId',
  VendorRef = 'vendorRef',
  Width = 'width'
}

export type DiamondProductExternalStatusFilterComparison = {
  eq?: InputMaybe<DiamondProductAvailability>;
  in?: InputMaybe<Array<DiamondProductAvailability>>;
};

export type DiamondProductExternalSumAggregate = {
  __typename?: 'DiamondProductExternalSumAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  table?: Maybe<Scalars['Float']>;
  tablePercentage?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductExternalSupplierIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type DiamondProductExternalSymmetryGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductExternalTableInclusionBlackFilterComparison = {
  eq?: InputMaybe<BlackInclusion>;
  in?: InputMaybe<Array<BlackInclusion>>;
  notIn?: InputMaybe<Array<BlackInclusion>>;
};

export type DiamondProductExternalTableInclusionFilterComparison = {
  eq?: InputMaybe<TableInclusion>;
  in?: InputMaybe<Array<TableInclusion>>;
  notIn?: InputMaybe<Array<TableInclusion>>;
};

export type DiamondProductExternalVendorIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type DiamondProductFancyColorIntensityGradeFilterComparison = {
  eq?: InputMaybe<FancyColorIntensityGrade>;
  gt?: InputMaybe<FancyColorIntensityGrade>;
  gte?: InputMaybe<FancyColorIntensityGrade>;
  in?: InputMaybe<Array<FancyColorIntensityGrade>>;
  lt?: InputMaybe<FancyColorIntensityGrade>;
  lte?: InputMaybe<FancyColorIntensityGrade>;
};

export type DiamondProductFancyColorPrimaryFilterComparison = {
  eq?: InputMaybe<DiamondColor>;
  in?: InputMaybe<Array<DiamondColor>>;
  notIn?: InputMaybe<Array<DiamondColor>>;
};

export type DiamondProductFancyColorSecondaryFilterComparison = {
  eq?: InputMaybe<DiamondColor>;
  in?: InputMaybe<Array<DiamondColor>>;
  notIn?: InputMaybe<Array<DiamondColor>>;
};

export type DiamondProductFilter = {
  and?: InputMaybe<Array<DiamondProductFilter>>;
  caratWeight?: InputMaybe<NumberFieldComparison>;
  certificationId?: InputMaybe<StringFieldComparison>;
  certificationProvider?: InputMaybe<StringFieldComparison>;
  clarityGrade?: InputMaybe<DiamondProductClarityGradeFilterComparison>;
  colorGrade?: InputMaybe<StringFieldComparison>;
  crownInclusion?: InputMaybe<DiamondProductCrownInclusionFilterComparison>;
  crownInclusionBlack?: InputMaybe<DiamondProductCrownInclusionBlackFilterComparison>;
  customPrice?: InputMaybe<NumberFieldComparison>;
  customPricePerCarat?: InputMaybe<NumberFieldComparison>;
  cutGrade?: InputMaybe<DiamondProductCutGradeFilterComparison>;
  depth?: InputMaybe<NumberFieldComparison>;
  depthPercentage?: InputMaybe<NumberFieldComparison>;
  fancyColorIntensityGrade?: InputMaybe<DiamondProductFancyColorIntensityGradeFilterComparison>;
  fancyColorPrimary?: InputMaybe<DiamondProductFancyColorPrimaryFilterComparison>;
  fancyColorSecondary?: InputMaybe<DiamondProductFancyColorSecondaryFilterComparison>;
  fluorescenceColorPrimary?: InputMaybe<DiamondProductFluorescenceColorPrimaryFilterComparison>;
  fluorescenceIntensityGrade?: InputMaybe<DiamondProductFluorescenceIntensityGradeFilterComparison>;
  girdleMax?: InputMaybe<DiamondProductGirdleMaxFilterComparison>;
  girdleMin?: InputMaybe<DiamondProductGirdleMinFilterComparison>;
  girdleThicknessPercentage?: InputMaybe<NumberFieldComparison>;
  growthProcess?: InputMaybe<DiamondProductGrowthProcessFilterComparison>;
  hasCertificateMedia?: InputMaybe<BooleanFieldComparison>;
  hasImageMedia?: InputMaybe<BooleanFieldComparison>;
  hasVideoMedia?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<DiamondProductIdFilterComparison>;
  length?: InputMaybe<NumberFieldComparison>;
  lengthWidthRatio?: InputMaybe<NumberFieldComparison>;
  lusterGrade?: InputMaybe<DiamondProductLusterGradeFilterComparison>;
  media?: InputMaybe<DiamondProductFilterDiamondProductMediaFilter>;
  milkyGrade?: InputMaybe<DiamondProductMilkyGradeFilterComparison>;
  or?: InputMaybe<Array<DiamondProductFilter>>;
  polishGrade?: InputMaybe<DiamondProductPolishGradeFilterComparison>;
  price?: InputMaybe<NumberFieldComparison>;
  pricePerCarat?: InputMaybe<NumberFieldComparison>;
  rapnetDiscountPercentage?: InputMaybe<NumberFieldComparison>;
  reflectUpstreamStatus?: InputMaybe<BooleanFieldComparison>;
  sarineJourney?: InputMaybe<BooleanFieldComparison>;
  sarineLightPerformance?: InputMaybe<DiamondProductSarineLightPerformanceFilterComparison>;
  shape?: InputMaybe<DiamondProductShapeFilterComparison>;
  status?: InputMaybe<DiamondProductStatusFilterComparison>;
  supplier?: InputMaybe<DiamondProductFilterVendorFilter>;
  supplierId?: InputMaybe<DiamondProductSupplierIdFilterComparison>;
  symmetryGrade?: InputMaybe<DiamondProductSymmetryGradeFilterComparison>;
  table?: InputMaybe<NumberFieldComparison>;
  tableInclusion?: InputMaybe<DiamondProductTableInclusionFilterComparison>;
  tableInclusionBlack?: InputMaybe<DiamondProductTableInclusionBlackFilterComparison>;
  tablePercentage?: InputMaybe<NumberFieldComparison>;
  upstreamProduct?: InputMaybe<DiamondProductFilterDiamondProductFilter>;
  upstreamProductId?: InputMaybe<DiamondProductUpstreamProductIdFilterComparison>;
  vendor?: InputMaybe<DiamondProductFilterVendorFilter>;
  vendorId?: InputMaybe<DiamondProductVendorIdFilterComparison>;
  vendorRef?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

export type DiamondProductFilterDiamondProductFilter = {
  and?: InputMaybe<Array<DiamondProductFilterDiamondProductFilter>>;
  caratWeight?: InputMaybe<NumberFieldComparison>;
  certificationId?: InputMaybe<StringFieldComparison>;
  certificationProvider?: InputMaybe<StringFieldComparison>;
  clarityGrade?: InputMaybe<DiamondProductClarityGradeFilterComparison>;
  colorGrade?: InputMaybe<StringFieldComparison>;
  crownInclusion?: InputMaybe<DiamondProductCrownInclusionFilterComparison>;
  crownInclusionBlack?: InputMaybe<DiamondProductCrownInclusionBlackFilterComparison>;
  customPrice?: InputMaybe<NumberFieldComparison>;
  customPricePerCarat?: InputMaybe<NumberFieldComparison>;
  cutGrade?: InputMaybe<DiamondProductCutGradeFilterComparison>;
  depth?: InputMaybe<NumberFieldComparison>;
  depthPercentage?: InputMaybe<NumberFieldComparison>;
  fancyColorIntensityGrade?: InputMaybe<DiamondProductFancyColorIntensityGradeFilterComparison>;
  fancyColorPrimary?: InputMaybe<DiamondProductFancyColorPrimaryFilterComparison>;
  fancyColorSecondary?: InputMaybe<DiamondProductFancyColorSecondaryFilterComparison>;
  fluorescenceColorPrimary?: InputMaybe<DiamondProductFluorescenceColorPrimaryFilterComparison>;
  fluorescenceIntensityGrade?: InputMaybe<DiamondProductFluorescenceIntensityGradeFilterComparison>;
  girdleMax?: InputMaybe<DiamondProductGirdleMaxFilterComparison>;
  girdleMin?: InputMaybe<DiamondProductGirdleMinFilterComparison>;
  girdleThicknessPercentage?: InputMaybe<NumberFieldComparison>;
  growthProcess?: InputMaybe<DiamondProductGrowthProcessFilterComparison>;
  hasCertificateMedia?: InputMaybe<BooleanFieldComparison>;
  hasImageMedia?: InputMaybe<BooleanFieldComparison>;
  hasVideoMedia?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<DiamondProductIdFilterComparison>;
  length?: InputMaybe<NumberFieldComparison>;
  lengthWidthRatio?: InputMaybe<NumberFieldComparison>;
  lusterGrade?: InputMaybe<DiamondProductLusterGradeFilterComparison>;
  milkyGrade?: InputMaybe<DiamondProductMilkyGradeFilterComparison>;
  or?: InputMaybe<Array<DiamondProductFilterDiamondProductFilter>>;
  polishGrade?: InputMaybe<DiamondProductPolishGradeFilterComparison>;
  price?: InputMaybe<NumberFieldComparison>;
  pricePerCarat?: InputMaybe<NumberFieldComparison>;
  rapnetDiscountPercentage?: InputMaybe<NumberFieldComparison>;
  reflectUpstreamStatus?: InputMaybe<BooleanFieldComparison>;
  sarineJourney?: InputMaybe<BooleanFieldComparison>;
  sarineLightPerformance?: InputMaybe<DiamondProductSarineLightPerformanceFilterComparison>;
  shape?: InputMaybe<DiamondProductShapeFilterComparison>;
  status?: InputMaybe<DiamondProductStatusFilterComparison>;
  supplierId?: InputMaybe<DiamondProductSupplierIdFilterComparison>;
  symmetryGrade?: InputMaybe<DiamondProductSymmetryGradeFilterComparison>;
  table?: InputMaybe<NumberFieldComparison>;
  tableInclusion?: InputMaybe<DiamondProductTableInclusionFilterComparison>;
  tableInclusionBlack?: InputMaybe<DiamondProductTableInclusionBlackFilterComparison>;
  tablePercentage?: InputMaybe<NumberFieldComparison>;
  upstreamProductId?: InputMaybe<DiamondProductUpstreamProductIdFilterComparison>;
  vendorId?: InputMaybe<DiamondProductVendorIdFilterComparison>;
  vendorRef?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

export type DiamondProductFilterDiamondProductMediaFilter = {
  and?: InputMaybe<Array<DiamondProductFilterDiamondProductMediaFilter>>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DiamondProductFilterDiamondProductMediaFilter>>;
};

export type DiamondProductFilterVendorFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<DiamondProductFilterVendorFilter>>;
  id?: InputMaybe<VendorIdFilterComparison>;
  or?: InputMaybe<Array<DiamondProductFilterVendorFilter>>;
  shortname?: InputMaybe<VendorShortnameFilterComparison>;
};

export type DiamondProductFluorescenceColorPrimaryFilterComparison = {
  eq?: InputMaybe<DiamondColor>;
  in?: InputMaybe<Array<DiamondColor>>;
  notIn?: InputMaybe<Array<DiamondColor>>;
};

export type DiamondProductFluorescenceIntensityGradeFilterComparison = {
  eq?: InputMaybe<FluorescenceIntensityGrade>;
  gt?: InputMaybe<FluorescenceIntensityGrade>;
  gte?: InputMaybe<FluorescenceIntensityGrade>;
  in?: InputMaybe<Array<FluorescenceIntensityGrade>>;
  lt?: InputMaybe<FluorescenceIntensityGrade>;
  lte?: InputMaybe<FluorescenceIntensityGrade>;
};

export type DiamondProductGirdleMaxFilterComparison = {
  eq?: InputMaybe<GirdleSize>;
  gt?: InputMaybe<GirdleSize>;
  gte?: InputMaybe<GirdleSize>;
  in?: InputMaybe<Array<GirdleSize>>;
  lt?: InputMaybe<GirdleSize>;
  lte?: InputMaybe<GirdleSize>;
};

export type DiamondProductGirdleMinFilterComparison = {
  eq?: InputMaybe<GirdleSize>;
  gt?: InputMaybe<GirdleSize>;
  gte?: InputMaybe<GirdleSize>;
  in?: InputMaybe<Array<GirdleSize>>;
  lt?: InputMaybe<GirdleSize>;
  lte?: InputMaybe<GirdleSize>;
};

export type DiamondProductGrowthProcessFilterComparison = {
  eq?: InputMaybe<GrowthProcess>;
  in?: InputMaybe<Array<GrowthProcess>>;
  notIn?: InputMaybe<Array<GrowthProcess>>;
};

export type DiamondProductIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type DiamondProductLusterGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductMaxAggregate = {
  __typename?: 'DiamondProductMaxAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorGrade?: Maybe<Scalars['String']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  id?: Maybe<Scalars['ID']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  milkyGrade?: Maybe<CutGrade>;
  polishGrade?: Maybe<CutGrade>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status?: Maybe<DiamondProductAvailability>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  upstreamProductId?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductMedia = {
  __typename?: 'DiamondProductMedia';
  label: Scalars['String'];
  url: Scalars['String'];
};

export type DiamondProductMediaAggregateFilter = {
  and?: InputMaybe<Array<DiamondProductMediaAggregateFilter>>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DiamondProductMediaAggregateFilter>>;
};

export type DiamondProductMediaAggregateGroupBy = {
  __typename?: 'DiamondProductMediaAggregateGroupBy';
  label?: Maybe<Scalars['String']>;
};

export type DiamondProductMediaAggregateResponse = {
  __typename?: 'DiamondProductMediaAggregateResponse';
  count?: Maybe<DiamondProductMediaCountAggregate>;
  groupBy?: Maybe<DiamondProductMediaAggregateGroupBy>;
  max?: Maybe<DiamondProductMediaMaxAggregate>;
  min?: Maybe<DiamondProductMediaMinAggregate>;
};

export type DiamondProductMediaCountAggregate = {
  __typename?: 'DiamondProductMediaCountAggregate';
  label?: Maybe<Scalars['Int']>;
};

export type DiamondProductMediaFilter = {
  and?: InputMaybe<Array<DiamondProductMediaFilter>>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DiamondProductMediaFilter>>;
};

export type DiamondProductMediaMaxAggregate = {
  __typename?: 'DiamondProductMediaMaxAggregate';
  label?: Maybe<Scalars['String']>;
};

export type DiamondProductMediaMinAggregate = {
  __typename?: 'DiamondProductMediaMinAggregate';
  label?: Maybe<Scalars['String']>;
};

export type DiamondProductMediaSort = {
  direction: SortDirection;
  field: DiamondProductMediaSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondProductMediaSortFields {
  Label = 'label'
}

export type DiamondProductMilkyGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductMinAggregate = {
  __typename?: 'DiamondProductMinAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  certificationId?: Maybe<Scalars['String']>;
  certificationProvider?: Maybe<Scalars['String']>;
  clarityGrade?: Maybe<ClarityGrade>;
  colorGrade?: Maybe<Scalars['String']>;
  crownInclusion?: Maybe<CrownInclusion>;
  crownInclusionBlack?: Maybe<BlackInclusion>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  cutGrade?: Maybe<CutGrade>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  fancyColorIntensityGrade?: Maybe<FancyColorIntensityGrade>;
  fancyColorPrimary?: Maybe<DiamondColor>;
  fancyColorSecondary?: Maybe<DiamondColor>;
  fluorescenceColorPrimary?: Maybe<DiamondColor>;
  fluorescenceIntensityGrade?: Maybe<FluorescenceIntensityGrade>;
  girdleMax?: Maybe<GirdleSize>;
  girdleMin?: Maybe<GirdleSize>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  growthProcess?: Maybe<GrowthProcess>;
  id?: Maybe<Scalars['ID']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  lusterGrade?: Maybe<CutGrade>;
  milkyGrade?: Maybe<CutGrade>;
  polishGrade?: Maybe<CutGrade>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  sarineLightPerformance?: Maybe<SarineLightPerformance>;
  shape?: Maybe<ShapeCut>;
  status?: Maybe<DiamondProductAvailability>;
  supplierId?: Maybe<Scalars['ID']>;
  symmetryGrade?: Maybe<CutGrade>;
  table?: Maybe<Scalars['Float']>;
  tableInclusion?: Maybe<TableInclusion>;
  tableInclusionBlack?: Maybe<BlackInclusion>;
  tablePercentage?: Maybe<Scalars['Float']>;
  upstreamProductId?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorRef?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductPolishGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductPricingCalculator = {
  __typename?: 'DiamondProductPricingCalculator';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vendorId: Scalars['ID'];
};

export type DiamondProductPricingCalculatorAggregateGroupBy = {
  __typename?: 'DiamondProductPricingCalculatorAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondProductPricingCalculatorConnection = {
  __typename?: 'DiamondProductPricingCalculatorConnection';
  /** Array of edges. */
  edges: Array<DiamondProductPricingCalculatorEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type DiamondProductPricingCalculatorCountAggregate = {
  __typename?: 'DiamondProductPricingCalculatorCountAggregate';
  id?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type DiamondProductPricingCalculatorDeleteResponse = {
  __typename?: 'DiamondProductPricingCalculatorDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  function?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondProductPricingCalculatorEdge = {
  __typename?: 'DiamondProductPricingCalculatorEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the DiamondProductPricingCalculator */
  node: DiamondProductPricingCalculator;
};

export type DiamondProductPricingCalculatorFilter = {
  and?: InputMaybe<Array<DiamondProductPricingCalculatorFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DiamondProductPricingCalculatorFilter>>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export type DiamondProductPricingCalculatorInput = {
  description?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type DiamondProductPricingCalculatorMaxAggregate = {
  __typename?: 'DiamondProductPricingCalculatorMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondProductPricingCalculatorMinAggregate = {
  __typename?: 'DiamondProductPricingCalculatorMinAggregate';
  id?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type DiamondProductPricingCalculatorSort = {
  direction: SortDirection;
  field: DiamondProductPricingCalculatorSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondProductPricingCalculatorSortFields {
  Id = 'id',
  VendorId = 'vendorId'
}

export type DiamondProductSarineLightPerformanceFilterComparison = {
  eq?: InputMaybe<SarineLightPerformance>;
  in?: InputMaybe<Array<SarineLightPerformance>>;
  notIn?: InputMaybe<Array<SarineLightPerformance>>;
};

export type DiamondProductShapeFilterComparison = {
  eq?: InputMaybe<ShapeCut>;
  in?: InputMaybe<Array<ShapeCut>>;
  notIn?: InputMaybe<Array<ShapeCut>>;
};

export type DiamondProductSort = {
  direction: SortDirection;
  field: DiamondProductSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DiamondProductSortFields {
  CaratWeight = 'caratWeight',
  CertificationId = 'certificationId',
  CertificationProvider = 'certificationProvider',
  ClarityGrade = 'clarityGrade',
  ColorGrade = 'colorGrade',
  CrownInclusion = 'crownInclusion',
  CrownInclusionBlack = 'crownInclusionBlack',
  CustomPrice = 'customPrice',
  CustomPricePerCarat = 'customPricePerCarat',
  CutGrade = 'cutGrade',
  Depth = 'depth',
  DepthPercentage = 'depthPercentage',
  FancyColorIntensityGrade = 'fancyColorIntensityGrade',
  FancyColorPrimary = 'fancyColorPrimary',
  FancyColorSecondary = 'fancyColorSecondary',
  FluorescenceColorPrimary = 'fluorescenceColorPrimary',
  FluorescenceIntensityGrade = 'fluorescenceIntensityGrade',
  GirdleMax = 'girdleMax',
  GirdleMin = 'girdleMin',
  GirdleThicknessPercentage = 'girdleThicknessPercentage',
  GrowthProcess = 'growthProcess',
  HasCertificateMedia = 'hasCertificateMedia',
  HasImageMedia = 'hasImageMedia',
  HasVideoMedia = 'hasVideoMedia',
  Id = 'id',
  Length = 'length',
  LengthWidthRatio = 'lengthWidthRatio',
  LusterGrade = 'lusterGrade',
  MilkyGrade = 'milkyGrade',
  PolishGrade = 'polishGrade',
  Price = 'price',
  PricePerCarat = 'pricePerCarat',
  RapnetDiscountPercentage = 'rapnetDiscountPercentage',
  ReflectUpstreamStatus = 'reflectUpstreamStatus',
  SarineJourney = 'sarineJourney',
  SarineLightPerformance = 'sarineLightPerformance',
  Shape = 'shape',
  Status = 'status',
  SupplierId = 'supplierId',
  SymmetryGrade = 'symmetryGrade',
  Table = 'table',
  TableInclusion = 'tableInclusion',
  TableInclusionBlack = 'tableInclusionBlack',
  TablePercentage = 'tablePercentage',
  UpstreamProductId = 'upstreamProductId',
  VendorId = 'vendorId',
  VendorRef = 'vendorRef',
  Width = 'width'
}

export type DiamondProductStatusFilterComparison = {
  eq?: InputMaybe<DiamondProductAvailability>;
  in?: InputMaybe<Array<DiamondProductAvailability>>;
};

export type DiamondProductSumAggregate = {
  __typename?: 'DiamondProductSumAggregate';
  caratWeight?: Maybe<Scalars['Float']>;
  customPrice?: Maybe<Scalars['Float']>;
  customPricePerCarat?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  depthPercentage?: Maybe<Scalars['Float']>;
  girdleThicknessPercentage?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  lengthWidthRatio?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  pricePerCarat?: Maybe<Scalars['Float']>;
  rapnetDiscountPercentage?: Maybe<Scalars['Float']>;
  table?: Maybe<Scalars['Float']>;
  tablePercentage?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type DiamondProductSupplierIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type DiamondProductSymmetryGradeFilterComparison = {
  eq?: InputMaybe<CutGrade>;
  gt?: InputMaybe<CutGrade>;
  gte?: InputMaybe<CutGrade>;
  in?: InputMaybe<Array<CutGrade>>;
  lt?: InputMaybe<CutGrade>;
  lte?: InputMaybe<CutGrade>;
};

export type DiamondProductTableInclusionBlackFilterComparison = {
  eq?: InputMaybe<BlackInclusion>;
  in?: InputMaybe<Array<BlackInclusion>>;
  notIn?: InputMaybe<Array<BlackInclusion>>;
};

export type DiamondProductTableInclusionFilterComparison = {
  eq?: InputMaybe<TableInclusion>;
  in?: InputMaybe<Array<TableInclusion>>;
  notIn?: InputMaybe<Array<TableInclusion>>;
};

export type DiamondProductUpdateFilter = {
  and?: InputMaybe<Array<DiamondProductUpdateFilter>>;
  caratWeight?: InputMaybe<NumberFieldComparison>;
  certificationId?: InputMaybe<StringFieldComparison>;
  certificationProvider?: InputMaybe<StringFieldComparison>;
  clarityGrade?: InputMaybe<DiamondProductClarityGradeFilterComparison>;
  colorGrade?: InputMaybe<StringFieldComparison>;
  crownInclusion?: InputMaybe<DiamondProductCrownInclusionFilterComparison>;
  crownInclusionBlack?: InputMaybe<DiamondProductCrownInclusionBlackFilterComparison>;
  customPrice?: InputMaybe<NumberFieldComparison>;
  customPricePerCarat?: InputMaybe<NumberFieldComparison>;
  cutGrade?: InputMaybe<DiamondProductCutGradeFilterComparison>;
  depth?: InputMaybe<NumberFieldComparison>;
  depthPercentage?: InputMaybe<NumberFieldComparison>;
  fancyColorIntensityGrade?: InputMaybe<DiamondProductFancyColorIntensityGradeFilterComparison>;
  fancyColorPrimary?: InputMaybe<DiamondProductFancyColorPrimaryFilterComparison>;
  fancyColorSecondary?: InputMaybe<DiamondProductFancyColorSecondaryFilterComparison>;
  fluorescenceColorPrimary?: InputMaybe<DiamondProductFluorescenceColorPrimaryFilterComparison>;
  fluorescenceIntensityGrade?: InputMaybe<DiamondProductFluorescenceIntensityGradeFilterComparison>;
  girdleMax?: InputMaybe<DiamondProductGirdleMaxFilterComparison>;
  girdleMin?: InputMaybe<DiamondProductGirdleMinFilterComparison>;
  girdleThicknessPercentage?: InputMaybe<NumberFieldComparison>;
  growthProcess?: InputMaybe<DiamondProductGrowthProcessFilterComparison>;
  hasCertificateMedia?: InputMaybe<BooleanFieldComparison>;
  hasImageMedia?: InputMaybe<BooleanFieldComparison>;
  hasVideoMedia?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<DiamondProductIdFilterComparison>;
  length?: InputMaybe<NumberFieldComparison>;
  lengthWidthRatio?: InputMaybe<NumberFieldComparison>;
  lusterGrade?: InputMaybe<DiamondProductLusterGradeFilterComparison>;
  milkyGrade?: InputMaybe<DiamondProductMilkyGradeFilterComparison>;
  or?: InputMaybe<Array<DiamondProductUpdateFilter>>;
  polishGrade?: InputMaybe<DiamondProductPolishGradeFilterComparison>;
  price?: InputMaybe<NumberFieldComparison>;
  pricePerCarat?: InputMaybe<NumberFieldComparison>;
  rapnetDiscountPercentage?: InputMaybe<NumberFieldComparison>;
  reflectUpstreamStatus?: InputMaybe<BooleanFieldComparison>;
  sarineJourney?: InputMaybe<BooleanFieldComparison>;
  sarineLightPerformance?: InputMaybe<DiamondProductSarineLightPerformanceFilterComparison>;
  shape?: InputMaybe<DiamondProductShapeFilterComparison>;
  status?: InputMaybe<DiamondProductStatusFilterComparison>;
  supplierId?: InputMaybe<DiamondProductSupplierIdFilterComparison>;
  symmetryGrade?: InputMaybe<DiamondProductSymmetryGradeFilterComparison>;
  table?: InputMaybe<NumberFieldComparison>;
  tableInclusion?: InputMaybe<DiamondProductTableInclusionFilterComparison>;
  tableInclusionBlack?: InputMaybe<DiamondProductTableInclusionBlackFilterComparison>;
  tablePercentage?: InputMaybe<NumberFieldComparison>;
  upstreamProductId?: InputMaybe<DiamondProductUpstreamProductIdFilterComparison>;
  vendorId?: InputMaybe<DiamondProductVendorIdFilterComparison>;
  vendorRef?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<NumberFieldComparison>;
};

export type DiamondProductUpdateInput = {
  reflectUpstreamStatus: Scalars['Boolean'];
  status: DiamondProductAvailability;
};

export type DiamondProductUpstreamProductIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type DiamondProductVendorIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type ExternalUser = {
  __typename?: 'ExternalUser';
  email?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export enum FancyColorIntensityGrade {
  F = 'F',
  Fdk = 'FDK',
  Fdp = 'FDP',
  Fi = 'FI',
  Fl = 'FL',
  Fnt = 'FNT',
  Fv = 'FV',
  L = 'L',
  Vl = 'VL'
}

export enum FluorescenceIntensityGrade {
  Fnt = 'FNT',
  Med = 'MED',
  N = 'N',
  Slt = 'SLT',
  Stg = 'STG',
  Vst = 'VST'
}

export enum GirdleSize {
  Etk = 'ETK',
  Etn = 'ETN',
  Med = 'MED',
  Stk = 'STK',
  Thk = 'THK',
  Thn = 'THN',
  Vtk = 'VTK',
  Vtn = 'VTN'
}

export enum GrowthProcess {
  Cvd = 'CVD',
  CvdAg = 'CVD_AG',
  Hpht = 'HPHT',
  Natural = 'NATURAL'
}

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  iLike?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  neq?: InputMaybe<Scalars['ID']>;
  notILike?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
  notLike?: InputMaybe<Scalars['ID']>;
};

export enum JobState {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Delayed = 'DELAYED',
  Failed = 'FAILED',
  Paused = 'PAUSED',
  Void = 'VOID',
  Waiting = 'WAITING'
}

export type Mutation = {
  __typename?: 'Mutation';
  addEventsToActivityEventTopic: ActivityEventTopic;
  addMediaToDiamondProduct: DiamondProduct;
  addMediaToDiamondProductExternal: DiamondProductExternal;
  addUpstreamVendorsToDiamondOnsellController: DiamondOnsellController;
  addVendorsToClientTableConfig: ClientTableConfig;
  addVendorsToUser: User;
  createManyAccountContacts: Array<AccountContact>;
  createManyAccounts: Array<Account>;
  createManyPhysicalAddresses: Array<PhysicalAddress>;
  createManyVendors: Array<Vendor>;
  createOneAccount: Account;
  createOneAccountContact: AccountContact;
  createOneActivityEventTopic: ActivityEventTopic;
  createOneClientTableConfig: ClientTableConfig;
  createOneComment: ActivityEvent;
  createOneDiamondBatchPricingJob: DiamondBatchPricingJob;
  createOneDiamondBatchUploadJob: DiamondBatchUploadJob;
  createOneDiamondBatchUploadJobForVendor: DiamondBatchUploadJob;
  createOneDiamondBatchUploadProfile: DiamondBatchUploadProfile;
  createOneDiamondFeedPerspective: DiamondFeedPerspective;
  createOneDiamondOnsellController: DiamondOnsellController;
  createOneDiamondProductPricingCalculator: DiamondProductPricingCalculator;
  createOnePhysicalAddress: PhysicalAddress;
  createOneVendor: Vendor;
  createOneVendorVariable: VendorVariable;
  deleteManyAccountContacts: DeleteManyResponse;
  deleteManyAccounts: DeleteManyResponse;
  deleteManyDiamondFeedPerspectives: DeleteManyResponse;
  deleteManyPhysicalAddresses: DeleteManyResponse;
  deleteManyVendors: DeleteManyResponse;
  deleteOneAccount: AccountDeleteResponse;
  deleteOneAccountContact: AccountContactDeleteResponse;
  deleteOneActivityEvent: ActivityEventDeleteResponse;
  deleteOneClientTableConfig: ClientTableConfigDeleteResponse;
  deleteOneDiamondBatchUploadProfile: DiamondBatchUploadProfileDeleteResponse;
  deleteOneDiamondFeedPerspective: DiamondFeedPerspectiveDeleteResponse;
  deleteOneDiamondOnsellController: DiamondOnsellControllerDeleteResponse;
  deleteOneDiamondProductPricingCalculator: DiamondProductPricingCalculatorDeleteResponse;
  deleteOnePhysicalAddress: PhysicalAddressDeleteResponse;
  deleteOneVendor: VendorDeleteResponse;
  deleteOneVendorVariable: VendorVariableDeleteResponse;
  removeDefaultShippingAddressFromAccount: Account;
  removeEventsFromActivityEventTopic: ActivityEventTopic;
  removeFeedPerspectiveFromAccount: Account;
  removeFeedPerspectiveFromAccountContact: AccountContact;
  removeMediaFromDiamondProduct: DiamondProduct;
  removeMediaFromDiamondProductExternal: DiamondProductExternal;
  removeOwnerFromPhysicalAddress: PhysicalAddress;
  removeUpstreamVendorsFromDiamondOnsellController: DiamondOnsellController;
  removeVendorFromActivityEventTopic: ActivityEventTopic;
  removeVendorFromVendorVariable: VendorVariable;
  removeVendorsFromClientTableConfig: ClientTableConfig;
  removeVendorsFromUser: User;
  sendRawEmail: Scalars['String'];
  setAccountOnVendor: Vendor;
  setCalculatorOnDiamondOnsellController: DiamondOnsellController;
  setDefaultShippingAddressOnAccount: Account;
  setEventsOnActivityEventTopic: ActivityEventTopic;
  setFeedPerspectiveOnAccount: Account;
  setFeedPerspectiveOnAccountContact: AccountContact;
  setMediaOnDiamondProduct: DiamondProduct;
  setMediaOnDiamondProductExternal: DiamondProductExternal;
  setOwnerOnPhysicalAddress: PhysicalAddress;
  setUpstreamVendorsOnDiamondOnsellController: DiamondOnsellController;
  setVendorOnActivityEventTopic: ActivityEventTopic;
  setVendorOnVendorVariable: VendorVariable;
  setVendorsOnClientTableConfig: ClientTableConfig;
  setVendorsOnUser: User;
  syncExternalUser: User;
  updateManyAccountContacts: UpdateManyResponse;
  updateManyAccounts: UpdateManyResponse;
  updateManyDiamondProducts: UpdateManyResponse;
  updateManyPhysicalAddresses: UpdateManyResponse;
  updateManyVendors: UpdateManyResponse;
  updateOneAccount: Account;
  updateOneAccountContact: AccountContact;
  updateOneActivityEvent: ActivityEvent;
  updateOneActivityEventTopic: ActivityEventTopic;
  updateOneClientTableConfig: ClientTableConfig;
  updateOneDiamondBatchUploadProfile: DiamondBatchUploadProfile;
  updateOneDiamondFeedPerspective: DiamondFeedPerspective;
  updateOneDiamondOnsellController: DiamondOnsellController;
  updateOneDiamondProduct: DiamondProduct;
  updateOneDiamondProductPricingCalculator: DiamondProductPricingCalculator;
  updateOnePhysicalAddress: PhysicalAddress;
  updateOneUser: User;
  updateOneVendor: Vendor;
  updateOneVendorVariable: VendorVariable;
  uploadFileForBatchUploadJob: UploadFileForBatchUploadJobResponse;
};


export type MutationAddEventsToActivityEventTopicArgs = {
  input: AddEventsToActivityEventTopicInput;
};


export type MutationAddMediaToDiamondProductArgs = {
  input: AddMediaToDiamondProductInput;
};


export type MutationAddMediaToDiamondProductExternalArgs = {
  input: AddMediaToDiamondProductExternalInput;
};


export type MutationAddUpstreamVendorsToDiamondOnsellControllerArgs = {
  input: AddUpstreamVendorsToDiamondOnsellControllerInput;
};


export type MutationAddVendorsToClientTableConfigArgs = {
  input: AddVendorsToClientTableConfigInput;
};


export type MutationAddVendorsToUserArgs = {
  input: AddVendorsToUserInput;
};


export type MutationCreateManyAccountContactsArgs = {
  input: CreateManyAccountContactsInput;
};


export type MutationCreateManyAccountsArgs = {
  input: CreateManyAccountsInput;
};


export type MutationCreateManyPhysicalAddressesArgs = {
  input: CreateManyPhysicalAddressesInput;
};


export type MutationCreateManyVendorsArgs = {
  input: CreateManyVendorsInput;
};


export type MutationCreateOneAccountArgs = {
  input: CreateOneAccountInput;
};


export type MutationCreateOneAccountContactArgs = {
  input: CreateOneAccountContactInput;
};


export type MutationCreateOneActivityEventTopicArgs = {
  input: CreateOneActivityEventTopicInput;
};


export type MutationCreateOneClientTableConfigArgs = {
  input: CreateOneClientTableConfigInput;
};


export type MutationCreateOneCommentArgs = {
  input: CreateOneCommentInput;
};


export type MutationCreateOneDiamondBatchPricingJobArgs = {
  input: CreateOneDiamondBatchPricingJobInput;
};


export type MutationCreateOneDiamondBatchUploadJobArgs = {
  input: CreateOneDiamondBatchUploadJobInput;
};


export type MutationCreateOneDiamondBatchUploadJobForVendorArgs = {
  file: Scalars['String'];
  profileId?: InputMaybe<Scalars['ID']>;
  vendorShortname: Scalars['String'];
};


export type MutationCreateOneDiamondBatchUploadProfileArgs = {
  input: CreateOneDiamondBatchUploadProfileInput;
};


export type MutationCreateOneDiamondFeedPerspectiveArgs = {
  input: CreateOneDiamondFeedPerspectiveInput;
};


export type MutationCreateOneDiamondOnsellControllerArgs = {
  input: CreateOneDiamondOnsellControllerInput;
};


export type MutationCreateOneDiamondProductPricingCalculatorArgs = {
  input: CreateOneDiamondProductPricingCalculatorInput;
};


export type MutationCreateOnePhysicalAddressArgs = {
  input: CreateOnePhysicalAddressInput;
};


export type MutationCreateOneVendorArgs = {
  input: CreateOneVendorInput;
};


export type MutationCreateOneVendorVariableArgs = {
  input: CreateOneVendorVariableInput;
};


export type MutationDeleteManyAccountContactsArgs = {
  input: DeleteManyAccountContactsInput;
};


export type MutationDeleteManyAccountsArgs = {
  input: DeleteManyAccountsInput;
};


export type MutationDeleteManyDiamondFeedPerspectivesArgs = {
  input: DeleteManyDiamondFeedPerspectivesInput;
};


export type MutationDeleteManyPhysicalAddressesArgs = {
  input: DeleteManyPhysicalAddressesInput;
};


export type MutationDeleteManyVendorsArgs = {
  input: DeleteManyVendorsInput;
};


export type MutationDeleteOneAccountArgs = {
  input: DeleteOneAccountInput;
};


export type MutationDeleteOneAccountContactArgs = {
  input: DeleteOneAccountContactInput;
};


export type MutationDeleteOneActivityEventArgs = {
  input: DeleteOneActivityEventInput;
};


export type MutationDeleteOneClientTableConfigArgs = {
  input: DeleteOneClientTableConfigInput;
};


export type MutationDeleteOneDiamondBatchUploadProfileArgs = {
  input: DeleteOneDiamondBatchUploadProfileInput;
};


export type MutationDeleteOneDiamondFeedPerspectiveArgs = {
  input: DeleteOneDiamondFeedPerspectiveInput;
};


export type MutationDeleteOneDiamondOnsellControllerArgs = {
  input: DeleteOneDiamondOnsellControllerInput;
};


export type MutationDeleteOneDiamondProductPricingCalculatorArgs = {
  input: DeleteOneDiamondProductPricingCalculatorInput;
};


export type MutationDeleteOnePhysicalAddressArgs = {
  input: DeleteOnePhysicalAddressInput;
};


export type MutationDeleteOneVendorArgs = {
  input: DeleteOneVendorInput;
};


export type MutationDeleteOneVendorVariableArgs = {
  input: DeleteOneVendorVariableInput;
};


export type MutationRemoveDefaultShippingAddressFromAccountArgs = {
  input: RemoveDefaultShippingAddressFromAccountInput;
};


export type MutationRemoveEventsFromActivityEventTopicArgs = {
  input: RemoveEventsFromActivityEventTopicInput;
};


export type MutationRemoveFeedPerspectiveFromAccountArgs = {
  input: RemoveFeedPerspectiveFromAccountInput;
};


export type MutationRemoveFeedPerspectiveFromAccountContactArgs = {
  input: RemoveFeedPerspectiveFromAccountContactInput;
};


export type MutationRemoveMediaFromDiamondProductArgs = {
  input: RemoveMediaFromDiamondProductInput;
};


export type MutationRemoveMediaFromDiamondProductExternalArgs = {
  input: RemoveMediaFromDiamondProductExternalInput;
};


export type MutationRemoveOwnerFromPhysicalAddressArgs = {
  input: RemoveOwnerFromPhysicalAddressInput;
};


export type MutationRemoveUpstreamVendorsFromDiamondOnsellControllerArgs = {
  input: RemoveUpstreamVendorsFromDiamondOnsellControllerInput;
};


export type MutationRemoveVendorFromActivityEventTopicArgs = {
  input: RemoveVendorFromActivityEventTopicInput;
};


export type MutationRemoveVendorFromVendorVariableArgs = {
  input: RemoveVendorFromVendorVariableInput;
};


export type MutationRemoveVendorsFromClientTableConfigArgs = {
  input: RemoveVendorsFromClientTableConfigInput;
};


export type MutationRemoveVendorsFromUserArgs = {
  input: RemoveVendorsFromUserInput;
};


export type MutationSendRawEmailArgs = {
  input: SendRawEmailInput;
};


export type MutationSetAccountOnVendorArgs = {
  input: SetAccountOnVendorInput;
};


export type MutationSetCalculatorOnDiamondOnsellControllerArgs = {
  input: SetCalculatorOnDiamondOnsellControllerInput;
};


export type MutationSetDefaultShippingAddressOnAccountArgs = {
  input: SetDefaultShippingAddressOnAccountInput;
};


export type MutationSetEventsOnActivityEventTopicArgs = {
  input: SetEventsOnActivityEventTopicInput;
};


export type MutationSetFeedPerspectiveOnAccountArgs = {
  input: SetFeedPerspectiveOnAccountInput;
};


export type MutationSetFeedPerspectiveOnAccountContactArgs = {
  input: SetFeedPerspectiveOnAccountContactInput;
};


export type MutationSetMediaOnDiamondProductArgs = {
  input: SetMediaOnDiamondProductInput;
};


export type MutationSetMediaOnDiamondProductExternalArgs = {
  input: SetMediaOnDiamondProductExternalInput;
};


export type MutationSetOwnerOnPhysicalAddressArgs = {
  input: SetOwnerOnPhysicalAddressInput;
};


export type MutationSetUpstreamVendorsOnDiamondOnsellControllerArgs = {
  input: SetUpstreamVendorsOnDiamondOnsellControllerInput;
};


export type MutationSetVendorOnActivityEventTopicArgs = {
  input: SetVendorOnActivityEventTopicInput;
};


export type MutationSetVendorOnVendorVariableArgs = {
  input: SetVendorOnVendorVariableInput;
};


export type MutationSetVendorsOnClientTableConfigArgs = {
  input: SetVendorsOnClientTableConfigInput;
};


export type MutationSetVendorsOnUserArgs = {
  input: SetVendorsOnUserInput;
};


export type MutationSyncExternalUserArgs = {
  externalId: Scalars['String'];
};


export type MutationUpdateManyAccountContactsArgs = {
  input: UpdateManyAccountContactsInput;
};


export type MutationUpdateManyAccountsArgs = {
  input: UpdateManyAccountsInput;
};


export type MutationUpdateManyDiamondProductsArgs = {
  input: UpdateManyDiamondProductsInput;
};


export type MutationUpdateManyPhysicalAddressesArgs = {
  input: UpdateManyPhysicalAddressesInput;
};


export type MutationUpdateManyVendorsArgs = {
  input: UpdateManyVendorsInput;
};


export type MutationUpdateOneAccountArgs = {
  input: UpdateOneAccountInput;
};


export type MutationUpdateOneAccountContactArgs = {
  input: UpdateOneAccountContactInput;
};


export type MutationUpdateOneActivityEventArgs = {
  input: UpdateOneActivityEventInput;
};


export type MutationUpdateOneActivityEventTopicArgs = {
  input: UpdateOneActivityEventTopicInput;
};


export type MutationUpdateOneClientTableConfigArgs = {
  input: UpdateOneClientTableConfigInput;
};


export type MutationUpdateOneDiamondBatchUploadProfileArgs = {
  input: UpdateOneDiamondBatchUploadProfileInput;
};


export type MutationUpdateOneDiamondFeedPerspectiveArgs = {
  input: UpdateOneDiamondFeedPerspectiveInput;
};


export type MutationUpdateOneDiamondOnsellControllerArgs = {
  input: UpdateOneDiamondOnsellControllerInput;
};


export type MutationUpdateOneDiamondProductArgs = {
  input: UpdateOneDiamondProductInput;
};


export type MutationUpdateOneDiamondProductPricingCalculatorArgs = {
  input: UpdateOneDiamondProductPricingCalculatorInput;
};


export type MutationUpdateOnePhysicalAddressArgs = {
  input: UpdateOnePhysicalAddressInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationUpdateOneVendorArgs = {
  input: UpdateOneVendorInput;
};


export type MutationUpdateOneVendorVariableArgs = {
  input: UpdateOneVendorVariableInput;
};


export type MutationUploadFileForBatchUploadJobArgs = {
  file: Scalars['Upload'];
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type PhysicalAddress = {
  __typename?: 'PhysicalAddress';
  addressLines: Array<Scalars['String']>;
  city: Scalars['String'];
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: Vendor;
  ownerId: Scalars['ID'];
  stateProvinceCountry: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zipOrPostcode: Scalars['String'];
};

export type PhysicalAddressAggregateGroupBy = {
  __typename?: 'PhysicalAddressAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhysicalAddressConnection = {
  __typename?: 'PhysicalAddressConnection';
  /** Array of edges. */
  edges: Array<PhysicalAddressEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PhysicalAddressCountAggregate = {
  __typename?: 'PhysicalAddressCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type PhysicalAddressCreateInput = {
  addressLines: Array<Scalars['String']>;
  city: Scalars['String'];
  countryCode: CountryCode;
  ownerId: Scalars['ID'];
  stateProvinceCountry: Scalars['String'];
  zipOrPostcode: Scalars['String'];
};

export type PhysicalAddressDeleteFilter = {
  and?: InputMaybe<Array<PhysicalAddressDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<PhysicalAddressIdFilterComparison>;
  or?: InputMaybe<Array<PhysicalAddressDeleteFilter>>;
  ownerId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PhysicalAddressDeleteResponse = {
  __typename?: 'PhysicalAddressDeleteResponse';
  addressLines?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
  stateProvinceCountry?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipOrPostcode?: Maybe<Scalars['String']>;
};

export type PhysicalAddressEdge = {
  __typename?: 'PhysicalAddressEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the PhysicalAddress */
  node: PhysicalAddress;
};

export type PhysicalAddressFilter = {
  and?: InputMaybe<Array<PhysicalAddressFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<PhysicalAddressIdFilterComparison>;
  or?: InputMaybe<Array<PhysicalAddressFilter>>;
  ownerId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PhysicalAddressIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type PhysicalAddressMaxAggregate = {
  __typename?: 'PhysicalAddressMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhysicalAddressMinAggregate = {
  __typename?: 'PhysicalAddressMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PhysicalAddressSort = {
  direction: SortDirection;
  field: PhysicalAddressSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PhysicalAddressSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  OwnerId = 'ownerId',
  UpdatedAt = 'updatedAt'
}

export type PhysicalAddressUpdateFilter = {
  and?: InputMaybe<Array<PhysicalAddressUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<PhysicalAddressIdFilterComparison>;
  or?: InputMaybe<Array<PhysicalAddressUpdateFilter>>;
  ownerId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PhysicalAddressUpdateInput = {
  addressLines?: InputMaybe<Array<Scalars['String']>>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  ownerId?: InputMaybe<Scalars['ID']>;
  stateProvinceCountry?: InputMaybe<Scalars['String']>;
  zipOrPostcode?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountContact?: Maybe<AccountContact>;
  accountContacts: AccountContactConnection;
  accounts: AccountConnection;
  activityEvent?: Maybe<ActivityEvent>;
  activityEventTopic?: Maybe<ActivityEventTopic>;
  activityEventTopics: ActivityEventTopicConnection;
  activityEvents: ActivityEventConnection;
  clientTableConfig?: Maybe<ClientTableConfig>;
  clientTableConfigs: ClientTableConfigConnection;
  diamondBatchPricingJob?: Maybe<DiamondBatchPricingJob>;
  diamondBatchPricingJobs: DiamondBatchPricingJobConnection;
  diamondBatchUploadJob?: Maybe<DiamondBatchUploadJob>;
  diamondBatchUploadJobs: DiamondBatchUploadJobConnection;
  diamondBatchUploadProfile?: Maybe<DiamondBatchUploadProfile>;
  diamondBatchUploadProfiles: DiamondBatchUploadProfileConnection;
  diamondFeedPerspective?: Maybe<DiamondFeedPerspective>;
  diamondFeedPerspectives: DiamondFeedPerspectiveConnection;
  diamondOnsellController?: Maybe<DiamondOnsellController>;
  diamondOnsellControllers: DiamondOnsellControllerConnection;
  diamondProduct?: Maybe<DiamondProduct>;
  diamondProductAggregate: Array<DiamondProductAggregateResponse>;
  diamondProductArchiveByCertificate?: Maybe<DiamondProductArchiveCertificateKey>;
  diamondProductArchiveByVendorRef?: Maybe<DiamondProductArchiveVendorKey>;
  diamondProductArchives: Array<DiamondProductArchive>;
  diamondProductExternal?: Maybe<DiamondProductExternal>;
  diamondProductPricingCalculator?: Maybe<DiamondProductPricingCalculator>;
  diamondProductPricingCalculators: DiamondProductPricingCalculatorConnection;
  diamondProducts: DiamondProductConnection;
  diamondProductsExternal: DiamondProductExternalConnection;
  me: User;
  physicalAddress?: Maybe<PhysicalAddress>;
  physicalAddresses: PhysicalAddressConnection;
  searchExternalUsers: Array<ExternalUser>;
  user?: Maybe<User>;
  users: UserConnection;
  vendor?: Maybe<Vendor>;
  vendorByShortname?: Maybe<Vendor>;
  vendorVariable?: Maybe<VendorVariable>;
  vendorVariables: VendorVariableConnection;
  vendors: VendorConnection;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountContactArgs = {
  id: Scalars['ID'];
};


export type QueryAccountContactsArgs = {
  filter?: InputMaybe<AccountContactFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<AccountContactSort>>;
};


export type QueryAccountsArgs = {
  filter?: InputMaybe<AccountFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<AccountSort>>;
};


export type QueryActivityEventArgs = {
  id: Scalars['ID'];
};


export type QueryActivityEventTopicArgs = {
  id: Scalars['ID'];
};


export type QueryActivityEventTopicsArgs = {
  filter?: InputMaybe<ActivityEventTopicFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ActivityEventTopicSort>>;
};


export type QueryActivityEventsArgs = {
  filter?: InputMaybe<ActivityEventFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ActivityEventSort>>;
};


export type QueryClientTableConfigArgs = {
  id: Scalars['ID'];
};


export type QueryClientTableConfigsArgs = {
  filter?: InputMaybe<ClientTableConfigFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<ClientTableConfigSort>>;
};


export type QueryDiamondBatchPricingJobArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondBatchPricingJobsArgs = {
  filter?: InputMaybe<DiamondBatchPricingJobFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<DiamondBatchPricingJobSort>>;
};


export type QueryDiamondBatchUploadJobArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondBatchUploadJobsArgs = {
  filter?: InputMaybe<DiamondBatchUploadJobFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<DiamondBatchUploadJobSort>>;
};


export type QueryDiamondBatchUploadProfileArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondBatchUploadProfilesArgs = {
  filter?: InputMaybe<DiamondBatchUploadProfileFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<DiamondBatchUploadProfileSort>>;
};


export type QueryDiamondFeedPerspectiveArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondFeedPerspectivesArgs = {
  filter?: InputMaybe<DiamondFeedPerspectiveFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<DiamondFeedPerspectiveSort>>;
};


export type QueryDiamondOnsellControllerArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondOnsellControllersArgs = {
  filter?: InputMaybe<DiamondOnsellControllerFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<DiamondOnsellControllerSort>>;
};


export type QueryDiamondProductArgs = {
  id: Scalars['ID'];
  perspectiveId?: InputMaybe<Scalars['ID']>;
};


export type QueryDiamondProductAggregateArgs = {
  filter?: InputMaybe<DiamondProductAggregateFilter>;
};


export type QueryDiamondProductArchiveByCertificateArgs = {
  query: DiamondProductArchiveCertificateQueryInput;
};


export type QueryDiamondProductArchiveByVendorRefArgs = {
  query: DiamondProductArchiveVendorQueryInput;
};


export type QueryDiamondProductArchivesArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondProductExternalArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondProductPricingCalculatorArgs = {
  id: Scalars['ID'];
};


export type QueryDiamondProductPricingCalculatorsArgs = {
  filter?: InputMaybe<DiamondProductPricingCalculatorFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<DiamondProductPricingCalculatorSort>>;
};


export type QueryDiamondProductsArgs = {
  filter?: InputMaybe<DiamondProductFilter>;
  paging?: InputMaybe<CursorPaging>;
  perspectiveId?: InputMaybe<Scalars['ID']>;
  sorting?: InputMaybe<Array<DiamondProductSort>>;
};


export type QueryDiamondProductsExternalArgs = {
  filter?: InputMaybe<DiamondProductExternalFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<DiamondProductExternalSort>>;
};


export type QueryPhysicalAddressArgs = {
  id: Scalars['ID'];
};


export type QueryPhysicalAddressesArgs = {
  filter?: InputMaybe<PhysicalAddressFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<PhysicalAddressSort>>;
};


export type QuerySearchExternalUsersArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryVendorArgs = {
  id: Scalars['ID'];
};


export type QueryVendorByShortnameArgs = {
  shortname: Scalars['String'];
};


export type QueryVendorVariableArgs = {
  id: Scalars['ID'];
};


export type QueryVendorVariablesArgs = {
  filter?: InputMaybe<VendorVariableFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<VendorVariableSort>>;
};


export type QueryVendorsArgs = {
  filter?: InputMaybe<VendorFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<VendorSort>>;
};

export type RemoveDefaultShippingAddressFromAccountInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveEventsFromActivityEventTopicInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveFeedPerspectiveFromAccountContactInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveFeedPerspectiveFromAccountInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMediaFromDiamondProductExternalInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveMediaFromDiamondProductInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveOwnerFromPhysicalAddressInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUpstreamVendorsFromDiamondOnsellControllerInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveVendorFromActivityEventTopicInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveVendorFromVendorVariableInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveVendorsFromClientTableConfigInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveVendorsFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export enum SarineLightPerformance {
  Clc1 = 'CLC1',
  Clc2 = 'CLC2',
  Prm1 = 'PRM1',
  Prm2 = 'PRM2',
  Ut1 = 'UT1',
  Ut2 = 'UT2',
  Ut3 = 'UT3'
}

export type SendRawEmailInput = {
  bcc?: InputMaybe<Array<Scalars['String']>>;
  body: Scalars['String'];
  cc?: InputMaybe<Array<Scalars['String']>>;
  from: Scalars['String'];
  html?: InputMaybe<Scalars['Boolean']>;
  replyTo?: InputMaybe<Array<Scalars['String']>>;
  subject: Scalars['String'];
  to: Array<Scalars['String']>;
};

export type SetAccountOnVendorInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetCalculatorOnDiamondOnsellControllerInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetDefaultShippingAddressOnAccountInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetEventsOnActivityEventTopicInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetFeedPerspectiveOnAccountContactInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetFeedPerspectiveOnAccountInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMediaOnDiamondProductExternalInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetMediaOnDiamondProductInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetOwnerOnPhysicalAddressInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUpstreamVendorsOnDiamondOnsellControllerInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetVendorOnActivityEventTopicInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetVendorOnVendorVariableInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetVendorsOnClientTableConfigInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetVendorsOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export enum ShapeCut {
  Asscher = 'ASSCHER',
  Baguette = 'BAGUETTE',
  Brilliant = 'BRILLIANT',
  Crisscut = 'CRISSCUT',
  Cushion = 'CUSHION',
  Emerald = 'EMERALD',
  Halfmoon = 'HALFMOON',
  Heart = 'HEART',
  Hexagon = 'HEXAGON',
  Kite = 'KITE',
  Marquise = 'MARQUISE',
  Octagonal = 'OCTAGONAL',
  Oldeuropean = 'OLDEUROPEAN',
  Oldmine = 'OLDMINE',
  Oval = 'OVAL',
  Padma = 'PADMA',
  Pear = 'PEAR',
  Princess = 'PRINCESS',
  Radiant = 'RADIANT',
  Roseround = 'ROSEROUND',
  Sevenhearts = 'SEVENHEARTS',
  Sirius = 'SIRIUS',
  Sqemerald = 'SQEMERALD',
  Tenha = 'TENHA',
  Trapezoid = 'TRAPEZOID',
  Trillion = 'TRILLION'
}

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
};

export enum TableInclusion {
  T0 = 'T0',
  T01 = 'T01',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4'
}

export type UpdateManyAccountContactsInput = {
  /** Filter used to find fields to update */
  filter: AccountContactUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AccountContactUpdateInput;
};

export type UpdateManyAccountsInput = {
  /** Filter used to find fields to update */
  filter: AccountUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: AccountUpdateInput;
};

export type UpdateManyDiamondProductsInput = {
  /** Filter used to find fields to update */
  filter: DiamondProductUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: DiamondProductUpdateInput;
};

export type UpdateManyPhysicalAddressesInput = {
  /** Filter used to find fields to update */
  filter: PhysicalAddressUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: PhysicalAddressUpdateInput;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManyVendorsInput = {
  /** Filter used to find fields to update */
  filter: VendorUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: VendorUpdateInput;
};

export type UpdateOneAccountContactInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: AccountContactUpdateInput;
};

export type UpdateOneAccountInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: AccountUpdateInput;
};

export type UpdateOneActivityEventInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ActivityEventUpdateInput;
};

export type UpdateOneActivityEventTopicInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ActivityEventTopicUpdateInput;
};

export type UpdateOneClientTableConfigInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ClientTableConfigInput;
};

export type UpdateOneDiamondBatchUploadProfileInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: DiamondBatchUploadProfileUpdateInput;
};

export type UpdateOneDiamondFeedPerspectiveInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: DiamondFeedPerspectiveUpdateInput;
};

export type UpdateOneDiamondOnsellControllerInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: DiamondOnsellControllerUpdateInput;
};

export type UpdateOneDiamondProductInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: DiamondProductUpdateInput;
};

export type UpdateOneDiamondProductPricingCalculatorInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: DiamondProductPricingCalculatorInput;
};

export type UpdateOnePhysicalAddressInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: PhysicalAddressUpdateInput;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UserUpdateInput;
};

export type UpdateOneVendorInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: VendorUpdateInput;
};

export type UpdateOneVendorVariableInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: VendorVariableUpdateInput;
};

export type UploadFileForBatchUploadJobResponse = {
  __typename?: 'UploadFileForBatchUploadJobResponse';
  key: Scalars['String'];
  schema: Scalars['JSON'];
};

export type User = {
  __typename?: 'User';
  auth0Id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vendors: Array<Vendor>;
};


export type UserVendorsArgs = {
  filter?: InputMaybe<VendorFilter>;
  sorting?: InputMaybe<Array<VendorSort>>;
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  auth0Id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type UserAuth0IdFilterComparison = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  auth0Id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the User */
  node: User;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  auth0Id?: InputMaybe<UserAuth0IdFilterComparison>;
  id?: InputMaybe<UserIdFilterComparison>;
  or?: InputMaybe<Array<UserFilter>>;
};

export type UserIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  auth0Id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  auth0Id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  Auth0Id = 'auth0Id',
  Id = 'id'
}

export type UserUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  diamondOnsellController?: Maybe<DiamondOnsellController>;
  id: Scalars['ID'];
  name: Scalars['String'];
  shortname: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type VendorAggregateGroupBy = {
  __typename?: 'VendorAggregateGroupBy';
  accountId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  shortname?: Maybe<Scalars['String']>;
};

export type VendorConnection = {
  __typename?: 'VendorConnection';
  /** Array of edges. */
  edges: Array<VendorEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type VendorCountAggregate = {
  __typename?: 'VendorCountAggregate';
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  shortname?: Maybe<Scalars['Int']>;
};

export type VendorCreateInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  shortname: Scalars['String'];
};

export type VendorDeleteFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<VendorDeleteFilter>>;
  id?: InputMaybe<VendorIdFilterComparison>;
  or?: InputMaybe<Array<VendorDeleteFilter>>;
  shortname?: InputMaybe<VendorShortnameFilterComparison>;
};

export type VendorDeleteResponse = {
  __typename?: 'VendorDeleteResponse';
  accountId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VendorEdge = {
  __typename?: 'VendorEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Vendor */
  node: Vendor;
};

export type VendorFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<VendorFilter>>;
  id?: InputMaybe<VendorIdFilterComparison>;
  or?: InputMaybe<Array<VendorFilter>>;
  shortname?: InputMaybe<VendorShortnameFilterComparison>;
};

export type VendorIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
};

export type VendorMaxAggregate = {
  __typename?: 'VendorMaxAggregate';
  accountId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  shortname?: Maybe<Scalars['String']>;
};

export type VendorMinAggregate = {
  __typename?: 'VendorMinAggregate';
  accountId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  shortname?: Maybe<Scalars['String']>;
};

export type VendorShortnameFilterComparison = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type VendorSort = {
  direction: SortDirection;
  field: VendorSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VendorSortFields {
  AccountId = 'accountId',
  Id = 'id',
  Shortname = 'shortname'
}

export type VendorUpdateFilter = {
  accountId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<VendorUpdateFilter>>;
  id?: InputMaybe<VendorIdFilterComparison>;
  or?: InputMaybe<Array<VendorUpdateFilter>>;
  shortname?: InputMaybe<VendorShortnameFilterComparison>;
};

export type VendorUpdateInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type VendorVariable = {
  __typename?: 'VendorVariable';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  group: VendorVariableGroup;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: VendorVariableType;
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['String']>;
  vendor: Vendor;
  vendorId: Scalars['ID'];
};

export type VendorVariableAggregateGroupBy = {
  __typename?: 'VendorVariableAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type VendorVariableConnection = {
  __typename?: 'VendorVariableConnection';
  /** Array of edges. */
  edges: Array<VendorVariableEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type VendorVariableCountAggregate = {
  __typename?: 'VendorVariableCountAggregate';
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type VendorVariableCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  group: VendorVariableGroup;
  name: Scalars['String'];
  type: VendorVariableType;
  value?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type VendorVariableDeleteResponse = {
  __typename?: 'VendorVariableDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<VendorVariableGroup>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VendorVariableType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type VendorVariableEdge = {
  __typename?: 'VendorVariableEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the VendorVariable */
  node: VendorVariable;
};

export type VendorVariableFilter = {
  and?: InputMaybe<Array<VendorVariableFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<VendorVariableFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  vendorId?: InputMaybe<IdFilterComparison>;
};

export enum VendorVariableGroup {
  FeedPerspective = 'FEED_PERSPECTIVE',
  Global = 'GLOBAL',
  PricingCalculator = 'PRICING_CALCULATOR'
}

export type VendorVariableMaxAggregate = {
  __typename?: 'VendorVariableMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type VendorVariableMinAggregate = {
  __typename?: 'VendorVariableMinAggregate';
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type VendorVariableSort = {
  direction: SortDirection;
  field: VendorVariableSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VendorVariableSortFields {
  Id = 'id',
  UpdatedAt = 'updatedAt',
  VendorId = 'vendorId'
}

export enum VendorVariableType {
  Float = 'FLOAT',
  Int = 'INT',
  String = 'STRING'
}

export type VendorVariableUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserFragment = { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string };

export type VendorFragment = { __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null };

export type DiamondProductFragment = { __typename?: 'DiamondProduct', id: string, createdAt: any, updatedAt: any, vendorId: string, vendorRef?: string | null, certificationId?: string | null, certificationProvider?: string | null, growthProcess?: GrowthProcess | null, caratWeight: number, shape?: ShapeCut | null, cutGrade?: CutGrade | null, polishGrade?: CutGrade | null, symmetryGrade?: CutGrade | null, clarityGrade?: ClarityGrade | null, lusterGrade?: CutGrade | null, milkyGrade?: CutGrade | null, length?: number | null, width?: number | null, depth?: number | null, table?: number | null, crownAngle?: number | null, pavillionAngle?: number | null, lengthWidthRatio?: number | null, depthPercentage?: number | null, tablePercentage?: number | null, crownHeightPercentage?: number | null, pavillionDepthPercentage?: number | null, girdleMin?: GirdleSize | null, girdleMax?: GirdleSize | null, girdleThicknessPercentage?: number | null, colorGrade?: string | null, fancyColorPrimary?: DiamondColor | null, fancyColorSecondary?: DiamondColor | null, fancyColorIntensityGrade?: FancyColorIntensityGrade | null, colorDescription?: string | null, fluorescenceIntensityGrade?: FluorescenceIntensityGrade | null, fluorescenceColorPrimary?: DiamondColor | null, fluorescenceDescription?: string | null, currency: Currency, price: number, pricePerCarat: number, status: DiamondProductAvailability, hasVideoMedia: boolean, hasImageMedia: boolean, hasCertificateMedia: boolean, sarineJourney?: boolean | null, sarineLightPerformance?: SarineLightPerformance | null, tableInclusion?: TableInclusion | null, tableInclusionBlack?: BlackInclusion | null, crownInclusion?: CrownInclusion | null, crownInclusionBlack?: BlackInclusion | null, rapnetDiscountPercentage?: number | null, origin?: string | null, comments?: Array<string> | null, customPrice?: number | null, customPricePerCarat?: number | null, upstreamProductId?: string | null };

export type FeedPerspectiveFragment = { __typename?: 'DiamondFeedPerspective', id: string, createdAt: any, updatedAt: any, vendorId: string, name?: string | null, description?: string | null, filter?: any | null, priceExpression?: string | null };

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<UserSort> | UserSort>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string, vendors: Array<{ __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null }> } }> } };

export type SearchExternalUsersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type SearchExternalUsersQuery = { __typename?: 'Query', searchExternalUsers: Array<{ __typename?: 'ExternalUser', externalId: string, name: string, nickname: string, email?: string | null, userId?: string | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string } };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string, vendors: Array<{ __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null }> } | null };

export type UpdateOneUserMutationVariables = Exact<{
  input: UpdateOneUserInput;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser: { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string, vendors: Array<{ __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null }> } };

export type AddVendorsToUserMutationVariables = Exact<{
  input: AddVendorsToUserInput;
}>;


export type AddVendorsToUserMutation = { __typename?: 'Mutation', addVendorsToUser: { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string, vendors: Array<{ __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null }> } };

export type RemoveVendorsFromUserMutationVariables = Exact<{
  input: RemoveVendorsFromUserInput;
}>;


export type RemoveVendorsFromUserMutation = { __typename?: 'Mutation', removeVendorsFromUser: { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string, vendors: Array<{ __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null }> } };

export type SyncExternalUserMutationVariables = Exact<{
  externalId: Scalars['String'];
}>;


export type SyncExternalUserMutation = { __typename?: 'Mutation', syncExternalUser: { __typename?: 'User', id: string, nickname?: string | null, name?: string | null, description?: string | null, email?: string | null, emailVerified?: string | null, auth0Id: string, vendors: Array<{ __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null }> } };

export type VendorsQueryVariables = Exact<{
  filter?: InputMaybe<VendorFilter>;
  paging?: InputMaybe<CursorPaging>;
  sorting?: InputMaybe<Array<VendorSort> | VendorSort>;
}>;


export type VendorsQuery = { __typename?: 'Query', vendors: { __typename?: 'VendorConnection', edges: Array<{ __typename?: 'VendorEdge', node: { __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null } }> } };

export type VendorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VendorQuery = { __typename?: 'Query', vendor?: { __typename?: 'Vendor', id: string, createdAt: any, updatedAt: any, accountId?: string | null, name: string, shortname: string, description?: string | null } | null };



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Account: ResolverTypeWrapper<Account>;
  AccountAggregateGroupBy: ResolverTypeWrapper<AccountAggregateGroupBy>;
  AccountConnection: ResolverTypeWrapper<AccountConnection>;
  AccountContact: ResolverTypeWrapper<AccountContact>;
  AccountContactAggregateGroupBy: ResolverTypeWrapper<AccountContactAggregateGroupBy>;
  AccountContactConnection: ResolverTypeWrapper<AccountContactConnection>;
  AccountContactCountAggregate: ResolverTypeWrapper<AccountContactCountAggregate>;
  AccountContactCreateInput: AccountContactCreateInput;
  AccountContactDeleteFilter: AccountContactDeleteFilter;
  AccountContactDeleteResponse: ResolverTypeWrapper<AccountContactDeleteResponse>;
  AccountContactEdge: ResolverTypeWrapper<AccountContactEdge>;
  AccountContactFilter: AccountContactFilter;
  AccountContactMaxAggregate: ResolverTypeWrapper<AccountContactMaxAggregate>;
  AccountContactMinAggregate: ResolverTypeWrapper<AccountContactMinAggregate>;
  AccountContactSort: AccountContactSort;
  AccountContactSortFields: AccountContactSortFields;
  AccountContactUpdateFilter: AccountContactUpdateFilter;
  AccountContactUpdateInput: AccountContactUpdateInput;
  AccountCountAggregate: ResolverTypeWrapper<AccountCountAggregate>;
  AccountCreateInput: AccountCreateInput;
  AccountDeleteFilter: AccountDeleteFilter;
  AccountDeleteResponse: ResolverTypeWrapper<AccountDeleteResponse>;
  AccountEdge: ResolverTypeWrapper<AccountEdge>;
  AccountFilter: AccountFilter;
  AccountMaxAggregate: ResolverTypeWrapper<AccountMaxAggregate>;
  AccountMinAggregate: ResolverTypeWrapper<AccountMinAggregate>;
  AccountSort: AccountSort;
  AccountSortFields: AccountSortFields;
  AccountUpdateFilter: AccountUpdateFilter;
  AccountUpdateInput: AccountUpdateInput;
  ActivityEvent: ResolverTypeWrapper<ActivityEvent>;
  ActivityEventAggregateGroupBy: ResolverTypeWrapper<ActivityEventAggregateGroupBy>;
  ActivityEventAuthorType: ActivityEventAuthorType;
  ActivityEventConnection: ResolverTypeWrapper<ActivityEventConnection>;
  ActivityEventCountAggregate: ResolverTypeWrapper<ActivityEventCountAggregate>;
  ActivityEventDeleteResponse: ResolverTypeWrapper<ActivityEventDeleteResponse>;
  ActivityEventEdge: ResolverTypeWrapper<ActivityEventEdge>;
  ActivityEventFilter: ActivityEventFilter;
  ActivityEventIdFilterComparison: ActivityEventIdFilterComparison;
  ActivityEventMaxAggregate: ResolverTypeWrapper<ActivityEventMaxAggregate>;
  ActivityEventMinAggregate: ResolverTypeWrapper<ActivityEventMinAggregate>;
  ActivityEventSort: ActivityEventSort;
  ActivityEventSortFields: ActivityEventSortFields;
  ActivityEventTopic: ResolverTypeWrapper<ActivityEventTopic>;
  ActivityEventTopicAggregateGroupBy: ResolverTypeWrapper<ActivityEventTopicAggregateGroupBy>;
  ActivityEventTopicConnection: ResolverTypeWrapper<ActivityEventTopicConnection>;
  ActivityEventTopicCountAggregate: ResolverTypeWrapper<ActivityEventTopicCountAggregate>;
  ActivityEventTopicCreateInput: ActivityEventTopicCreateInput;
  ActivityEventTopicEdge: ResolverTypeWrapper<ActivityEventTopicEdge>;
  ActivityEventTopicEventsConnection: ResolverTypeWrapper<ActivityEventTopicEventsConnection>;
  ActivityEventTopicFilter: ActivityEventTopicFilter;
  ActivityEventTopicIdFilterComparison: ActivityEventTopicIdFilterComparison;
  ActivityEventTopicMaxAggregate: ResolverTypeWrapper<ActivityEventTopicMaxAggregate>;
  ActivityEventTopicMinAggregate: ResolverTypeWrapper<ActivityEventTopicMinAggregate>;
  ActivityEventTopicSort: ActivityEventTopicSort;
  ActivityEventTopicSortFields: ActivityEventTopicSortFields;
  ActivityEventTopicUpdateInput: ActivityEventTopicUpdateInput;
  ActivityEventType: ActivityEventType;
  ActivityEventUpdateInput: ActivityEventUpdateInput;
  AddEventsToActivityEventTopicInput: AddEventsToActivityEventTopicInput;
  AddMediaToDiamondProductExternalInput: AddMediaToDiamondProductExternalInput;
  AddMediaToDiamondProductInput: AddMediaToDiamondProductInput;
  AddUpstreamVendorsToDiamondOnsellControllerInput: AddUpstreamVendorsToDiamondOnsellControllerInput;
  AddVendorsToClientTableConfigInput: AddVendorsToClientTableConfigInput;
  AddVendorsToUserInput: AddVendorsToUserInput;
  BlackInclusion: BlackInclusion;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BooleanFieldComparison: BooleanFieldComparison;
  ClarityGrade: ClarityGrade;
  ClientTableConfig: ResolverTypeWrapper<ClientTableConfig>;
  ClientTableConfigAggregateGroupBy: ResolverTypeWrapper<ClientTableConfigAggregateGroupBy>;
  ClientTableConfigConnection: ResolverTypeWrapper<ClientTableConfigConnection>;
  ClientTableConfigCountAggregate: ResolverTypeWrapper<ClientTableConfigCountAggregate>;
  ClientTableConfigDeleteResponse: ResolverTypeWrapper<ClientTableConfigDeleteResponse>;
  ClientTableConfigEdge: ResolverTypeWrapper<ClientTableConfigEdge>;
  ClientTableConfigFilter: ClientTableConfigFilter;
  ClientTableConfigFilterVendorFilter: ClientTableConfigFilterVendorFilter;
  ClientTableConfigIdFilterComparison: ClientTableConfigIdFilterComparison;
  ClientTableConfigInput: ClientTableConfigInput;
  ClientTableConfigMaxAggregate: ResolverTypeWrapper<ClientTableConfigMaxAggregate>;
  ClientTableConfigMinAggregate: ResolverTypeWrapper<ClientTableConfigMinAggregate>;
  ClientTableConfigSort: ClientTableConfigSort;
  ClientTableConfigSortFields: ClientTableConfigSortFields;
  CommentCreateInput: CommentCreateInput;
  ConnectionCursor: ResolverTypeWrapper<Scalars['ConnectionCursor']>;
  CountryCode: CountryCode;
  CreateManyAccountContactsInput: CreateManyAccountContactsInput;
  CreateManyAccountsInput: CreateManyAccountsInput;
  CreateManyPhysicalAddressesInput: CreateManyPhysicalAddressesInput;
  CreateManyVendorsInput: CreateManyVendorsInput;
  CreateOneAccountContactInput: CreateOneAccountContactInput;
  CreateOneAccountInput: CreateOneAccountInput;
  CreateOneActivityEventTopicInput: CreateOneActivityEventTopicInput;
  CreateOneClientTableConfigInput: CreateOneClientTableConfigInput;
  CreateOneCommentInput: CreateOneCommentInput;
  CreateOneDiamondBatchPricingJobInput: CreateOneDiamondBatchPricingJobInput;
  CreateOneDiamondBatchUploadJobInput: CreateOneDiamondBatchUploadJobInput;
  CreateOneDiamondBatchUploadProfileInput: CreateOneDiamondBatchUploadProfileInput;
  CreateOneDiamondFeedPerspectiveInput: CreateOneDiamondFeedPerspectiveInput;
  CreateOneDiamondOnsellControllerInput: CreateOneDiamondOnsellControllerInput;
  CreateOneDiamondProductPricingCalculatorInput: CreateOneDiamondProductPricingCalculatorInput;
  CreateOnePhysicalAddressInput: CreateOnePhysicalAddressInput;
  CreateOneVendorInput: CreateOneVendorInput;
  CreateOneVendorVariableInput: CreateOneVendorVariableInput;
  CrownInclusion: CrownInclusion;
  Currency: Currency;
  CursorPaging: CursorPaging;
  CutGrade: CutGrade;
  DateFieldComparison: DateFieldComparison;
  DateFieldComparisonBetween: DateFieldComparisonBetween;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteManyAccountContactsInput: DeleteManyAccountContactsInput;
  DeleteManyAccountsInput: DeleteManyAccountsInput;
  DeleteManyDiamondFeedPerspectivesInput: DeleteManyDiamondFeedPerspectivesInput;
  DeleteManyPhysicalAddressesInput: DeleteManyPhysicalAddressesInput;
  DeleteManyResponse: ResolverTypeWrapper<DeleteManyResponse>;
  DeleteManyVendorsInput: DeleteManyVendorsInput;
  DeleteOneAccountContactInput: DeleteOneAccountContactInput;
  DeleteOneAccountInput: DeleteOneAccountInput;
  DeleteOneActivityEventInput: DeleteOneActivityEventInput;
  DeleteOneClientTableConfigInput: DeleteOneClientTableConfigInput;
  DeleteOneDiamondBatchUploadProfileInput: DeleteOneDiamondBatchUploadProfileInput;
  DeleteOneDiamondFeedPerspectiveInput: DeleteOneDiamondFeedPerspectiveInput;
  DeleteOneDiamondOnsellControllerInput: DeleteOneDiamondOnsellControllerInput;
  DeleteOneDiamondProductPricingCalculatorInput: DeleteOneDiamondProductPricingCalculatorInput;
  DeleteOnePhysicalAddressInput: DeleteOnePhysicalAddressInput;
  DeleteOneVendorInput: DeleteOneVendorInput;
  DeleteOneVendorVariableInput: DeleteOneVendorVariableInput;
  DiamondBatchPricingJob: ResolverTypeWrapper<DiamondBatchPricingJob>;
  DiamondBatchPricingJobAggregateGroupBy: ResolverTypeWrapper<DiamondBatchPricingJobAggregateGroupBy>;
  DiamondBatchPricingJobConnection: ResolverTypeWrapper<DiamondBatchPricingJobConnection>;
  DiamondBatchPricingJobCountAggregate: ResolverTypeWrapper<DiamondBatchPricingJobCountAggregate>;
  DiamondBatchPricingJobEdge: ResolverTypeWrapper<DiamondBatchPricingJobEdge>;
  DiamondBatchPricingJobFilter: DiamondBatchPricingJobFilter;
  DiamondBatchPricingJobInput: DiamondBatchPricingJobInput;
  DiamondBatchPricingJobMaxAggregate: ResolverTypeWrapper<DiamondBatchPricingJobMaxAggregate>;
  DiamondBatchPricingJobMinAggregate: ResolverTypeWrapper<DiamondBatchPricingJobMinAggregate>;
  DiamondBatchPricingJobMode: DiamondBatchPricingJobMode;
  DiamondBatchPricingJobSort: DiamondBatchPricingJobSort;
  DiamondBatchPricingJobSortFields: DiamondBatchPricingJobSortFields;
  DiamondBatchUploadJob: ResolverTypeWrapper<DiamondBatchUploadJob>;
  DiamondBatchUploadJobAggregateGroupBy: ResolverTypeWrapper<DiamondBatchUploadJobAggregateGroupBy>;
  DiamondBatchUploadJobConnection: ResolverTypeWrapper<DiamondBatchUploadJobConnection>;
  DiamondBatchUploadJobCountAggregate: ResolverTypeWrapper<DiamondBatchUploadJobCountAggregate>;
  DiamondBatchUploadJobEdge: ResolverTypeWrapper<DiamondBatchUploadJobEdge>;
  DiamondBatchUploadJobFilter: DiamondBatchUploadJobFilter;
  DiamondBatchUploadJobInput: DiamondBatchUploadJobInput;
  DiamondBatchUploadJobMaxAggregate: ResolverTypeWrapper<DiamondBatchUploadJobMaxAggregate>;
  DiamondBatchUploadJobMinAggregate: ResolverTypeWrapper<DiamondBatchUploadJobMinAggregate>;
  DiamondBatchUploadJobSort: DiamondBatchUploadJobSort;
  DiamondBatchUploadJobSortFields: DiamondBatchUploadJobSortFields;
  DiamondBatchUploadProfile: ResolverTypeWrapper<DiamondBatchUploadProfile>;
  DiamondBatchUploadProfileAggregateGroupBy: ResolverTypeWrapper<DiamondBatchUploadProfileAggregateGroupBy>;
  DiamondBatchUploadProfileConnection: ResolverTypeWrapper<DiamondBatchUploadProfileConnection>;
  DiamondBatchUploadProfileCountAggregate: ResolverTypeWrapper<DiamondBatchUploadProfileCountAggregate>;
  DiamondBatchUploadProfileCreateInput: DiamondBatchUploadProfileCreateInput;
  DiamondBatchUploadProfileDeleteResponse: ResolverTypeWrapper<DiamondBatchUploadProfileDeleteResponse>;
  DiamondBatchUploadProfileEdge: ResolverTypeWrapper<DiamondBatchUploadProfileEdge>;
  DiamondBatchUploadProfileFilter: DiamondBatchUploadProfileFilter;
  DiamondBatchUploadProfileMaxAggregate: ResolverTypeWrapper<DiamondBatchUploadProfileMaxAggregate>;
  DiamondBatchUploadProfileMinAggregate: ResolverTypeWrapper<DiamondBatchUploadProfileMinAggregate>;
  DiamondBatchUploadProfileSort: DiamondBatchUploadProfileSort;
  DiamondBatchUploadProfileSortFields: DiamondBatchUploadProfileSortFields;
  DiamondBatchUploadProfileUpdateInput: DiamondBatchUploadProfileUpdateInput;
  DiamondColor: DiamondColor;
  DiamondFeedPerspective: ResolverTypeWrapper<DiamondFeedPerspective>;
  DiamondFeedPerspectiveAggregateGroupBy: ResolverTypeWrapper<DiamondFeedPerspectiveAggregateGroupBy>;
  DiamondFeedPerspectiveConnection: ResolverTypeWrapper<DiamondFeedPerspectiveConnection>;
  DiamondFeedPerspectiveCountAggregate: ResolverTypeWrapper<DiamondFeedPerspectiveCountAggregate>;
  DiamondFeedPerspectiveDeleteFilter: DiamondFeedPerspectiveDeleteFilter;
  DiamondFeedPerspectiveDeleteResponse: ResolverTypeWrapper<DiamondFeedPerspectiveDeleteResponse>;
  DiamondFeedPerspectiveEdge: ResolverTypeWrapper<DiamondFeedPerspectiveEdge>;
  DiamondFeedPerspectiveFilter: DiamondFeedPerspectiveFilter;
  DiamondFeedPerspectiveInput: DiamondFeedPerspectiveInput;
  DiamondFeedPerspectiveMaxAggregate: ResolverTypeWrapper<DiamondFeedPerspectiveMaxAggregate>;
  DiamondFeedPerspectiveMinAggregate: ResolverTypeWrapper<DiamondFeedPerspectiveMinAggregate>;
  DiamondFeedPerspectiveSort: DiamondFeedPerspectiveSort;
  DiamondFeedPerspectiveSortFields: DiamondFeedPerspectiveSortFields;
  DiamondFeedPerspectiveUpdateInput: DiamondFeedPerspectiveUpdateInput;
  DiamondOnsellController: ResolverTypeWrapper<DiamondOnsellController>;
  DiamondOnsellControllerAggregateGroupBy: ResolverTypeWrapper<DiamondOnsellControllerAggregateGroupBy>;
  DiamondOnsellControllerConnection: ResolverTypeWrapper<DiamondOnsellControllerConnection>;
  DiamondOnsellControllerCountAggregate: ResolverTypeWrapper<DiamondOnsellControllerCountAggregate>;
  DiamondOnsellControllerCreateInput: DiamondOnsellControllerCreateInput;
  DiamondOnsellControllerDeleteResponse: ResolverTypeWrapper<DiamondOnsellControllerDeleteResponse>;
  DiamondOnsellControllerEdge: ResolverTypeWrapper<DiamondOnsellControllerEdge>;
  DiamondOnsellControllerFilter: DiamondOnsellControllerFilter;
  DiamondOnsellControllerMaxAggregate: ResolverTypeWrapper<DiamondOnsellControllerMaxAggregate>;
  DiamondOnsellControllerMinAggregate: ResolverTypeWrapper<DiamondOnsellControllerMinAggregate>;
  DiamondOnsellControllerSort: DiamondOnsellControllerSort;
  DiamondOnsellControllerSortFields: DiamondOnsellControllerSortFields;
  DiamondOnsellControllerUpdateInput: DiamondOnsellControllerUpdateInput;
  DiamondProduct: ResolverTypeWrapper<DiamondProduct>;
  DiamondProductAggregateFilter: DiamondProductAggregateFilter;
  DiamondProductAggregateGroupBy: ResolverTypeWrapper<DiamondProductAggregateGroupBy>;
  DiamondProductAggregateResponse: ResolverTypeWrapper<DiamondProductAggregateResponse>;
  DiamondProductArchive: ResolverTypeWrapper<DiamondProductArchive>;
  DiamondProductArchiveCertificateKey: ResolverTypeWrapper<DiamondProductArchiveCertificateKey>;
  DiamondProductArchiveCertificateQueryInput: DiamondProductArchiveCertificateQueryInput;
  DiamondProductArchiveVendorKey: ResolverTypeWrapper<DiamondProductArchiveVendorKey>;
  DiamondProductArchiveVendorQueryInput: DiamondProductArchiveVendorQueryInput;
  DiamondProductAvailability: DiamondProductAvailability;
  DiamondProductAvgAggregate: ResolverTypeWrapper<DiamondProductAvgAggregate>;
  DiamondProductClarityGradeFilterComparison: DiamondProductClarityGradeFilterComparison;
  DiamondProductConnection: ResolverTypeWrapper<DiamondProductConnection>;
  DiamondProductCountAggregate: ResolverTypeWrapper<DiamondProductCountAggregate>;
  DiamondProductCrownInclusionBlackFilterComparison: DiamondProductCrownInclusionBlackFilterComparison;
  DiamondProductCrownInclusionFilterComparison: DiamondProductCrownInclusionFilterComparison;
  DiamondProductCutGradeFilterComparison: DiamondProductCutGradeFilterComparison;
  DiamondProductEdge: ResolverTypeWrapper<DiamondProductEdge>;
  DiamondProductExternal: ResolverTypeWrapper<DiamondProductExternal>;
  DiamondProductExternalAggregateGroupBy: ResolverTypeWrapper<DiamondProductExternalAggregateGroupBy>;
  DiamondProductExternalAvgAggregate: ResolverTypeWrapper<DiamondProductExternalAvgAggregate>;
  DiamondProductExternalClarityGradeFilterComparison: DiamondProductExternalClarityGradeFilterComparison;
  DiamondProductExternalConnection: ResolverTypeWrapper<DiamondProductExternalConnection>;
  DiamondProductExternalCountAggregate: ResolverTypeWrapper<DiamondProductExternalCountAggregate>;
  DiamondProductExternalCrownInclusionBlackFilterComparison: DiamondProductExternalCrownInclusionBlackFilterComparison;
  DiamondProductExternalCrownInclusionFilterComparison: DiamondProductExternalCrownInclusionFilterComparison;
  DiamondProductExternalCutGradeFilterComparison: DiamondProductExternalCutGradeFilterComparison;
  DiamondProductExternalEdge: ResolverTypeWrapper<DiamondProductExternalEdge>;
  DiamondProductExternalFancyColorIntensityGradeFilterComparison: DiamondProductExternalFancyColorIntensityGradeFilterComparison;
  DiamondProductExternalFancyColorPrimaryFilterComparison: DiamondProductExternalFancyColorPrimaryFilterComparison;
  DiamondProductExternalFancyColorSecondaryFilterComparison: DiamondProductExternalFancyColorSecondaryFilterComparison;
  DiamondProductExternalFilter: DiamondProductExternalFilter;
  DiamondProductExternalFilterDiamondProductMediaFilter: DiamondProductExternalFilterDiamondProductMediaFilter;
  DiamondProductExternalFilterVendorFilter: DiamondProductExternalFilterVendorFilter;
  DiamondProductExternalFluorescenceColorPrimaryFilterComparison: DiamondProductExternalFluorescenceColorPrimaryFilterComparison;
  DiamondProductExternalFluorescenceIntensityGradeFilterComparison: DiamondProductExternalFluorescenceIntensityGradeFilterComparison;
  DiamondProductExternalGirdleMaxFilterComparison: DiamondProductExternalGirdleMaxFilterComparison;
  DiamondProductExternalGirdleMinFilterComparison: DiamondProductExternalGirdleMinFilterComparison;
  DiamondProductExternalGrowthProcessFilterComparison: DiamondProductExternalGrowthProcessFilterComparison;
  DiamondProductExternalIdFilterComparison: DiamondProductExternalIdFilterComparison;
  DiamondProductExternalLusterGradeFilterComparison: DiamondProductExternalLusterGradeFilterComparison;
  DiamondProductExternalMaxAggregate: ResolverTypeWrapper<DiamondProductExternalMaxAggregate>;
  DiamondProductExternalMilkyGradeFilterComparison: DiamondProductExternalMilkyGradeFilterComparison;
  DiamondProductExternalMinAggregate: ResolverTypeWrapper<DiamondProductExternalMinAggregate>;
  DiamondProductExternalPolishGradeFilterComparison: DiamondProductExternalPolishGradeFilterComparison;
  DiamondProductExternalSarineLightPerformanceFilterComparison: DiamondProductExternalSarineLightPerformanceFilterComparison;
  DiamondProductExternalShapeFilterComparison: DiamondProductExternalShapeFilterComparison;
  DiamondProductExternalSort: DiamondProductExternalSort;
  DiamondProductExternalSortFields: DiamondProductExternalSortFields;
  DiamondProductExternalStatusFilterComparison: DiamondProductExternalStatusFilterComparison;
  DiamondProductExternalSumAggregate: ResolverTypeWrapper<DiamondProductExternalSumAggregate>;
  DiamondProductExternalSupplierIdFilterComparison: DiamondProductExternalSupplierIdFilterComparison;
  DiamondProductExternalSymmetryGradeFilterComparison: DiamondProductExternalSymmetryGradeFilterComparison;
  DiamondProductExternalTableInclusionBlackFilterComparison: DiamondProductExternalTableInclusionBlackFilterComparison;
  DiamondProductExternalTableInclusionFilterComparison: DiamondProductExternalTableInclusionFilterComparison;
  DiamondProductExternalVendorIdFilterComparison: DiamondProductExternalVendorIdFilterComparison;
  DiamondProductFancyColorIntensityGradeFilterComparison: DiamondProductFancyColorIntensityGradeFilterComparison;
  DiamondProductFancyColorPrimaryFilterComparison: DiamondProductFancyColorPrimaryFilterComparison;
  DiamondProductFancyColorSecondaryFilterComparison: DiamondProductFancyColorSecondaryFilterComparison;
  DiamondProductFilter: DiamondProductFilter;
  DiamondProductFilterDiamondProductFilter: DiamondProductFilterDiamondProductFilter;
  DiamondProductFilterDiamondProductMediaFilter: DiamondProductFilterDiamondProductMediaFilter;
  DiamondProductFilterVendorFilter: DiamondProductFilterVendorFilter;
  DiamondProductFluorescenceColorPrimaryFilterComparison: DiamondProductFluorescenceColorPrimaryFilterComparison;
  DiamondProductFluorescenceIntensityGradeFilterComparison: DiamondProductFluorescenceIntensityGradeFilterComparison;
  DiamondProductGirdleMaxFilterComparison: DiamondProductGirdleMaxFilterComparison;
  DiamondProductGirdleMinFilterComparison: DiamondProductGirdleMinFilterComparison;
  DiamondProductGrowthProcessFilterComparison: DiamondProductGrowthProcessFilterComparison;
  DiamondProductIdFilterComparison: DiamondProductIdFilterComparison;
  DiamondProductLusterGradeFilterComparison: DiamondProductLusterGradeFilterComparison;
  DiamondProductMaxAggregate: ResolverTypeWrapper<DiamondProductMaxAggregate>;
  DiamondProductMedia: ResolverTypeWrapper<DiamondProductMedia>;
  DiamondProductMediaAggregateFilter: DiamondProductMediaAggregateFilter;
  DiamondProductMediaAggregateGroupBy: ResolverTypeWrapper<DiamondProductMediaAggregateGroupBy>;
  DiamondProductMediaAggregateResponse: ResolverTypeWrapper<DiamondProductMediaAggregateResponse>;
  DiamondProductMediaCountAggregate: ResolverTypeWrapper<DiamondProductMediaCountAggregate>;
  DiamondProductMediaFilter: DiamondProductMediaFilter;
  DiamondProductMediaMaxAggregate: ResolverTypeWrapper<DiamondProductMediaMaxAggregate>;
  DiamondProductMediaMinAggregate: ResolverTypeWrapper<DiamondProductMediaMinAggregate>;
  DiamondProductMediaSort: DiamondProductMediaSort;
  DiamondProductMediaSortFields: DiamondProductMediaSortFields;
  DiamondProductMilkyGradeFilterComparison: DiamondProductMilkyGradeFilterComparison;
  DiamondProductMinAggregate: ResolverTypeWrapper<DiamondProductMinAggregate>;
  DiamondProductPolishGradeFilterComparison: DiamondProductPolishGradeFilterComparison;
  DiamondProductPricingCalculator: ResolverTypeWrapper<DiamondProductPricingCalculator>;
  DiamondProductPricingCalculatorAggregateGroupBy: ResolverTypeWrapper<DiamondProductPricingCalculatorAggregateGroupBy>;
  DiamondProductPricingCalculatorConnection: ResolverTypeWrapper<DiamondProductPricingCalculatorConnection>;
  DiamondProductPricingCalculatorCountAggregate: ResolverTypeWrapper<DiamondProductPricingCalculatorCountAggregate>;
  DiamondProductPricingCalculatorDeleteResponse: ResolverTypeWrapper<DiamondProductPricingCalculatorDeleteResponse>;
  DiamondProductPricingCalculatorEdge: ResolverTypeWrapper<DiamondProductPricingCalculatorEdge>;
  DiamondProductPricingCalculatorFilter: DiamondProductPricingCalculatorFilter;
  DiamondProductPricingCalculatorInput: DiamondProductPricingCalculatorInput;
  DiamondProductPricingCalculatorMaxAggregate: ResolverTypeWrapper<DiamondProductPricingCalculatorMaxAggregate>;
  DiamondProductPricingCalculatorMinAggregate: ResolverTypeWrapper<DiamondProductPricingCalculatorMinAggregate>;
  DiamondProductPricingCalculatorSort: DiamondProductPricingCalculatorSort;
  DiamondProductPricingCalculatorSortFields: DiamondProductPricingCalculatorSortFields;
  DiamondProductSarineLightPerformanceFilterComparison: DiamondProductSarineLightPerformanceFilterComparison;
  DiamondProductShapeFilterComparison: DiamondProductShapeFilterComparison;
  DiamondProductSort: DiamondProductSort;
  DiamondProductSortFields: DiamondProductSortFields;
  DiamondProductStatusFilterComparison: DiamondProductStatusFilterComparison;
  DiamondProductSumAggregate: ResolverTypeWrapper<DiamondProductSumAggregate>;
  DiamondProductSupplierIdFilterComparison: DiamondProductSupplierIdFilterComparison;
  DiamondProductSymmetryGradeFilterComparison: DiamondProductSymmetryGradeFilterComparison;
  DiamondProductTableInclusionBlackFilterComparison: DiamondProductTableInclusionBlackFilterComparison;
  DiamondProductTableInclusionFilterComparison: DiamondProductTableInclusionFilterComparison;
  DiamondProductUpdateFilter: DiamondProductUpdateFilter;
  DiamondProductUpdateInput: DiamondProductUpdateInput;
  DiamondProductUpstreamProductIdFilterComparison: DiamondProductUpstreamProductIdFilterComparison;
  DiamondProductVendorIdFilterComparison: DiamondProductVendorIdFilterComparison;
  ExternalUser: ResolverTypeWrapper<ExternalUser>;
  FancyColorIntensityGrade: FancyColorIntensityGrade;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FluorescenceIntensityGrade: FluorescenceIntensityGrade;
  GirdleSize: GirdleSize;
  GrowthProcess: GrowthProcess;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  IDFilterComparison: IdFilterComparison;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JobState: JobState;
  Mutation: ResolverTypeWrapper<{}>;
  NumberFieldComparison: NumberFieldComparison;
  NumberFieldComparisonBetween: NumberFieldComparisonBetween;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PhysicalAddress: ResolverTypeWrapper<PhysicalAddress>;
  PhysicalAddressAggregateGroupBy: ResolverTypeWrapper<PhysicalAddressAggregateGroupBy>;
  PhysicalAddressConnection: ResolverTypeWrapper<PhysicalAddressConnection>;
  PhysicalAddressCountAggregate: ResolverTypeWrapper<PhysicalAddressCountAggregate>;
  PhysicalAddressCreateInput: PhysicalAddressCreateInput;
  PhysicalAddressDeleteFilter: PhysicalAddressDeleteFilter;
  PhysicalAddressDeleteResponse: ResolverTypeWrapper<PhysicalAddressDeleteResponse>;
  PhysicalAddressEdge: ResolverTypeWrapper<PhysicalAddressEdge>;
  PhysicalAddressFilter: PhysicalAddressFilter;
  PhysicalAddressIdFilterComparison: PhysicalAddressIdFilterComparison;
  PhysicalAddressMaxAggregate: ResolverTypeWrapper<PhysicalAddressMaxAggregate>;
  PhysicalAddressMinAggregate: ResolverTypeWrapper<PhysicalAddressMinAggregate>;
  PhysicalAddressSort: PhysicalAddressSort;
  PhysicalAddressSortFields: PhysicalAddressSortFields;
  PhysicalAddressUpdateFilter: PhysicalAddressUpdateFilter;
  PhysicalAddressUpdateInput: PhysicalAddressUpdateInput;
  Query: ResolverTypeWrapper<{}>;
  RemoveDefaultShippingAddressFromAccountInput: RemoveDefaultShippingAddressFromAccountInput;
  RemoveEventsFromActivityEventTopicInput: RemoveEventsFromActivityEventTopicInput;
  RemoveFeedPerspectiveFromAccountContactInput: RemoveFeedPerspectiveFromAccountContactInput;
  RemoveFeedPerspectiveFromAccountInput: RemoveFeedPerspectiveFromAccountInput;
  RemoveMediaFromDiamondProductExternalInput: RemoveMediaFromDiamondProductExternalInput;
  RemoveMediaFromDiamondProductInput: RemoveMediaFromDiamondProductInput;
  RemoveOwnerFromPhysicalAddressInput: RemoveOwnerFromPhysicalAddressInput;
  RemoveUpstreamVendorsFromDiamondOnsellControllerInput: RemoveUpstreamVendorsFromDiamondOnsellControllerInput;
  RemoveVendorFromActivityEventTopicInput: RemoveVendorFromActivityEventTopicInput;
  RemoveVendorFromVendorVariableInput: RemoveVendorFromVendorVariableInput;
  RemoveVendorsFromClientTableConfigInput: RemoveVendorsFromClientTableConfigInput;
  RemoveVendorsFromUserInput: RemoveVendorsFromUserInput;
  SarineLightPerformance: SarineLightPerformance;
  SendRawEmailInput: SendRawEmailInput;
  SetAccountOnVendorInput: SetAccountOnVendorInput;
  SetCalculatorOnDiamondOnsellControllerInput: SetCalculatorOnDiamondOnsellControllerInput;
  SetDefaultShippingAddressOnAccountInput: SetDefaultShippingAddressOnAccountInput;
  SetEventsOnActivityEventTopicInput: SetEventsOnActivityEventTopicInput;
  SetFeedPerspectiveOnAccountContactInput: SetFeedPerspectiveOnAccountContactInput;
  SetFeedPerspectiveOnAccountInput: SetFeedPerspectiveOnAccountInput;
  SetMediaOnDiamondProductExternalInput: SetMediaOnDiamondProductExternalInput;
  SetMediaOnDiamondProductInput: SetMediaOnDiamondProductInput;
  SetOwnerOnPhysicalAddressInput: SetOwnerOnPhysicalAddressInput;
  SetUpstreamVendorsOnDiamondOnsellControllerInput: SetUpstreamVendorsOnDiamondOnsellControllerInput;
  SetVendorOnActivityEventTopicInput: SetVendorOnActivityEventTopicInput;
  SetVendorOnVendorVariableInput: SetVendorOnVendorVariableInput;
  SetVendorsOnClientTableConfigInput: SetVendorsOnClientTableConfigInput;
  SetVendorsOnUserInput: SetVendorsOnUserInput;
  ShapeCut: ShapeCut;
  SortDirection: SortDirection;
  SortNulls: SortNulls;
  String: ResolverTypeWrapper<Scalars['String']>;
  StringFieldComparison: StringFieldComparison;
  TableInclusion: TableInclusion;
  UpdateManyAccountContactsInput: UpdateManyAccountContactsInput;
  UpdateManyAccountsInput: UpdateManyAccountsInput;
  UpdateManyDiamondProductsInput: UpdateManyDiamondProductsInput;
  UpdateManyPhysicalAddressesInput: UpdateManyPhysicalAddressesInput;
  UpdateManyResponse: ResolverTypeWrapper<UpdateManyResponse>;
  UpdateManyVendorsInput: UpdateManyVendorsInput;
  UpdateOneAccountContactInput: UpdateOneAccountContactInput;
  UpdateOneAccountInput: UpdateOneAccountInput;
  UpdateOneActivityEventInput: UpdateOneActivityEventInput;
  UpdateOneActivityEventTopicInput: UpdateOneActivityEventTopicInput;
  UpdateOneClientTableConfigInput: UpdateOneClientTableConfigInput;
  UpdateOneDiamondBatchUploadProfileInput: UpdateOneDiamondBatchUploadProfileInput;
  UpdateOneDiamondFeedPerspectiveInput: UpdateOneDiamondFeedPerspectiveInput;
  UpdateOneDiamondOnsellControllerInput: UpdateOneDiamondOnsellControllerInput;
  UpdateOneDiamondProductInput: UpdateOneDiamondProductInput;
  UpdateOneDiamondProductPricingCalculatorInput: UpdateOneDiamondProductPricingCalculatorInput;
  UpdateOnePhysicalAddressInput: UpdateOnePhysicalAddressInput;
  UpdateOneUserInput: UpdateOneUserInput;
  UpdateOneVendorInput: UpdateOneVendorInput;
  UpdateOneVendorVariableInput: UpdateOneVendorVariableInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  UploadFileForBatchUploadJobResponse: ResolverTypeWrapper<UploadFileForBatchUploadJobResponse>;
  User: ResolverTypeWrapper<User>;
  UserAggregateGroupBy: ResolverTypeWrapper<UserAggregateGroupBy>;
  UserAuth0IdFilterComparison: UserAuth0IdFilterComparison;
  UserConnection: ResolverTypeWrapper<UserConnection>;
  UserCountAggregate: ResolverTypeWrapper<UserCountAggregate>;
  UserEdge: ResolverTypeWrapper<UserEdge>;
  UserFilter: UserFilter;
  UserIdFilterComparison: UserIdFilterComparison;
  UserMaxAggregate: ResolverTypeWrapper<UserMaxAggregate>;
  UserMinAggregate: ResolverTypeWrapper<UserMinAggregate>;
  UserSort: UserSort;
  UserSortFields: UserSortFields;
  UserUpdateInput: UserUpdateInput;
  Vendor: ResolverTypeWrapper<Vendor>;
  VendorAggregateGroupBy: ResolverTypeWrapper<VendorAggregateGroupBy>;
  VendorConnection: ResolverTypeWrapper<VendorConnection>;
  VendorCountAggregate: ResolverTypeWrapper<VendorCountAggregate>;
  VendorCreateInput: VendorCreateInput;
  VendorDeleteFilter: VendorDeleteFilter;
  VendorDeleteResponse: ResolverTypeWrapper<VendorDeleteResponse>;
  VendorEdge: ResolverTypeWrapper<VendorEdge>;
  VendorFilter: VendorFilter;
  VendorIdFilterComparison: VendorIdFilterComparison;
  VendorMaxAggregate: ResolverTypeWrapper<VendorMaxAggregate>;
  VendorMinAggregate: ResolverTypeWrapper<VendorMinAggregate>;
  VendorShortnameFilterComparison: VendorShortnameFilterComparison;
  VendorSort: VendorSort;
  VendorSortFields: VendorSortFields;
  VendorUpdateFilter: VendorUpdateFilter;
  VendorUpdateInput: VendorUpdateInput;
  VendorVariable: ResolverTypeWrapper<VendorVariable>;
  VendorVariableAggregateGroupBy: ResolverTypeWrapper<VendorVariableAggregateGroupBy>;
  VendorVariableConnection: ResolverTypeWrapper<VendorVariableConnection>;
  VendorVariableCountAggregate: ResolverTypeWrapper<VendorVariableCountAggregate>;
  VendorVariableCreateInput: VendorVariableCreateInput;
  VendorVariableDeleteResponse: ResolverTypeWrapper<VendorVariableDeleteResponse>;
  VendorVariableEdge: ResolverTypeWrapper<VendorVariableEdge>;
  VendorVariableFilter: VendorVariableFilter;
  VendorVariableGroup: VendorVariableGroup;
  VendorVariableMaxAggregate: ResolverTypeWrapper<VendorVariableMaxAggregate>;
  VendorVariableMinAggregate: ResolverTypeWrapper<VendorVariableMinAggregate>;
  VendorVariableSort: VendorVariableSort;
  VendorVariableSortFields: VendorVariableSortFields;
  VendorVariableType: VendorVariableType;
  VendorVariableUpdateInput: VendorVariableUpdateInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Account: Account;
  AccountAggregateGroupBy: AccountAggregateGroupBy;
  AccountConnection: AccountConnection;
  AccountContact: AccountContact;
  AccountContactAggregateGroupBy: AccountContactAggregateGroupBy;
  AccountContactConnection: AccountContactConnection;
  AccountContactCountAggregate: AccountContactCountAggregate;
  AccountContactCreateInput: AccountContactCreateInput;
  AccountContactDeleteFilter: AccountContactDeleteFilter;
  AccountContactDeleteResponse: AccountContactDeleteResponse;
  AccountContactEdge: AccountContactEdge;
  AccountContactFilter: AccountContactFilter;
  AccountContactMaxAggregate: AccountContactMaxAggregate;
  AccountContactMinAggregate: AccountContactMinAggregate;
  AccountContactSort: AccountContactSort;
  AccountContactUpdateFilter: AccountContactUpdateFilter;
  AccountContactUpdateInput: AccountContactUpdateInput;
  AccountCountAggregate: AccountCountAggregate;
  AccountCreateInput: AccountCreateInput;
  AccountDeleteFilter: AccountDeleteFilter;
  AccountDeleteResponse: AccountDeleteResponse;
  AccountEdge: AccountEdge;
  AccountFilter: AccountFilter;
  AccountMaxAggregate: AccountMaxAggregate;
  AccountMinAggregate: AccountMinAggregate;
  AccountSort: AccountSort;
  AccountUpdateFilter: AccountUpdateFilter;
  AccountUpdateInput: AccountUpdateInput;
  ActivityEvent: ActivityEvent;
  ActivityEventAggregateGroupBy: ActivityEventAggregateGroupBy;
  ActivityEventConnection: ActivityEventConnection;
  ActivityEventCountAggregate: ActivityEventCountAggregate;
  ActivityEventDeleteResponse: ActivityEventDeleteResponse;
  ActivityEventEdge: ActivityEventEdge;
  ActivityEventFilter: ActivityEventFilter;
  ActivityEventIdFilterComparison: ActivityEventIdFilterComparison;
  ActivityEventMaxAggregate: ActivityEventMaxAggregate;
  ActivityEventMinAggregate: ActivityEventMinAggregate;
  ActivityEventSort: ActivityEventSort;
  ActivityEventTopic: ActivityEventTopic;
  ActivityEventTopicAggregateGroupBy: ActivityEventTopicAggregateGroupBy;
  ActivityEventTopicConnection: ActivityEventTopicConnection;
  ActivityEventTopicCountAggregate: ActivityEventTopicCountAggregate;
  ActivityEventTopicCreateInput: ActivityEventTopicCreateInput;
  ActivityEventTopicEdge: ActivityEventTopicEdge;
  ActivityEventTopicEventsConnection: ActivityEventTopicEventsConnection;
  ActivityEventTopicFilter: ActivityEventTopicFilter;
  ActivityEventTopicIdFilterComparison: ActivityEventTopicIdFilterComparison;
  ActivityEventTopicMaxAggregate: ActivityEventTopicMaxAggregate;
  ActivityEventTopicMinAggregate: ActivityEventTopicMinAggregate;
  ActivityEventTopicSort: ActivityEventTopicSort;
  ActivityEventTopicUpdateInput: ActivityEventTopicUpdateInput;
  ActivityEventUpdateInput: ActivityEventUpdateInput;
  AddEventsToActivityEventTopicInput: AddEventsToActivityEventTopicInput;
  AddMediaToDiamondProductExternalInput: AddMediaToDiamondProductExternalInput;
  AddMediaToDiamondProductInput: AddMediaToDiamondProductInput;
  AddUpstreamVendorsToDiamondOnsellControllerInput: AddUpstreamVendorsToDiamondOnsellControllerInput;
  AddVendorsToClientTableConfigInput: AddVendorsToClientTableConfigInput;
  AddVendorsToUserInput: AddVendorsToUserInput;
  Boolean: Scalars['Boolean'];
  BooleanFieldComparison: BooleanFieldComparison;
  ClientTableConfig: ClientTableConfig;
  ClientTableConfigAggregateGroupBy: ClientTableConfigAggregateGroupBy;
  ClientTableConfigConnection: ClientTableConfigConnection;
  ClientTableConfigCountAggregate: ClientTableConfigCountAggregate;
  ClientTableConfigDeleteResponse: ClientTableConfigDeleteResponse;
  ClientTableConfigEdge: ClientTableConfigEdge;
  ClientTableConfigFilter: ClientTableConfigFilter;
  ClientTableConfigFilterVendorFilter: ClientTableConfigFilterVendorFilter;
  ClientTableConfigIdFilterComparison: ClientTableConfigIdFilterComparison;
  ClientTableConfigInput: ClientTableConfigInput;
  ClientTableConfigMaxAggregate: ClientTableConfigMaxAggregate;
  ClientTableConfigMinAggregate: ClientTableConfigMinAggregate;
  ClientTableConfigSort: ClientTableConfigSort;
  CommentCreateInput: CommentCreateInput;
  ConnectionCursor: Scalars['ConnectionCursor'];
  CreateManyAccountContactsInput: CreateManyAccountContactsInput;
  CreateManyAccountsInput: CreateManyAccountsInput;
  CreateManyPhysicalAddressesInput: CreateManyPhysicalAddressesInput;
  CreateManyVendorsInput: CreateManyVendorsInput;
  CreateOneAccountContactInput: CreateOneAccountContactInput;
  CreateOneAccountInput: CreateOneAccountInput;
  CreateOneActivityEventTopicInput: CreateOneActivityEventTopicInput;
  CreateOneClientTableConfigInput: CreateOneClientTableConfigInput;
  CreateOneCommentInput: CreateOneCommentInput;
  CreateOneDiamondBatchPricingJobInput: CreateOneDiamondBatchPricingJobInput;
  CreateOneDiamondBatchUploadJobInput: CreateOneDiamondBatchUploadJobInput;
  CreateOneDiamondBatchUploadProfileInput: CreateOneDiamondBatchUploadProfileInput;
  CreateOneDiamondFeedPerspectiveInput: CreateOneDiamondFeedPerspectiveInput;
  CreateOneDiamondOnsellControllerInput: CreateOneDiamondOnsellControllerInput;
  CreateOneDiamondProductPricingCalculatorInput: CreateOneDiamondProductPricingCalculatorInput;
  CreateOnePhysicalAddressInput: CreateOnePhysicalAddressInput;
  CreateOneVendorInput: CreateOneVendorInput;
  CreateOneVendorVariableInput: CreateOneVendorVariableInput;
  CursorPaging: CursorPaging;
  DateFieldComparison: DateFieldComparison;
  DateFieldComparisonBetween: DateFieldComparisonBetween;
  DateTime: Scalars['DateTime'];
  DeleteManyAccountContactsInput: DeleteManyAccountContactsInput;
  DeleteManyAccountsInput: DeleteManyAccountsInput;
  DeleteManyDiamondFeedPerspectivesInput: DeleteManyDiamondFeedPerspectivesInput;
  DeleteManyPhysicalAddressesInput: DeleteManyPhysicalAddressesInput;
  DeleteManyResponse: DeleteManyResponse;
  DeleteManyVendorsInput: DeleteManyVendorsInput;
  DeleteOneAccountContactInput: DeleteOneAccountContactInput;
  DeleteOneAccountInput: DeleteOneAccountInput;
  DeleteOneActivityEventInput: DeleteOneActivityEventInput;
  DeleteOneClientTableConfigInput: DeleteOneClientTableConfigInput;
  DeleteOneDiamondBatchUploadProfileInput: DeleteOneDiamondBatchUploadProfileInput;
  DeleteOneDiamondFeedPerspectiveInput: DeleteOneDiamondFeedPerspectiveInput;
  DeleteOneDiamondOnsellControllerInput: DeleteOneDiamondOnsellControllerInput;
  DeleteOneDiamondProductPricingCalculatorInput: DeleteOneDiamondProductPricingCalculatorInput;
  DeleteOnePhysicalAddressInput: DeleteOnePhysicalAddressInput;
  DeleteOneVendorInput: DeleteOneVendorInput;
  DeleteOneVendorVariableInput: DeleteOneVendorVariableInput;
  DiamondBatchPricingJob: DiamondBatchPricingJob;
  DiamondBatchPricingJobAggregateGroupBy: DiamondBatchPricingJobAggregateGroupBy;
  DiamondBatchPricingJobConnection: DiamondBatchPricingJobConnection;
  DiamondBatchPricingJobCountAggregate: DiamondBatchPricingJobCountAggregate;
  DiamondBatchPricingJobEdge: DiamondBatchPricingJobEdge;
  DiamondBatchPricingJobFilter: DiamondBatchPricingJobFilter;
  DiamondBatchPricingJobInput: DiamondBatchPricingJobInput;
  DiamondBatchPricingJobMaxAggregate: DiamondBatchPricingJobMaxAggregate;
  DiamondBatchPricingJobMinAggregate: DiamondBatchPricingJobMinAggregate;
  DiamondBatchPricingJobSort: DiamondBatchPricingJobSort;
  DiamondBatchUploadJob: DiamondBatchUploadJob;
  DiamondBatchUploadJobAggregateGroupBy: DiamondBatchUploadJobAggregateGroupBy;
  DiamondBatchUploadJobConnection: DiamondBatchUploadJobConnection;
  DiamondBatchUploadJobCountAggregate: DiamondBatchUploadJobCountAggregate;
  DiamondBatchUploadJobEdge: DiamondBatchUploadJobEdge;
  DiamondBatchUploadJobFilter: DiamondBatchUploadJobFilter;
  DiamondBatchUploadJobInput: DiamondBatchUploadJobInput;
  DiamondBatchUploadJobMaxAggregate: DiamondBatchUploadJobMaxAggregate;
  DiamondBatchUploadJobMinAggregate: DiamondBatchUploadJobMinAggregate;
  DiamondBatchUploadJobSort: DiamondBatchUploadJobSort;
  DiamondBatchUploadProfile: DiamondBatchUploadProfile;
  DiamondBatchUploadProfileAggregateGroupBy: DiamondBatchUploadProfileAggregateGroupBy;
  DiamondBatchUploadProfileConnection: DiamondBatchUploadProfileConnection;
  DiamondBatchUploadProfileCountAggregate: DiamondBatchUploadProfileCountAggregate;
  DiamondBatchUploadProfileCreateInput: DiamondBatchUploadProfileCreateInput;
  DiamondBatchUploadProfileDeleteResponse: DiamondBatchUploadProfileDeleteResponse;
  DiamondBatchUploadProfileEdge: DiamondBatchUploadProfileEdge;
  DiamondBatchUploadProfileFilter: DiamondBatchUploadProfileFilter;
  DiamondBatchUploadProfileMaxAggregate: DiamondBatchUploadProfileMaxAggregate;
  DiamondBatchUploadProfileMinAggregate: DiamondBatchUploadProfileMinAggregate;
  DiamondBatchUploadProfileSort: DiamondBatchUploadProfileSort;
  DiamondBatchUploadProfileUpdateInput: DiamondBatchUploadProfileUpdateInput;
  DiamondFeedPerspective: DiamondFeedPerspective;
  DiamondFeedPerspectiveAggregateGroupBy: DiamondFeedPerspectiveAggregateGroupBy;
  DiamondFeedPerspectiveConnection: DiamondFeedPerspectiveConnection;
  DiamondFeedPerspectiveCountAggregate: DiamondFeedPerspectiveCountAggregate;
  DiamondFeedPerspectiveDeleteFilter: DiamondFeedPerspectiveDeleteFilter;
  DiamondFeedPerspectiveDeleteResponse: DiamondFeedPerspectiveDeleteResponse;
  DiamondFeedPerspectiveEdge: DiamondFeedPerspectiveEdge;
  DiamondFeedPerspectiveFilter: DiamondFeedPerspectiveFilter;
  DiamondFeedPerspectiveInput: DiamondFeedPerspectiveInput;
  DiamondFeedPerspectiveMaxAggregate: DiamondFeedPerspectiveMaxAggregate;
  DiamondFeedPerspectiveMinAggregate: DiamondFeedPerspectiveMinAggregate;
  DiamondFeedPerspectiveSort: DiamondFeedPerspectiveSort;
  DiamondFeedPerspectiveUpdateInput: DiamondFeedPerspectiveUpdateInput;
  DiamondOnsellController: DiamondOnsellController;
  DiamondOnsellControllerAggregateGroupBy: DiamondOnsellControllerAggregateGroupBy;
  DiamondOnsellControllerConnection: DiamondOnsellControllerConnection;
  DiamondOnsellControllerCountAggregate: DiamondOnsellControllerCountAggregate;
  DiamondOnsellControllerCreateInput: DiamondOnsellControllerCreateInput;
  DiamondOnsellControllerDeleteResponse: DiamondOnsellControllerDeleteResponse;
  DiamondOnsellControllerEdge: DiamondOnsellControllerEdge;
  DiamondOnsellControllerFilter: DiamondOnsellControllerFilter;
  DiamondOnsellControllerMaxAggregate: DiamondOnsellControllerMaxAggregate;
  DiamondOnsellControllerMinAggregate: DiamondOnsellControllerMinAggregate;
  DiamondOnsellControllerSort: DiamondOnsellControllerSort;
  DiamondOnsellControllerUpdateInput: DiamondOnsellControllerUpdateInput;
  DiamondProduct: DiamondProduct;
  DiamondProductAggregateFilter: DiamondProductAggregateFilter;
  DiamondProductAggregateGroupBy: DiamondProductAggregateGroupBy;
  DiamondProductAggregateResponse: DiamondProductAggregateResponse;
  DiamondProductArchive: DiamondProductArchive;
  DiamondProductArchiveCertificateKey: DiamondProductArchiveCertificateKey;
  DiamondProductArchiveCertificateQueryInput: DiamondProductArchiveCertificateQueryInput;
  DiamondProductArchiveVendorKey: DiamondProductArchiveVendorKey;
  DiamondProductArchiveVendorQueryInput: DiamondProductArchiveVendorQueryInput;
  DiamondProductAvgAggregate: DiamondProductAvgAggregate;
  DiamondProductClarityGradeFilterComparison: DiamondProductClarityGradeFilterComparison;
  DiamondProductConnection: DiamondProductConnection;
  DiamondProductCountAggregate: DiamondProductCountAggregate;
  DiamondProductCrownInclusionBlackFilterComparison: DiamondProductCrownInclusionBlackFilterComparison;
  DiamondProductCrownInclusionFilterComparison: DiamondProductCrownInclusionFilterComparison;
  DiamondProductCutGradeFilterComparison: DiamondProductCutGradeFilterComparison;
  DiamondProductEdge: DiamondProductEdge;
  DiamondProductExternal: DiamondProductExternal;
  DiamondProductExternalAggregateGroupBy: DiamondProductExternalAggregateGroupBy;
  DiamondProductExternalAvgAggregate: DiamondProductExternalAvgAggregate;
  DiamondProductExternalClarityGradeFilterComparison: DiamondProductExternalClarityGradeFilterComparison;
  DiamondProductExternalConnection: DiamondProductExternalConnection;
  DiamondProductExternalCountAggregate: DiamondProductExternalCountAggregate;
  DiamondProductExternalCrownInclusionBlackFilterComparison: DiamondProductExternalCrownInclusionBlackFilterComparison;
  DiamondProductExternalCrownInclusionFilterComparison: DiamondProductExternalCrownInclusionFilterComparison;
  DiamondProductExternalCutGradeFilterComparison: DiamondProductExternalCutGradeFilterComparison;
  DiamondProductExternalEdge: DiamondProductExternalEdge;
  DiamondProductExternalFancyColorIntensityGradeFilterComparison: DiamondProductExternalFancyColorIntensityGradeFilterComparison;
  DiamondProductExternalFancyColorPrimaryFilterComparison: DiamondProductExternalFancyColorPrimaryFilterComparison;
  DiamondProductExternalFancyColorSecondaryFilterComparison: DiamondProductExternalFancyColorSecondaryFilterComparison;
  DiamondProductExternalFilter: DiamondProductExternalFilter;
  DiamondProductExternalFilterDiamondProductMediaFilter: DiamondProductExternalFilterDiamondProductMediaFilter;
  DiamondProductExternalFilterVendorFilter: DiamondProductExternalFilterVendorFilter;
  DiamondProductExternalFluorescenceColorPrimaryFilterComparison: DiamondProductExternalFluorescenceColorPrimaryFilterComparison;
  DiamondProductExternalFluorescenceIntensityGradeFilterComparison: DiamondProductExternalFluorescenceIntensityGradeFilterComparison;
  DiamondProductExternalGirdleMaxFilterComparison: DiamondProductExternalGirdleMaxFilterComparison;
  DiamondProductExternalGirdleMinFilterComparison: DiamondProductExternalGirdleMinFilterComparison;
  DiamondProductExternalGrowthProcessFilterComparison: DiamondProductExternalGrowthProcessFilterComparison;
  DiamondProductExternalIdFilterComparison: DiamondProductExternalIdFilterComparison;
  DiamondProductExternalLusterGradeFilterComparison: DiamondProductExternalLusterGradeFilterComparison;
  DiamondProductExternalMaxAggregate: DiamondProductExternalMaxAggregate;
  DiamondProductExternalMilkyGradeFilterComparison: DiamondProductExternalMilkyGradeFilterComparison;
  DiamondProductExternalMinAggregate: DiamondProductExternalMinAggregate;
  DiamondProductExternalPolishGradeFilterComparison: DiamondProductExternalPolishGradeFilterComparison;
  DiamondProductExternalSarineLightPerformanceFilterComparison: DiamondProductExternalSarineLightPerformanceFilterComparison;
  DiamondProductExternalShapeFilterComparison: DiamondProductExternalShapeFilterComparison;
  DiamondProductExternalSort: DiamondProductExternalSort;
  DiamondProductExternalStatusFilterComparison: DiamondProductExternalStatusFilterComparison;
  DiamondProductExternalSumAggregate: DiamondProductExternalSumAggregate;
  DiamondProductExternalSupplierIdFilterComparison: DiamondProductExternalSupplierIdFilterComparison;
  DiamondProductExternalSymmetryGradeFilterComparison: DiamondProductExternalSymmetryGradeFilterComparison;
  DiamondProductExternalTableInclusionBlackFilterComparison: DiamondProductExternalTableInclusionBlackFilterComparison;
  DiamondProductExternalTableInclusionFilterComparison: DiamondProductExternalTableInclusionFilterComparison;
  DiamondProductExternalVendorIdFilterComparison: DiamondProductExternalVendorIdFilterComparison;
  DiamondProductFancyColorIntensityGradeFilterComparison: DiamondProductFancyColorIntensityGradeFilterComparison;
  DiamondProductFancyColorPrimaryFilterComparison: DiamondProductFancyColorPrimaryFilterComparison;
  DiamondProductFancyColorSecondaryFilterComparison: DiamondProductFancyColorSecondaryFilterComparison;
  DiamondProductFilter: DiamondProductFilter;
  DiamondProductFilterDiamondProductFilter: DiamondProductFilterDiamondProductFilter;
  DiamondProductFilterDiamondProductMediaFilter: DiamondProductFilterDiamondProductMediaFilter;
  DiamondProductFilterVendorFilter: DiamondProductFilterVendorFilter;
  DiamondProductFluorescenceColorPrimaryFilterComparison: DiamondProductFluorescenceColorPrimaryFilterComparison;
  DiamondProductFluorescenceIntensityGradeFilterComparison: DiamondProductFluorescenceIntensityGradeFilterComparison;
  DiamondProductGirdleMaxFilterComparison: DiamondProductGirdleMaxFilterComparison;
  DiamondProductGirdleMinFilterComparison: DiamondProductGirdleMinFilterComparison;
  DiamondProductGrowthProcessFilterComparison: DiamondProductGrowthProcessFilterComparison;
  DiamondProductIdFilterComparison: DiamondProductIdFilterComparison;
  DiamondProductLusterGradeFilterComparison: DiamondProductLusterGradeFilterComparison;
  DiamondProductMaxAggregate: DiamondProductMaxAggregate;
  DiamondProductMedia: DiamondProductMedia;
  DiamondProductMediaAggregateFilter: DiamondProductMediaAggregateFilter;
  DiamondProductMediaAggregateGroupBy: DiamondProductMediaAggregateGroupBy;
  DiamondProductMediaAggregateResponse: DiamondProductMediaAggregateResponse;
  DiamondProductMediaCountAggregate: DiamondProductMediaCountAggregate;
  DiamondProductMediaFilter: DiamondProductMediaFilter;
  DiamondProductMediaMaxAggregate: DiamondProductMediaMaxAggregate;
  DiamondProductMediaMinAggregate: DiamondProductMediaMinAggregate;
  DiamondProductMediaSort: DiamondProductMediaSort;
  DiamondProductMilkyGradeFilterComparison: DiamondProductMilkyGradeFilterComparison;
  DiamondProductMinAggregate: DiamondProductMinAggregate;
  DiamondProductPolishGradeFilterComparison: DiamondProductPolishGradeFilterComparison;
  DiamondProductPricingCalculator: DiamondProductPricingCalculator;
  DiamondProductPricingCalculatorAggregateGroupBy: DiamondProductPricingCalculatorAggregateGroupBy;
  DiamondProductPricingCalculatorConnection: DiamondProductPricingCalculatorConnection;
  DiamondProductPricingCalculatorCountAggregate: DiamondProductPricingCalculatorCountAggregate;
  DiamondProductPricingCalculatorDeleteResponse: DiamondProductPricingCalculatorDeleteResponse;
  DiamondProductPricingCalculatorEdge: DiamondProductPricingCalculatorEdge;
  DiamondProductPricingCalculatorFilter: DiamondProductPricingCalculatorFilter;
  DiamondProductPricingCalculatorInput: DiamondProductPricingCalculatorInput;
  DiamondProductPricingCalculatorMaxAggregate: DiamondProductPricingCalculatorMaxAggregate;
  DiamondProductPricingCalculatorMinAggregate: DiamondProductPricingCalculatorMinAggregate;
  DiamondProductPricingCalculatorSort: DiamondProductPricingCalculatorSort;
  DiamondProductSarineLightPerformanceFilterComparison: DiamondProductSarineLightPerformanceFilterComparison;
  DiamondProductShapeFilterComparison: DiamondProductShapeFilterComparison;
  DiamondProductSort: DiamondProductSort;
  DiamondProductStatusFilterComparison: DiamondProductStatusFilterComparison;
  DiamondProductSumAggregate: DiamondProductSumAggregate;
  DiamondProductSupplierIdFilterComparison: DiamondProductSupplierIdFilterComparison;
  DiamondProductSymmetryGradeFilterComparison: DiamondProductSymmetryGradeFilterComparison;
  DiamondProductTableInclusionBlackFilterComparison: DiamondProductTableInclusionBlackFilterComparison;
  DiamondProductTableInclusionFilterComparison: DiamondProductTableInclusionFilterComparison;
  DiamondProductUpdateFilter: DiamondProductUpdateFilter;
  DiamondProductUpdateInput: DiamondProductUpdateInput;
  DiamondProductUpstreamProductIdFilterComparison: DiamondProductUpstreamProductIdFilterComparison;
  DiamondProductVendorIdFilterComparison: DiamondProductVendorIdFilterComparison;
  ExternalUser: ExternalUser;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  IDFilterComparison: IdFilterComparison;
  Int: Scalars['Int'];
  JSON: Scalars['JSON'];
  Mutation: {};
  NumberFieldComparison: NumberFieldComparison;
  NumberFieldComparisonBetween: NumberFieldComparisonBetween;
  PageInfo: PageInfo;
  PhysicalAddress: PhysicalAddress;
  PhysicalAddressAggregateGroupBy: PhysicalAddressAggregateGroupBy;
  PhysicalAddressConnection: PhysicalAddressConnection;
  PhysicalAddressCountAggregate: PhysicalAddressCountAggregate;
  PhysicalAddressCreateInput: PhysicalAddressCreateInput;
  PhysicalAddressDeleteFilter: PhysicalAddressDeleteFilter;
  PhysicalAddressDeleteResponse: PhysicalAddressDeleteResponse;
  PhysicalAddressEdge: PhysicalAddressEdge;
  PhysicalAddressFilter: PhysicalAddressFilter;
  PhysicalAddressIdFilterComparison: PhysicalAddressIdFilterComparison;
  PhysicalAddressMaxAggregate: PhysicalAddressMaxAggregate;
  PhysicalAddressMinAggregate: PhysicalAddressMinAggregate;
  PhysicalAddressSort: PhysicalAddressSort;
  PhysicalAddressUpdateFilter: PhysicalAddressUpdateFilter;
  PhysicalAddressUpdateInput: PhysicalAddressUpdateInput;
  Query: {};
  RemoveDefaultShippingAddressFromAccountInput: RemoveDefaultShippingAddressFromAccountInput;
  RemoveEventsFromActivityEventTopicInput: RemoveEventsFromActivityEventTopicInput;
  RemoveFeedPerspectiveFromAccountContactInput: RemoveFeedPerspectiveFromAccountContactInput;
  RemoveFeedPerspectiveFromAccountInput: RemoveFeedPerspectiveFromAccountInput;
  RemoveMediaFromDiamondProductExternalInput: RemoveMediaFromDiamondProductExternalInput;
  RemoveMediaFromDiamondProductInput: RemoveMediaFromDiamondProductInput;
  RemoveOwnerFromPhysicalAddressInput: RemoveOwnerFromPhysicalAddressInput;
  RemoveUpstreamVendorsFromDiamondOnsellControllerInput: RemoveUpstreamVendorsFromDiamondOnsellControllerInput;
  RemoveVendorFromActivityEventTopicInput: RemoveVendorFromActivityEventTopicInput;
  RemoveVendorFromVendorVariableInput: RemoveVendorFromVendorVariableInput;
  RemoveVendorsFromClientTableConfigInput: RemoveVendorsFromClientTableConfigInput;
  RemoveVendorsFromUserInput: RemoveVendorsFromUserInput;
  SendRawEmailInput: SendRawEmailInput;
  SetAccountOnVendorInput: SetAccountOnVendorInput;
  SetCalculatorOnDiamondOnsellControllerInput: SetCalculatorOnDiamondOnsellControllerInput;
  SetDefaultShippingAddressOnAccountInput: SetDefaultShippingAddressOnAccountInput;
  SetEventsOnActivityEventTopicInput: SetEventsOnActivityEventTopicInput;
  SetFeedPerspectiveOnAccountContactInput: SetFeedPerspectiveOnAccountContactInput;
  SetFeedPerspectiveOnAccountInput: SetFeedPerspectiveOnAccountInput;
  SetMediaOnDiamondProductExternalInput: SetMediaOnDiamondProductExternalInput;
  SetMediaOnDiamondProductInput: SetMediaOnDiamondProductInput;
  SetOwnerOnPhysicalAddressInput: SetOwnerOnPhysicalAddressInput;
  SetUpstreamVendorsOnDiamondOnsellControllerInput: SetUpstreamVendorsOnDiamondOnsellControllerInput;
  SetVendorOnActivityEventTopicInput: SetVendorOnActivityEventTopicInput;
  SetVendorOnVendorVariableInput: SetVendorOnVendorVariableInput;
  SetVendorsOnClientTableConfigInput: SetVendorsOnClientTableConfigInput;
  SetVendorsOnUserInput: SetVendorsOnUserInput;
  String: Scalars['String'];
  StringFieldComparison: StringFieldComparison;
  UpdateManyAccountContactsInput: UpdateManyAccountContactsInput;
  UpdateManyAccountsInput: UpdateManyAccountsInput;
  UpdateManyDiamondProductsInput: UpdateManyDiamondProductsInput;
  UpdateManyPhysicalAddressesInput: UpdateManyPhysicalAddressesInput;
  UpdateManyResponse: UpdateManyResponse;
  UpdateManyVendorsInput: UpdateManyVendorsInput;
  UpdateOneAccountContactInput: UpdateOneAccountContactInput;
  UpdateOneAccountInput: UpdateOneAccountInput;
  UpdateOneActivityEventInput: UpdateOneActivityEventInput;
  UpdateOneActivityEventTopicInput: UpdateOneActivityEventTopicInput;
  UpdateOneClientTableConfigInput: UpdateOneClientTableConfigInput;
  UpdateOneDiamondBatchUploadProfileInput: UpdateOneDiamondBatchUploadProfileInput;
  UpdateOneDiamondFeedPerspectiveInput: UpdateOneDiamondFeedPerspectiveInput;
  UpdateOneDiamondOnsellControllerInput: UpdateOneDiamondOnsellControllerInput;
  UpdateOneDiamondProductInput: UpdateOneDiamondProductInput;
  UpdateOneDiamondProductPricingCalculatorInput: UpdateOneDiamondProductPricingCalculatorInput;
  UpdateOnePhysicalAddressInput: UpdateOnePhysicalAddressInput;
  UpdateOneUserInput: UpdateOneUserInput;
  UpdateOneVendorInput: UpdateOneVendorInput;
  UpdateOneVendorVariableInput: UpdateOneVendorVariableInput;
  Upload: Scalars['Upload'];
  UploadFileForBatchUploadJobResponse: UploadFileForBatchUploadJobResponse;
  User: User;
  UserAggregateGroupBy: UserAggregateGroupBy;
  UserAuth0IdFilterComparison: UserAuth0IdFilterComparison;
  UserConnection: UserConnection;
  UserCountAggregate: UserCountAggregate;
  UserEdge: UserEdge;
  UserFilter: UserFilter;
  UserIdFilterComparison: UserIdFilterComparison;
  UserMaxAggregate: UserMaxAggregate;
  UserMinAggregate: UserMinAggregate;
  UserSort: UserSort;
  UserUpdateInput: UserUpdateInput;
  Vendor: Vendor;
  VendorAggregateGroupBy: VendorAggregateGroupBy;
  VendorConnection: VendorConnection;
  VendorCountAggregate: VendorCountAggregate;
  VendorCreateInput: VendorCreateInput;
  VendorDeleteFilter: VendorDeleteFilter;
  VendorDeleteResponse: VendorDeleteResponse;
  VendorEdge: VendorEdge;
  VendorFilter: VendorFilter;
  VendorIdFilterComparison: VendorIdFilterComparison;
  VendorMaxAggregate: VendorMaxAggregate;
  VendorMinAggregate: VendorMinAggregate;
  VendorShortnameFilterComparison: VendorShortnameFilterComparison;
  VendorSort: VendorSort;
  VendorUpdateFilter: VendorUpdateFilter;
  VendorUpdateInput: VendorUpdateInput;
  VendorVariable: VendorVariable;
  VendorVariableAggregateGroupBy: VendorVariableAggregateGroupBy;
  VendorVariableConnection: VendorVariableConnection;
  VendorVariableCountAggregate: VendorVariableCountAggregate;
  VendorVariableCreateInput: VendorVariableCreateInput;
  VendorVariableDeleteResponse: VendorVariableDeleteResponse;
  VendorVariableEdge: VendorVariableEdge;
  VendorVariableFilter: VendorVariableFilter;
  VendorVariableMaxAggregate: VendorVariableMaxAggregate;
  VendorVariableMinAggregate: VendorVariableMinAggregate;
  VendorVariableSort: VendorVariableSort;
  VendorVariableUpdateInput: VendorVariableUpdateInput;
};

export type AccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  defaultShippingAddress?: Resolver<Maybe<ResolversTypes['PhysicalAddress']>, ParentType, ContextType>;
  defaultShippingAddressId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedPerspective?: Resolver<Maybe<ResolversTypes['DiamondFeedPerspective']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountAggregateGroupBy'] = ResolversParentTypes['AccountAggregateGroupBy']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountConnection'] = ResolversParentTypes['AccountConnection']> = {
  edges?: Resolver<Array<ResolversTypes['AccountEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContact'] = ResolversParentTypes['AccountContact']> = {
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  accountId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  feedPerspective?: Resolver<Maybe<ResolversTypes['DiamondFeedPerspective']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactAggregateGroupBy'] = ResolversParentTypes['AccountContactAggregateGroupBy']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactConnection'] = ResolversParentTypes['AccountContactConnection']> = {
  edges?: Resolver<Array<ResolversTypes['AccountContactEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactCountAggregate'] = ResolversParentTypes['AccountContactCountAggregate']> = {
  accountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactDeleteResponse'] = ResolversParentTypes['AccountContactDeleteResponse']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactEdge'] = ResolversParentTypes['AccountContactEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AccountContact'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactMaxAggregate'] = ResolversParentTypes['AccountContactMaxAggregate']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountContactMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountContactMinAggregate'] = ResolversParentTypes['AccountContactMinAggregate']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountCountAggregate'] = ResolversParentTypes['AccountCountAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountDeleteResponse'] = ResolversParentTypes['AccountDeleteResponse']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  defaultShippingAddressId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountEdge'] = ResolversParentTypes['AccountEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountMaxAggregate'] = ResolversParentTypes['AccountMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountMinAggregate'] = ResolversParentTypes['AccountMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  feedPerspectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEvent'] = ResolversParentTypes['ActivityEvent']> = {
  author?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  authorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  authorType?: Resolver<ResolversTypes['ActivityEventAuthorType'], ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  topicId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ActivityEventType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventAggregateGroupBy'] = ResolversParentTypes['ActivityEventAggregateGroupBy']> = {
  authorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventConnection'] = ResolversParentTypes['ActivityEventConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ActivityEventEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventCountAggregate'] = ResolversParentTypes['ActivityEventCountAggregate']> = {
  authorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  topicId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventDeleteResponse'] = ResolversParentTypes['ActivityEventDeleteResponse']> = {
  authorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  authorType?: Resolver<Maybe<ResolversTypes['ActivityEventAuthorType']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  topicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ActivityEventType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventEdge'] = ResolversParentTypes['ActivityEventEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ActivityEvent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventMaxAggregate'] = ResolversParentTypes['ActivityEventMaxAggregate']> = {
  authorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventMinAggregate'] = ResolversParentTypes['ActivityEventMinAggregate']> = {
  authorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopic'] = ResolversParentTypes['ActivityEventTopic']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  events?: Resolver<ResolversTypes['ActivityEventTopicEventsConnection'], ParentType, ContextType, RequireFields<ActivityEventTopicEventsArgs, 'filter' | 'paging' | 'sorting'>>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopicAggregateGroupBy'] = ResolversParentTypes['ActivityEventTopicAggregateGroupBy']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  entityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopicConnection'] = ResolversParentTypes['ActivityEventTopicConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ActivityEventTopicEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopicCountAggregate'] = ResolversParentTypes['ActivityEventTopicCountAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  entityId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopicEdge'] = ResolversParentTypes['ActivityEventTopicEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicEventsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopicEventsConnection'] = ResolversParentTypes['ActivityEventTopicEventsConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ActivityEventEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopicMaxAggregate'] = ResolversParentTypes['ActivityEventTopicMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  entityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityEventTopicMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityEventTopicMinAggregate'] = ResolversParentTypes['ActivityEventTopicMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  entityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfig'] = ResolversParentTypes['ClientTableConfig']> = {
  config?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendors?: Resolver<Array<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<ClientTableConfigVendorsArgs, 'filter' | 'sorting'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfigAggregateGroupBy'] = ResolversParentTypes['ClientTableConfigAggregateGroupBy']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfigConnection'] = ResolversParentTypes['ClientTableConfigConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ClientTableConfigEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfigCountAggregate'] = ResolversParentTypes['ClientTableConfigCountAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfigDeleteResponse'] = ResolversParentTypes['ClientTableConfigDeleteResponse']> = {
  config?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfigEdge'] = ResolversParentTypes['ClientTableConfigEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ClientTableConfig'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfigMaxAggregate'] = ResolversParentTypes['ClientTableConfigMaxAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTableConfigMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTableConfigMinAggregate'] = ResolversParentTypes['ClientTableConfigMinAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ConnectionCursorScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ConnectionCursor'], any> {
  name: 'ConnectionCursor';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteManyResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteManyResponse'] = ResolversParentTypes['DeleteManyResponse']> = {
  deletedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchPricingJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchPricingJob'] = ResolversParentTypes['DiamondBatchPricingJob']> = {
  calculator?: Resolver<Maybe<ResolversTypes['DiamondProductPricingCalculator']>, ParentType, ContextType>;
  calculatorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  calculatorVariablesSnapshot?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isManual?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  logs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['DiamondBatchPricingJobMode'], ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['JobState'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  upstreamVendors?: Resolver<Array<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<DiamondBatchPricingJobUpstreamVendorsArgs, 'filter' | 'sorting'>>;
  vendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchPricingJobAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchPricingJobAggregateGroupBy'] = ResolversParentTypes['DiamondBatchPricingJobAggregateGroupBy']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchPricingJobConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchPricingJobConnection'] = ResolversParentTypes['DiamondBatchPricingJobConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondBatchPricingJobEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchPricingJobCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchPricingJobCountAggregate'] = ResolversParentTypes['DiamondBatchPricingJobCountAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchPricingJobEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchPricingJobEdge'] = ResolversParentTypes['DiamondBatchPricingJobEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondBatchPricingJob'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchPricingJobMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchPricingJobMaxAggregate'] = ResolversParentTypes['DiamondBatchPricingJobMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchPricingJobMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchPricingJobMinAggregate'] = ResolversParentTypes['DiamondBatchPricingJobMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadJob'] = ResolversParentTypes['DiamondBatchUploadJob']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  failedDocuments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  file?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPreview?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  logs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['DiamondBatchUploadProfile'], ParentType, ContextType>;
  profileId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['JobState'], ParentType, ContextType>;
  totalDocuments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadJobAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadJobAggregateGroupBy'] = ResolversParentTypes['DiamondBatchUploadJobAggregateGroupBy']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isPreview?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadJobConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadJobConnection'] = ResolversParentTypes['DiamondBatchUploadJobConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondBatchUploadJobEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadJobCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadJobCountAggregate'] = ResolversParentTypes['DiamondBatchUploadJobCountAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isPreview?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadJobEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadJobEdge'] = ResolversParentTypes['DiamondBatchUploadJobEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondBatchUploadJob'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadJobMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadJobMaxAggregate'] = ResolversParentTypes['DiamondBatchUploadJobMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadJobMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadJobMinAggregate'] = ResolversParentTypes['DiamondBatchUploadJobMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfile'] = ResolversParentTypes['DiamondBatchUploadProfile']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resolvers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfileAggregateGroupBy'] = ResolversParentTypes['DiamondBatchUploadProfileAggregateGroupBy']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfileConnection'] = ResolversParentTypes['DiamondBatchUploadProfileConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondBatchUploadProfileEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfileCountAggregate'] = ResolversParentTypes['DiamondBatchUploadProfileCountAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfileDeleteResponse'] = ResolversParentTypes['DiamondBatchUploadProfileDeleteResponse']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolvers?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfileEdge'] = ResolversParentTypes['DiamondBatchUploadProfileEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondBatchUploadProfile'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfileMaxAggregate'] = ResolversParentTypes['DiamondBatchUploadProfileMaxAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondBatchUploadProfileMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondBatchUploadProfileMinAggregate'] = ResolversParentTypes['DiamondBatchUploadProfileMinAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspective'] = ResolversParentTypes['DiamondFeedPerspective']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filter?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceExpression?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspectiveAggregateGroupBy'] = ResolversParentTypes['DiamondFeedPerspectiveAggregateGroupBy']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspectiveConnection'] = ResolversParentTypes['DiamondFeedPerspectiveConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondFeedPerspectiveEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspectiveCountAggregate'] = ResolversParentTypes['DiamondFeedPerspectiveCountAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspectiveDeleteResponse'] = ResolversParentTypes['DiamondFeedPerspectiveDeleteResponse']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filter?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceExpression?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspectiveEdge'] = ResolversParentTypes['DiamondFeedPerspectiveEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondFeedPerspective'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspectiveMaxAggregate'] = ResolversParentTypes['DiamondFeedPerspectiveMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondFeedPerspectiveMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondFeedPerspectiveMinAggregate'] = ResolversParentTypes['DiamondFeedPerspectiveMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellController'] = ResolversParentTypes['DiamondOnsellController']> = {
  calculator?: Resolver<Maybe<ResolversTypes['DiamondProductPricingCalculator']>, ParentType, ContextType>;
  calculatorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  upstreamVendors?: Resolver<Array<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<DiamondOnsellControllerUpstreamVendorsArgs, 'filter' | 'sorting'>>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellControllerAggregateGroupBy'] = ResolversParentTypes['DiamondOnsellControllerAggregateGroupBy']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellControllerConnection'] = ResolversParentTypes['DiamondOnsellControllerConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondOnsellControllerEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellControllerCountAggregate'] = ResolversParentTypes['DiamondOnsellControllerCountAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellControllerDeleteResponse'] = ResolversParentTypes['DiamondOnsellControllerDeleteResponse']> = {
  calculatorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellControllerEdge'] = ResolversParentTypes['DiamondOnsellControllerEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondOnsellController'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellControllerMaxAggregate'] = ResolversParentTypes['DiamondOnsellControllerMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondOnsellControllerMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondOnsellControllerMinAggregate'] = ResolversParentTypes['DiamondOnsellControllerMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProduct'] = ResolversParentTypes['DiamondProduct']> = {
  caratWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  crownAngle?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  crownHeightPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  hasCertificateMedia?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasImageMedia?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasVideoMedia?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  media?: Resolver<Array<ResolversTypes['DiamondProductMedia']>, ParentType, ContextType, RequireFields<DiamondProductMediaArgs, 'filter' | 'sorting'>>;
  mediaAggregate?: Resolver<Array<ResolversTypes['DiamondProductMediaAggregateResponse']>, ParentType, ContextType, Partial<DiamondProductMediaAggregateArgs>>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pavillionAngle?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pavillionDepthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pricePerCarat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reflectUpstreamStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sarineJourney?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DiamondProductAvailability'], ParentType, ContextType>;
  supplier?: Resolver<Maybe<ResolversTypes['Vendor']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  upstreamProduct?: Resolver<Maybe<ResolversTypes['DiamondProduct']>, ParentType, ContextType>;
  upstreamProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductAggregateGroupBy'] = ResolversParentTypes['DiamondProductAggregateGroupBy']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  hasCertificateMedia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasImageMedia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasVideoMedia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  reflectUpstreamStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sarineJourney?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DiamondProductAvailability']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  upstreamProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductAggregateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductAggregateResponse'] = ResolversParentTypes['DiamondProductAggregateResponse']> = {
  avg?: Resolver<Maybe<ResolversTypes['DiamondProductAvgAggregate']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['DiamondProductCountAggregate']>, ParentType, ContextType>;
  groupBy?: Resolver<Maybe<ResolversTypes['DiamondProductAggregateGroupBy']>, ParentType, ContextType>;
  max?: Resolver<Maybe<ResolversTypes['DiamondProductMaxAggregate']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['DiamondProductMinAggregate']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['DiamondProductSumAggregate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductArchiveResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductArchive'] = ResolversParentTypes['DiamondProductArchive']> = {
  certificate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  diamondProduct?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  diamondProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  upstreamProductId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductArchiveCertificateKeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductArchiveCertificateKey'] = ResolversParentTypes['DiamondProductArchiveCertificateKey']> = {
  certificate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  diamondProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductArchiveVendorKeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductArchiveVendorKey'] = ResolversParentTypes['DiamondProductArchiveVendorKey']> = {
  diamondProductId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductAvgAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductAvgAggregate'] = ResolversParentTypes['DiamondProductAvgAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductConnection'] = ResolversParentTypes['DiamondProductConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondProductEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductCountAggregate'] = ResolversParentTypes['DiamondProductCountAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasCertificateMedia?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasImageMedia?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasVideoMedia?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  reflectUpstreamStatus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sarineJourney?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  upstreamProductId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductEdge'] = ResolversParentTypes['DiamondProductEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondProduct'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternal'] = ResolversParentTypes['DiamondProductExternal']> = {
  caratWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  crownAngle?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  crownHeightPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  hasCertificateMedia?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasImageMedia?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasVideoMedia?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  media?: Resolver<Array<ResolversTypes['DiamondProductMedia']>, ParentType, ContextType, RequireFields<DiamondProductExternalMediaArgs, 'filter' | 'sorting'>>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pavillionAngle?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pavillionDepthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pricePerCarat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sarineJourney?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DiamondProductAvailability'], ParentType, ContextType>;
  supplier?: Resolver<Maybe<ResolversTypes['Vendor']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalAggregateGroupBy'] = ResolversParentTypes['DiamondProductExternalAggregateGroupBy']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  hasCertificateMedia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasImageMedia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasVideoMedia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sarineJourney?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DiamondProductAvailability']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalAvgAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalAvgAggregate'] = ResolversParentTypes['DiamondProductExternalAvgAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalConnection'] = ResolversParentTypes['DiamondProductExternalConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondProductExternalEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalCountAggregate'] = ResolversParentTypes['DiamondProductExternalCountAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasCertificateMedia?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasImageMedia?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasVideoMedia?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sarineJourney?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalEdge'] = ResolversParentTypes['DiamondProductExternalEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondProductExternal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalMaxAggregate'] = ResolversParentTypes['DiamondProductExternalMaxAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DiamondProductAvailability']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalMinAggregate'] = ResolversParentTypes['DiamondProductExternalMinAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DiamondProductAvailability']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductExternalSumAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductExternalSumAggregate'] = ResolversParentTypes['DiamondProductExternalSumAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMaxAggregate'] = ResolversParentTypes['DiamondProductMaxAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DiamondProductAvailability']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  upstreamProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMedia'] = ResolversParentTypes['DiamondProductMedia']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMediaAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMediaAggregateGroupBy'] = ResolversParentTypes['DiamondProductMediaAggregateGroupBy']> = {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMediaAggregateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMediaAggregateResponse'] = ResolversParentTypes['DiamondProductMediaAggregateResponse']> = {
  count?: Resolver<Maybe<ResolversTypes['DiamondProductMediaCountAggregate']>, ParentType, ContextType>;
  groupBy?: Resolver<Maybe<ResolversTypes['DiamondProductMediaAggregateGroupBy']>, ParentType, ContextType>;
  max?: Resolver<Maybe<ResolversTypes['DiamondProductMediaMaxAggregate']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['DiamondProductMediaMinAggregate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMediaCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMediaCountAggregate'] = ResolversParentTypes['DiamondProductMediaCountAggregate']> = {
  label?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMediaMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMediaMaxAggregate'] = ResolversParentTypes['DiamondProductMediaMaxAggregate']> = {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMediaMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMediaMinAggregate'] = ResolversParentTypes['DiamondProductMediaMinAggregate']> = {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductMinAggregate'] = ResolversParentTypes['DiamondProductMinAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  certificationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clarityGrade?: Resolver<Maybe<ResolversTypes['ClarityGrade']>, ParentType, ContextType>;
  colorGrade?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crownInclusion?: Resolver<Maybe<ResolversTypes['CrownInclusion']>, ParentType, ContextType>;
  crownInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cutGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fancyColorIntensityGrade?: Resolver<Maybe<ResolversTypes['FancyColorIntensityGrade']>, ParentType, ContextType>;
  fancyColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fancyColorSecondary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceColorPrimary?: Resolver<Maybe<ResolversTypes['DiamondColor']>, ParentType, ContextType>;
  fluorescenceIntensityGrade?: Resolver<Maybe<ResolversTypes['FluorescenceIntensityGrade']>, ParentType, ContextType>;
  girdleMax?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleMin?: Resolver<Maybe<ResolversTypes['GirdleSize']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  growthProcess?: Resolver<Maybe<ResolversTypes['GrowthProcess']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lusterGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  milkyGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  polishGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sarineLightPerformance?: Resolver<Maybe<ResolversTypes['SarineLightPerformance']>, ParentType, ContextType>;
  shape?: Resolver<Maybe<ResolversTypes['ShapeCut']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DiamondProductAvailability']>, ParentType, ContextType>;
  supplierId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  symmetryGrade?: Resolver<Maybe<ResolversTypes['CutGrade']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableInclusion?: Resolver<Maybe<ResolversTypes['TableInclusion']>, ParentType, ContextType>;
  tableInclusionBlack?: Resolver<Maybe<ResolversTypes['BlackInclusion']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  upstreamProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculator'] = ResolversParentTypes['DiamondProductPricingCalculator']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculatorAggregateGroupBy'] = ResolversParentTypes['DiamondProductPricingCalculatorAggregateGroupBy']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculatorConnection'] = ResolversParentTypes['DiamondProductPricingCalculatorConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DiamondProductPricingCalculatorEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculatorCountAggregate'] = ResolversParentTypes['DiamondProductPricingCalculatorCountAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculatorDeleteResponse'] = ResolversParentTypes['DiamondProductPricingCalculatorDeleteResponse']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculatorEdge'] = ResolversParentTypes['DiamondProductPricingCalculatorEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DiamondProductPricingCalculator'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculatorMaxAggregate'] = ResolversParentTypes['DiamondProductPricingCalculatorMaxAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductPricingCalculatorMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductPricingCalculatorMinAggregate'] = ResolversParentTypes['DiamondProductPricingCalculatorMinAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiamondProductSumAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiamondProductSumAggregate'] = ResolversParentTypes['DiamondProductSumAggregate']> = {
  caratWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customPricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  depthPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  girdleThicknessPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lengthWidthRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pricePerCarat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rapnetDiscountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tablePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalUser'] = ResolversParentTypes['ExternalUser']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nickname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addEventsToActivityEventTopic?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType, RequireFields<MutationAddEventsToActivityEventTopicArgs, 'input'>>;
  addMediaToDiamondProduct?: Resolver<ResolversTypes['DiamondProduct'], ParentType, ContextType, RequireFields<MutationAddMediaToDiamondProductArgs, 'input'>>;
  addMediaToDiamondProductExternal?: Resolver<ResolversTypes['DiamondProductExternal'], ParentType, ContextType, RequireFields<MutationAddMediaToDiamondProductExternalArgs, 'input'>>;
  addUpstreamVendorsToDiamondOnsellController?: Resolver<ResolversTypes['DiamondOnsellController'], ParentType, ContextType, RequireFields<MutationAddUpstreamVendorsToDiamondOnsellControllerArgs, 'input'>>;
  addVendorsToClientTableConfig?: Resolver<ResolversTypes['ClientTableConfig'], ParentType, ContextType, RequireFields<MutationAddVendorsToClientTableConfigArgs, 'input'>>;
  addVendorsToUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationAddVendorsToUserArgs, 'input'>>;
  createManyAccountContacts?: Resolver<Array<ResolversTypes['AccountContact']>, ParentType, ContextType, RequireFields<MutationCreateManyAccountContactsArgs, 'input'>>;
  createManyAccounts?: Resolver<Array<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<MutationCreateManyAccountsArgs, 'input'>>;
  createManyPhysicalAddresses?: Resolver<Array<ResolversTypes['PhysicalAddress']>, ParentType, ContextType, RequireFields<MutationCreateManyPhysicalAddressesArgs, 'input'>>;
  createManyVendors?: Resolver<Array<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<MutationCreateManyVendorsArgs, 'input'>>;
  createOneAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationCreateOneAccountArgs, 'input'>>;
  createOneAccountContact?: Resolver<ResolversTypes['AccountContact'], ParentType, ContextType, RequireFields<MutationCreateOneAccountContactArgs, 'input'>>;
  createOneActivityEventTopic?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType, RequireFields<MutationCreateOneActivityEventTopicArgs, 'input'>>;
  createOneClientTableConfig?: Resolver<ResolversTypes['ClientTableConfig'], ParentType, ContextType, RequireFields<MutationCreateOneClientTableConfigArgs, 'input'>>;
  createOneComment?: Resolver<ResolversTypes['ActivityEvent'], ParentType, ContextType, RequireFields<MutationCreateOneCommentArgs, 'input'>>;
  createOneDiamondBatchPricingJob?: Resolver<ResolversTypes['DiamondBatchPricingJob'], ParentType, ContextType, RequireFields<MutationCreateOneDiamondBatchPricingJobArgs, 'input'>>;
  createOneDiamondBatchUploadJob?: Resolver<ResolversTypes['DiamondBatchUploadJob'], ParentType, ContextType, RequireFields<MutationCreateOneDiamondBatchUploadJobArgs, 'input'>>;
  createOneDiamondBatchUploadJobForVendor?: Resolver<ResolversTypes['DiamondBatchUploadJob'], ParentType, ContextType, RequireFields<MutationCreateOneDiamondBatchUploadJobForVendorArgs, 'file' | 'vendorShortname'>>;
  createOneDiamondBatchUploadProfile?: Resolver<ResolversTypes['DiamondBatchUploadProfile'], ParentType, ContextType, RequireFields<MutationCreateOneDiamondBatchUploadProfileArgs, 'input'>>;
  createOneDiamondFeedPerspective?: Resolver<ResolversTypes['DiamondFeedPerspective'], ParentType, ContextType, RequireFields<MutationCreateOneDiamondFeedPerspectiveArgs, 'input'>>;
  createOneDiamondOnsellController?: Resolver<ResolversTypes['DiamondOnsellController'], ParentType, ContextType, RequireFields<MutationCreateOneDiamondOnsellControllerArgs, 'input'>>;
  createOneDiamondProductPricingCalculator?: Resolver<ResolversTypes['DiamondProductPricingCalculator'], ParentType, ContextType, RequireFields<MutationCreateOneDiamondProductPricingCalculatorArgs, 'input'>>;
  createOnePhysicalAddress?: Resolver<ResolversTypes['PhysicalAddress'], ParentType, ContextType, RequireFields<MutationCreateOnePhysicalAddressArgs, 'input'>>;
  createOneVendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType, RequireFields<MutationCreateOneVendorArgs, 'input'>>;
  createOneVendorVariable?: Resolver<ResolversTypes['VendorVariable'], ParentType, ContextType, RequireFields<MutationCreateOneVendorVariableArgs, 'input'>>;
  deleteManyAccountContacts?: Resolver<ResolversTypes['DeleteManyResponse'], ParentType, ContextType, RequireFields<MutationDeleteManyAccountContactsArgs, 'input'>>;
  deleteManyAccounts?: Resolver<ResolversTypes['DeleteManyResponse'], ParentType, ContextType, RequireFields<MutationDeleteManyAccountsArgs, 'input'>>;
  deleteManyDiamondFeedPerspectives?: Resolver<ResolversTypes['DeleteManyResponse'], ParentType, ContextType, RequireFields<MutationDeleteManyDiamondFeedPerspectivesArgs, 'input'>>;
  deleteManyPhysicalAddresses?: Resolver<ResolversTypes['DeleteManyResponse'], ParentType, ContextType, RequireFields<MutationDeleteManyPhysicalAddressesArgs, 'input'>>;
  deleteManyVendors?: Resolver<ResolversTypes['DeleteManyResponse'], ParentType, ContextType, RequireFields<MutationDeleteManyVendorsArgs, 'input'>>;
  deleteOneAccount?: Resolver<ResolversTypes['AccountDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneAccountArgs, 'input'>>;
  deleteOneAccountContact?: Resolver<ResolversTypes['AccountContactDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneAccountContactArgs, 'input'>>;
  deleteOneActivityEvent?: Resolver<ResolversTypes['ActivityEventDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneActivityEventArgs, 'input'>>;
  deleteOneClientTableConfig?: Resolver<ResolversTypes['ClientTableConfigDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneClientTableConfigArgs, 'input'>>;
  deleteOneDiamondBatchUploadProfile?: Resolver<ResolversTypes['DiamondBatchUploadProfileDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneDiamondBatchUploadProfileArgs, 'input'>>;
  deleteOneDiamondFeedPerspective?: Resolver<ResolversTypes['DiamondFeedPerspectiveDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneDiamondFeedPerspectiveArgs, 'input'>>;
  deleteOneDiamondOnsellController?: Resolver<ResolversTypes['DiamondOnsellControllerDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneDiamondOnsellControllerArgs, 'input'>>;
  deleteOneDiamondProductPricingCalculator?: Resolver<ResolversTypes['DiamondProductPricingCalculatorDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneDiamondProductPricingCalculatorArgs, 'input'>>;
  deleteOnePhysicalAddress?: Resolver<ResolversTypes['PhysicalAddressDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOnePhysicalAddressArgs, 'input'>>;
  deleteOneVendor?: Resolver<ResolversTypes['VendorDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneVendorArgs, 'input'>>;
  deleteOneVendorVariable?: Resolver<ResolversTypes['VendorVariableDeleteResponse'], ParentType, ContextType, RequireFields<MutationDeleteOneVendorVariableArgs, 'input'>>;
  removeDefaultShippingAddressFromAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationRemoveDefaultShippingAddressFromAccountArgs, 'input'>>;
  removeEventsFromActivityEventTopic?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType, RequireFields<MutationRemoveEventsFromActivityEventTopicArgs, 'input'>>;
  removeFeedPerspectiveFromAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationRemoveFeedPerspectiveFromAccountArgs, 'input'>>;
  removeFeedPerspectiveFromAccountContact?: Resolver<ResolversTypes['AccountContact'], ParentType, ContextType, RequireFields<MutationRemoveFeedPerspectiveFromAccountContactArgs, 'input'>>;
  removeMediaFromDiamondProduct?: Resolver<ResolversTypes['DiamondProduct'], ParentType, ContextType, RequireFields<MutationRemoveMediaFromDiamondProductArgs, 'input'>>;
  removeMediaFromDiamondProductExternal?: Resolver<ResolversTypes['DiamondProductExternal'], ParentType, ContextType, RequireFields<MutationRemoveMediaFromDiamondProductExternalArgs, 'input'>>;
  removeOwnerFromPhysicalAddress?: Resolver<ResolversTypes['PhysicalAddress'], ParentType, ContextType, RequireFields<MutationRemoveOwnerFromPhysicalAddressArgs, 'input'>>;
  removeUpstreamVendorsFromDiamondOnsellController?: Resolver<ResolversTypes['DiamondOnsellController'], ParentType, ContextType, RequireFields<MutationRemoveUpstreamVendorsFromDiamondOnsellControllerArgs, 'input'>>;
  removeVendorFromActivityEventTopic?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType, RequireFields<MutationRemoveVendorFromActivityEventTopicArgs, 'input'>>;
  removeVendorFromVendorVariable?: Resolver<ResolversTypes['VendorVariable'], ParentType, ContextType, RequireFields<MutationRemoveVendorFromVendorVariableArgs, 'input'>>;
  removeVendorsFromClientTableConfig?: Resolver<ResolversTypes['ClientTableConfig'], ParentType, ContextType, RequireFields<MutationRemoveVendorsFromClientTableConfigArgs, 'input'>>;
  removeVendorsFromUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationRemoveVendorsFromUserArgs, 'input'>>;
  sendRawEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSendRawEmailArgs, 'input'>>;
  setAccountOnVendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType, RequireFields<MutationSetAccountOnVendorArgs, 'input'>>;
  setCalculatorOnDiamondOnsellController?: Resolver<ResolversTypes['DiamondOnsellController'], ParentType, ContextType, RequireFields<MutationSetCalculatorOnDiamondOnsellControllerArgs, 'input'>>;
  setDefaultShippingAddressOnAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationSetDefaultShippingAddressOnAccountArgs, 'input'>>;
  setEventsOnActivityEventTopic?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType, RequireFields<MutationSetEventsOnActivityEventTopicArgs, 'input'>>;
  setFeedPerspectiveOnAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationSetFeedPerspectiveOnAccountArgs, 'input'>>;
  setFeedPerspectiveOnAccountContact?: Resolver<ResolversTypes['AccountContact'], ParentType, ContextType, RequireFields<MutationSetFeedPerspectiveOnAccountContactArgs, 'input'>>;
  setMediaOnDiamondProduct?: Resolver<ResolversTypes['DiamondProduct'], ParentType, ContextType, RequireFields<MutationSetMediaOnDiamondProductArgs, 'input'>>;
  setMediaOnDiamondProductExternal?: Resolver<ResolversTypes['DiamondProductExternal'], ParentType, ContextType, RequireFields<MutationSetMediaOnDiamondProductExternalArgs, 'input'>>;
  setOwnerOnPhysicalAddress?: Resolver<ResolversTypes['PhysicalAddress'], ParentType, ContextType, RequireFields<MutationSetOwnerOnPhysicalAddressArgs, 'input'>>;
  setUpstreamVendorsOnDiamondOnsellController?: Resolver<ResolversTypes['DiamondOnsellController'], ParentType, ContextType, RequireFields<MutationSetUpstreamVendorsOnDiamondOnsellControllerArgs, 'input'>>;
  setVendorOnActivityEventTopic?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType, RequireFields<MutationSetVendorOnActivityEventTopicArgs, 'input'>>;
  setVendorOnVendorVariable?: Resolver<ResolversTypes['VendorVariable'], ParentType, ContextType, RequireFields<MutationSetVendorOnVendorVariableArgs, 'input'>>;
  setVendorsOnClientTableConfig?: Resolver<ResolversTypes['ClientTableConfig'], ParentType, ContextType, RequireFields<MutationSetVendorsOnClientTableConfigArgs, 'input'>>;
  setVendorsOnUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationSetVendorsOnUserArgs, 'input'>>;
  syncExternalUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationSyncExternalUserArgs, 'externalId'>>;
  updateManyAccountContacts?: Resolver<ResolversTypes['UpdateManyResponse'], ParentType, ContextType, RequireFields<MutationUpdateManyAccountContactsArgs, 'input'>>;
  updateManyAccounts?: Resolver<ResolversTypes['UpdateManyResponse'], ParentType, ContextType, RequireFields<MutationUpdateManyAccountsArgs, 'input'>>;
  updateManyDiamondProducts?: Resolver<ResolversTypes['UpdateManyResponse'], ParentType, ContextType, RequireFields<MutationUpdateManyDiamondProductsArgs, 'input'>>;
  updateManyPhysicalAddresses?: Resolver<ResolversTypes['UpdateManyResponse'], ParentType, ContextType, RequireFields<MutationUpdateManyPhysicalAddressesArgs, 'input'>>;
  updateManyVendors?: Resolver<ResolversTypes['UpdateManyResponse'], ParentType, ContextType, RequireFields<MutationUpdateManyVendorsArgs, 'input'>>;
  updateOneAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationUpdateOneAccountArgs, 'input'>>;
  updateOneAccountContact?: Resolver<ResolversTypes['AccountContact'], ParentType, ContextType, RequireFields<MutationUpdateOneAccountContactArgs, 'input'>>;
  updateOneActivityEvent?: Resolver<ResolversTypes['ActivityEvent'], ParentType, ContextType, RequireFields<MutationUpdateOneActivityEventArgs, 'input'>>;
  updateOneActivityEventTopic?: Resolver<ResolversTypes['ActivityEventTopic'], ParentType, ContextType, RequireFields<MutationUpdateOneActivityEventTopicArgs, 'input'>>;
  updateOneClientTableConfig?: Resolver<ResolversTypes['ClientTableConfig'], ParentType, ContextType, RequireFields<MutationUpdateOneClientTableConfigArgs, 'input'>>;
  updateOneDiamondBatchUploadProfile?: Resolver<ResolversTypes['DiamondBatchUploadProfile'], ParentType, ContextType, RequireFields<MutationUpdateOneDiamondBatchUploadProfileArgs, 'input'>>;
  updateOneDiamondFeedPerspective?: Resolver<ResolversTypes['DiamondFeedPerspective'], ParentType, ContextType, RequireFields<MutationUpdateOneDiamondFeedPerspectiveArgs, 'input'>>;
  updateOneDiamondOnsellController?: Resolver<ResolversTypes['DiamondOnsellController'], ParentType, ContextType, RequireFields<MutationUpdateOneDiamondOnsellControllerArgs, 'input'>>;
  updateOneDiamondProduct?: Resolver<ResolversTypes['DiamondProduct'], ParentType, ContextType, RequireFields<MutationUpdateOneDiamondProductArgs, 'input'>>;
  updateOneDiamondProductPricingCalculator?: Resolver<ResolversTypes['DiamondProductPricingCalculator'], ParentType, ContextType, RequireFields<MutationUpdateOneDiamondProductPricingCalculatorArgs, 'input'>>;
  updateOnePhysicalAddress?: Resolver<ResolversTypes['PhysicalAddress'], ParentType, ContextType, RequireFields<MutationUpdateOnePhysicalAddressArgs, 'input'>>;
  updateOneUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateOneUserArgs, 'input'>>;
  updateOneVendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType, RequireFields<MutationUpdateOneVendorArgs, 'input'>>;
  updateOneVendorVariable?: Resolver<ResolversTypes['VendorVariable'], ParentType, ContextType, RequireFields<MutationUpdateOneVendorVariableArgs, 'input'>>;
  uploadFileForBatchUploadJob?: Resolver<ResolversTypes['UploadFileForBatchUploadJobResponse'], ParentType, ContextType, RequireFields<MutationUploadFileForBatchUploadJobArgs, 'file'>>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['ConnectionCursor']>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPreviousPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['ConnectionCursor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddress'] = ResolversParentTypes['PhysicalAddress']> = {
  addressLines?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['CountryCode'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stateProvinceCountry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  zipOrPostcode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddressAggregateGroupBy'] = ResolversParentTypes['PhysicalAddressAggregateGroupBy']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddressConnection'] = ResolversParentTypes['PhysicalAddressConnection']> = {
  edges?: Resolver<Array<ResolversTypes['PhysicalAddressEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddressCountAggregate'] = ResolversParentTypes['PhysicalAddressCountAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddressDeleteResponse'] = ResolversParentTypes['PhysicalAddressDeleteResponse']> = {
  addressLines?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['CountryCode']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  stateProvinceCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  zipOrPostcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddressEdge'] = ResolversParentTypes['PhysicalAddressEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PhysicalAddress'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddressMaxAggregate'] = ResolversParentTypes['PhysicalAddressMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhysicalAddressMinAggregate'] = ResolversParentTypes['PhysicalAddressMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  account?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<QueryAccountArgs, 'id'>>;
  accountContact?: Resolver<Maybe<ResolversTypes['AccountContact']>, ParentType, ContextType, RequireFields<QueryAccountContactArgs, 'id'>>;
  accountContacts?: Resolver<ResolversTypes['AccountContactConnection'], ParentType, ContextType, RequireFields<QueryAccountContactsArgs, 'filter' | 'paging' | 'sorting'>>;
  accounts?: Resolver<ResolversTypes['AccountConnection'], ParentType, ContextType, RequireFields<QueryAccountsArgs, 'filter' | 'paging' | 'sorting'>>;
  activityEvent?: Resolver<Maybe<ResolversTypes['ActivityEvent']>, ParentType, ContextType, RequireFields<QueryActivityEventArgs, 'id'>>;
  activityEventTopic?: Resolver<Maybe<ResolversTypes['ActivityEventTopic']>, ParentType, ContextType, RequireFields<QueryActivityEventTopicArgs, 'id'>>;
  activityEventTopics?: Resolver<ResolversTypes['ActivityEventTopicConnection'], ParentType, ContextType, RequireFields<QueryActivityEventTopicsArgs, 'filter' | 'paging' | 'sorting'>>;
  activityEvents?: Resolver<ResolversTypes['ActivityEventConnection'], ParentType, ContextType, RequireFields<QueryActivityEventsArgs, 'filter' | 'paging' | 'sorting'>>;
  clientTableConfig?: Resolver<Maybe<ResolversTypes['ClientTableConfig']>, ParentType, ContextType, RequireFields<QueryClientTableConfigArgs, 'id'>>;
  clientTableConfigs?: Resolver<ResolversTypes['ClientTableConfigConnection'], ParentType, ContextType, RequireFields<QueryClientTableConfigsArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondBatchPricingJob?: Resolver<Maybe<ResolversTypes['DiamondBatchPricingJob']>, ParentType, ContextType, RequireFields<QueryDiamondBatchPricingJobArgs, 'id'>>;
  diamondBatchPricingJobs?: Resolver<ResolversTypes['DiamondBatchPricingJobConnection'], ParentType, ContextType, RequireFields<QueryDiamondBatchPricingJobsArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondBatchUploadJob?: Resolver<Maybe<ResolversTypes['DiamondBatchUploadJob']>, ParentType, ContextType, RequireFields<QueryDiamondBatchUploadJobArgs, 'id'>>;
  diamondBatchUploadJobs?: Resolver<ResolversTypes['DiamondBatchUploadJobConnection'], ParentType, ContextType, RequireFields<QueryDiamondBatchUploadJobsArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondBatchUploadProfile?: Resolver<Maybe<ResolversTypes['DiamondBatchUploadProfile']>, ParentType, ContextType, RequireFields<QueryDiamondBatchUploadProfileArgs, 'id'>>;
  diamondBatchUploadProfiles?: Resolver<ResolversTypes['DiamondBatchUploadProfileConnection'], ParentType, ContextType, RequireFields<QueryDiamondBatchUploadProfilesArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondFeedPerspective?: Resolver<Maybe<ResolversTypes['DiamondFeedPerspective']>, ParentType, ContextType, RequireFields<QueryDiamondFeedPerspectiveArgs, 'id'>>;
  diamondFeedPerspectives?: Resolver<ResolversTypes['DiamondFeedPerspectiveConnection'], ParentType, ContextType, RequireFields<QueryDiamondFeedPerspectivesArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondOnsellController?: Resolver<Maybe<ResolversTypes['DiamondOnsellController']>, ParentType, ContextType, RequireFields<QueryDiamondOnsellControllerArgs, 'id'>>;
  diamondOnsellControllers?: Resolver<ResolversTypes['DiamondOnsellControllerConnection'], ParentType, ContextType, RequireFields<QueryDiamondOnsellControllersArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondProduct?: Resolver<Maybe<ResolversTypes['DiamondProduct']>, ParentType, ContextType, RequireFields<QueryDiamondProductArgs, 'id'>>;
  diamondProductAggregate?: Resolver<Array<ResolversTypes['DiamondProductAggregateResponse']>, ParentType, ContextType, Partial<QueryDiamondProductAggregateArgs>>;
  diamondProductArchiveByCertificate?: Resolver<Maybe<ResolversTypes['DiamondProductArchiveCertificateKey']>, ParentType, ContextType, RequireFields<QueryDiamondProductArchiveByCertificateArgs, 'query'>>;
  diamondProductArchiveByVendorRef?: Resolver<Maybe<ResolversTypes['DiamondProductArchiveVendorKey']>, ParentType, ContextType, RequireFields<QueryDiamondProductArchiveByVendorRefArgs, 'query'>>;
  diamondProductArchives?: Resolver<Array<ResolversTypes['DiamondProductArchive']>, ParentType, ContextType, RequireFields<QueryDiamondProductArchivesArgs, 'id'>>;
  diamondProductExternal?: Resolver<Maybe<ResolversTypes['DiamondProductExternal']>, ParentType, ContextType, RequireFields<QueryDiamondProductExternalArgs, 'id'>>;
  diamondProductPricingCalculator?: Resolver<Maybe<ResolversTypes['DiamondProductPricingCalculator']>, ParentType, ContextType, RequireFields<QueryDiamondProductPricingCalculatorArgs, 'id'>>;
  diamondProductPricingCalculators?: Resolver<ResolversTypes['DiamondProductPricingCalculatorConnection'], ParentType, ContextType, RequireFields<QueryDiamondProductPricingCalculatorsArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondProducts?: Resolver<ResolversTypes['DiamondProductConnection'], ParentType, ContextType, RequireFields<QueryDiamondProductsArgs, 'filter' | 'paging' | 'sorting'>>;
  diamondProductsExternal?: Resolver<ResolversTypes['DiamondProductExternalConnection'], ParentType, ContextType, RequireFields<QueryDiamondProductsExternalArgs, 'filter' | 'paging' | 'sorting'>>;
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  physicalAddress?: Resolver<Maybe<ResolversTypes['PhysicalAddress']>, ParentType, ContextType, RequireFields<QueryPhysicalAddressArgs, 'id'>>;
  physicalAddresses?: Resolver<ResolversTypes['PhysicalAddressConnection'], ParentType, ContextType, RequireFields<QueryPhysicalAddressesArgs, 'filter' | 'paging' | 'sorting'>>;
  searchExternalUsers?: Resolver<Array<ResolversTypes['ExternalUser']>, ParentType, ContextType, Partial<QuerySearchExternalUsersArgs>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  users?: Resolver<ResolversTypes['UserConnection'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'filter' | 'paging' | 'sorting'>>;
  vendor?: Resolver<Maybe<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<QueryVendorArgs, 'id'>>;
  vendorByShortname?: Resolver<Maybe<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<QueryVendorByShortnameArgs, 'shortname'>>;
  vendorVariable?: Resolver<Maybe<ResolversTypes['VendorVariable']>, ParentType, ContextType, RequireFields<QueryVendorVariableArgs, 'id'>>;
  vendorVariables?: Resolver<ResolversTypes['VendorVariableConnection'], ParentType, ContextType, RequireFields<QueryVendorVariablesArgs, 'filter' | 'paging' | 'sorting'>>;
  vendors?: Resolver<ResolversTypes['VendorConnection'], ParentType, ContextType, RequireFields<QueryVendorsArgs, 'filter' | 'paging' | 'sorting'>>;
};

export type UpdateManyResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateManyResponse'] = ResolversParentTypes['UpdateManyResponse']> = {
  updatedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UploadFileForBatchUploadJobResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadFileForBatchUploadJobResponse'] = ResolversParentTypes['UploadFileForBatchUploadJobResponse']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schema?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  auth0Id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  vendors?: Resolver<Array<ResolversTypes['Vendor']>, ParentType, ContextType, RequireFields<UserVendorsArgs, 'filter' | 'sorting'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAggregateGroupBy'] = ResolversParentTypes['UserAggregateGroupBy']> = {
  auth0Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConnection'] = ResolversParentTypes['UserConnection']> = {
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCountAggregate'] = ResolversParentTypes['UserCountAggregate']> = {
  auth0Id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMaxAggregate'] = ResolversParentTypes['UserMaxAggregate']> = {
  auth0Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMinAggregate'] = ResolversParentTypes['UserMinAggregate']> = {
  auth0Id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vendor'] = ResolversParentTypes['Vendor']> = {
  account?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType>;
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  diamondOnsellController?: Resolver<Maybe<ResolversTypes['DiamondOnsellController']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorAggregateGroupBy'] = ResolversParentTypes['VendorAggregateGroupBy']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  shortname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorConnection'] = ResolversParentTypes['VendorConnection']> = {
  edges?: Resolver<Array<ResolversTypes['VendorEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorCountAggregate'] = ResolversParentTypes['VendorCountAggregate']> = {
  accountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shortname?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorDeleteResponse'] = ResolversParentTypes['VendorDeleteResponse']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorEdge'] = ResolversParentTypes['VendorEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorMaxAggregate'] = ResolversParentTypes['VendorMaxAggregate']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  shortname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorMinAggregate'] = ResolversParentTypes['VendorMinAggregate']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  shortname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariable'] = ResolversParentTypes['VendorVariable']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['VendorVariableGroup'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['VendorVariableType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
  vendorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableAggregateGroupByResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariableAggregateGroupBy'] = ResolversParentTypes['VendorVariableAggregateGroupBy']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariableConnection'] = ResolversParentTypes['VendorVariableConnection']> = {
  edges?: Resolver<Array<ResolversTypes['VendorVariableEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariableCountAggregate'] = ResolversParentTypes['VendorVariableCountAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableDeleteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariableDeleteResponse'] = ResolversParentTypes['VendorVariableDeleteResponse']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['VendorVariableGroup']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['VendorVariableType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariableEdge'] = ResolversParentTypes['VendorVariableEdge']> = {
  cursor?: Resolver<ResolversTypes['ConnectionCursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['VendorVariable'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariableMaxAggregate'] = ResolversParentTypes['VendorVariableMaxAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorVariableMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VendorVariableMinAggregate'] = ResolversParentTypes['VendorVariableMinAggregate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vendorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Account?: AccountResolvers<ContextType>;
  AccountAggregateGroupBy?: AccountAggregateGroupByResolvers<ContextType>;
  AccountConnection?: AccountConnectionResolvers<ContextType>;
  AccountContact?: AccountContactResolvers<ContextType>;
  AccountContactAggregateGroupBy?: AccountContactAggregateGroupByResolvers<ContextType>;
  AccountContactConnection?: AccountContactConnectionResolvers<ContextType>;
  AccountContactCountAggregate?: AccountContactCountAggregateResolvers<ContextType>;
  AccountContactDeleteResponse?: AccountContactDeleteResponseResolvers<ContextType>;
  AccountContactEdge?: AccountContactEdgeResolvers<ContextType>;
  AccountContactMaxAggregate?: AccountContactMaxAggregateResolvers<ContextType>;
  AccountContactMinAggregate?: AccountContactMinAggregateResolvers<ContextType>;
  AccountCountAggregate?: AccountCountAggregateResolvers<ContextType>;
  AccountDeleteResponse?: AccountDeleteResponseResolvers<ContextType>;
  AccountEdge?: AccountEdgeResolvers<ContextType>;
  AccountMaxAggregate?: AccountMaxAggregateResolvers<ContextType>;
  AccountMinAggregate?: AccountMinAggregateResolvers<ContextType>;
  ActivityEvent?: ActivityEventResolvers<ContextType>;
  ActivityEventAggregateGroupBy?: ActivityEventAggregateGroupByResolvers<ContextType>;
  ActivityEventConnection?: ActivityEventConnectionResolvers<ContextType>;
  ActivityEventCountAggregate?: ActivityEventCountAggregateResolvers<ContextType>;
  ActivityEventDeleteResponse?: ActivityEventDeleteResponseResolvers<ContextType>;
  ActivityEventEdge?: ActivityEventEdgeResolvers<ContextType>;
  ActivityEventMaxAggregate?: ActivityEventMaxAggregateResolvers<ContextType>;
  ActivityEventMinAggregate?: ActivityEventMinAggregateResolvers<ContextType>;
  ActivityEventTopic?: ActivityEventTopicResolvers<ContextType>;
  ActivityEventTopicAggregateGroupBy?: ActivityEventTopicAggregateGroupByResolvers<ContextType>;
  ActivityEventTopicConnection?: ActivityEventTopicConnectionResolvers<ContextType>;
  ActivityEventTopicCountAggregate?: ActivityEventTopicCountAggregateResolvers<ContextType>;
  ActivityEventTopicEdge?: ActivityEventTopicEdgeResolvers<ContextType>;
  ActivityEventTopicEventsConnection?: ActivityEventTopicEventsConnectionResolvers<ContextType>;
  ActivityEventTopicMaxAggregate?: ActivityEventTopicMaxAggregateResolvers<ContextType>;
  ActivityEventTopicMinAggregate?: ActivityEventTopicMinAggregateResolvers<ContextType>;
  ClientTableConfig?: ClientTableConfigResolvers<ContextType>;
  ClientTableConfigAggregateGroupBy?: ClientTableConfigAggregateGroupByResolvers<ContextType>;
  ClientTableConfigConnection?: ClientTableConfigConnectionResolvers<ContextType>;
  ClientTableConfigCountAggregate?: ClientTableConfigCountAggregateResolvers<ContextType>;
  ClientTableConfigDeleteResponse?: ClientTableConfigDeleteResponseResolvers<ContextType>;
  ClientTableConfigEdge?: ClientTableConfigEdgeResolvers<ContextType>;
  ClientTableConfigMaxAggregate?: ClientTableConfigMaxAggregateResolvers<ContextType>;
  ClientTableConfigMinAggregate?: ClientTableConfigMinAggregateResolvers<ContextType>;
  ConnectionCursor?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DeleteManyResponse?: DeleteManyResponseResolvers<ContextType>;
  DiamondBatchPricingJob?: DiamondBatchPricingJobResolvers<ContextType>;
  DiamondBatchPricingJobAggregateGroupBy?: DiamondBatchPricingJobAggregateGroupByResolvers<ContextType>;
  DiamondBatchPricingJobConnection?: DiamondBatchPricingJobConnectionResolvers<ContextType>;
  DiamondBatchPricingJobCountAggregate?: DiamondBatchPricingJobCountAggregateResolvers<ContextType>;
  DiamondBatchPricingJobEdge?: DiamondBatchPricingJobEdgeResolvers<ContextType>;
  DiamondBatchPricingJobMaxAggregate?: DiamondBatchPricingJobMaxAggregateResolvers<ContextType>;
  DiamondBatchPricingJobMinAggregate?: DiamondBatchPricingJobMinAggregateResolvers<ContextType>;
  DiamondBatchUploadJob?: DiamondBatchUploadJobResolvers<ContextType>;
  DiamondBatchUploadJobAggregateGroupBy?: DiamondBatchUploadJobAggregateGroupByResolvers<ContextType>;
  DiamondBatchUploadJobConnection?: DiamondBatchUploadJobConnectionResolvers<ContextType>;
  DiamondBatchUploadJobCountAggregate?: DiamondBatchUploadJobCountAggregateResolvers<ContextType>;
  DiamondBatchUploadJobEdge?: DiamondBatchUploadJobEdgeResolvers<ContextType>;
  DiamondBatchUploadJobMaxAggregate?: DiamondBatchUploadJobMaxAggregateResolvers<ContextType>;
  DiamondBatchUploadJobMinAggregate?: DiamondBatchUploadJobMinAggregateResolvers<ContextType>;
  DiamondBatchUploadProfile?: DiamondBatchUploadProfileResolvers<ContextType>;
  DiamondBatchUploadProfileAggregateGroupBy?: DiamondBatchUploadProfileAggregateGroupByResolvers<ContextType>;
  DiamondBatchUploadProfileConnection?: DiamondBatchUploadProfileConnectionResolvers<ContextType>;
  DiamondBatchUploadProfileCountAggregate?: DiamondBatchUploadProfileCountAggregateResolvers<ContextType>;
  DiamondBatchUploadProfileDeleteResponse?: DiamondBatchUploadProfileDeleteResponseResolvers<ContextType>;
  DiamondBatchUploadProfileEdge?: DiamondBatchUploadProfileEdgeResolvers<ContextType>;
  DiamondBatchUploadProfileMaxAggregate?: DiamondBatchUploadProfileMaxAggregateResolvers<ContextType>;
  DiamondBatchUploadProfileMinAggregate?: DiamondBatchUploadProfileMinAggregateResolvers<ContextType>;
  DiamondFeedPerspective?: DiamondFeedPerspectiveResolvers<ContextType>;
  DiamondFeedPerspectiveAggregateGroupBy?: DiamondFeedPerspectiveAggregateGroupByResolvers<ContextType>;
  DiamondFeedPerspectiveConnection?: DiamondFeedPerspectiveConnectionResolvers<ContextType>;
  DiamondFeedPerspectiveCountAggregate?: DiamondFeedPerspectiveCountAggregateResolvers<ContextType>;
  DiamondFeedPerspectiveDeleteResponse?: DiamondFeedPerspectiveDeleteResponseResolvers<ContextType>;
  DiamondFeedPerspectiveEdge?: DiamondFeedPerspectiveEdgeResolvers<ContextType>;
  DiamondFeedPerspectiveMaxAggregate?: DiamondFeedPerspectiveMaxAggregateResolvers<ContextType>;
  DiamondFeedPerspectiveMinAggregate?: DiamondFeedPerspectiveMinAggregateResolvers<ContextType>;
  DiamondOnsellController?: DiamondOnsellControllerResolvers<ContextType>;
  DiamondOnsellControllerAggregateGroupBy?: DiamondOnsellControllerAggregateGroupByResolvers<ContextType>;
  DiamondOnsellControllerConnection?: DiamondOnsellControllerConnectionResolvers<ContextType>;
  DiamondOnsellControllerCountAggregate?: DiamondOnsellControllerCountAggregateResolvers<ContextType>;
  DiamondOnsellControllerDeleteResponse?: DiamondOnsellControllerDeleteResponseResolvers<ContextType>;
  DiamondOnsellControllerEdge?: DiamondOnsellControllerEdgeResolvers<ContextType>;
  DiamondOnsellControllerMaxAggregate?: DiamondOnsellControllerMaxAggregateResolvers<ContextType>;
  DiamondOnsellControllerMinAggregate?: DiamondOnsellControllerMinAggregateResolvers<ContextType>;
  DiamondProduct?: DiamondProductResolvers<ContextType>;
  DiamondProductAggregateGroupBy?: DiamondProductAggregateGroupByResolvers<ContextType>;
  DiamondProductAggregateResponse?: DiamondProductAggregateResponseResolvers<ContextType>;
  DiamondProductArchive?: DiamondProductArchiveResolvers<ContextType>;
  DiamondProductArchiveCertificateKey?: DiamondProductArchiveCertificateKeyResolvers<ContextType>;
  DiamondProductArchiveVendorKey?: DiamondProductArchiveVendorKeyResolvers<ContextType>;
  DiamondProductAvgAggregate?: DiamondProductAvgAggregateResolvers<ContextType>;
  DiamondProductConnection?: DiamondProductConnectionResolvers<ContextType>;
  DiamondProductCountAggregate?: DiamondProductCountAggregateResolvers<ContextType>;
  DiamondProductEdge?: DiamondProductEdgeResolvers<ContextType>;
  DiamondProductExternal?: DiamondProductExternalResolvers<ContextType>;
  DiamondProductExternalAggregateGroupBy?: DiamondProductExternalAggregateGroupByResolvers<ContextType>;
  DiamondProductExternalAvgAggregate?: DiamondProductExternalAvgAggregateResolvers<ContextType>;
  DiamondProductExternalConnection?: DiamondProductExternalConnectionResolvers<ContextType>;
  DiamondProductExternalCountAggregate?: DiamondProductExternalCountAggregateResolvers<ContextType>;
  DiamondProductExternalEdge?: DiamondProductExternalEdgeResolvers<ContextType>;
  DiamondProductExternalMaxAggregate?: DiamondProductExternalMaxAggregateResolvers<ContextType>;
  DiamondProductExternalMinAggregate?: DiamondProductExternalMinAggregateResolvers<ContextType>;
  DiamondProductExternalSumAggregate?: DiamondProductExternalSumAggregateResolvers<ContextType>;
  DiamondProductMaxAggregate?: DiamondProductMaxAggregateResolvers<ContextType>;
  DiamondProductMedia?: DiamondProductMediaResolvers<ContextType>;
  DiamondProductMediaAggregateGroupBy?: DiamondProductMediaAggregateGroupByResolvers<ContextType>;
  DiamondProductMediaAggregateResponse?: DiamondProductMediaAggregateResponseResolvers<ContextType>;
  DiamondProductMediaCountAggregate?: DiamondProductMediaCountAggregateResolvers<ContextType>;
  DiamondProductMediaMaxAggregate?: DiamondProductMediaMaxAggregateResolvers<ContextType>;
  DiamondProductMediaMinAggregate?: DiamondProductMediaMinAggregateResolvers<ContextType>;
  DiamondProductMinAggregate?: DiamondProductMinAggregateResolvers<ContextType>;
  DiamondProductPricingCalculator?: DiamondProductPricingCalculatorResolvers<ContextType>;
  DiamondProductPricingCalculatorAggregateGroupBy?: DiamondProductPricingCalculatorAggregateGroupByResolvers<ContextType>;
  DiamondProductPricingCalculatorConnection?: DiamondProductPricingCalculatorConnectionResolvers<ContextType>;
  DiamondProductPricingCalculatorCountAggregate?: DiamondProductPricingCalculatorCountAggregateResolvers<ContextType>;
  DiamondProductPricingCalculatorDeleteResponse?: DiamondProductPricingCalculatorDeleteResponseResolvers<ContextType>;
  DiamondProductPricingCalculatorEdge?: DiamondProductPricingCalculatorEdgeResolvers<ContextType>;
  DiamondProductPricingCalculatorMaxAggregate?: DiamondProductPricingCalculatorMaxAggregateResolvers<ContextType>;
  DiamondProductPricingCalculatorMinAggregate?: DiamondProductPricingCalculatorMinAggregateResolvers<ContextType>;
  DiamondProductSumAggregate?: DiamondProductSumAggregateResolvers<ContextType>;
  ExternalUser?: ExternalUserResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Mutation?: MutationResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PhysicalAddress?: PhysicalAddressResolvers<ContextType>;
  PhysicalAddressAggregateGroupBy?: PhysicalAddressAggregateGroupByResolvers<ContextType>;
  PhysicalAddressConnection?: PhysicalAddressConnectionResolvers<ContextType>;
  PhysicalAddressCountAggregate?: PhysicalAddressCountAggregateResolvers<ContextType>;
  PhysicalAddressDeleteResponse?: PhysicalAddressDeleteResponseResolvers<ContextType>;
  PhysicalAddressEdge?: PhysicalAddressEdgeResolvers<ContextType>;
  PhysicalAddressMaxAggregate?: PhysicalAddressMaxAggregateResolvers<ContextType>;
  PhysicalAddressMinAggregate?: PhysicalAddressMinAggregateResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  UpdateManyResponse?: UpdateManyResponseResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UploadFileForBatchUploadJobResponse?: UploadFileForBatchUploadJobResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAggregateGroupBy?: UserAggregateGroupByResolvers<ContextType>;
  UserConnection?: UserConnectionResolvers<ContextType>;
  UserCountAggregate?: UserCountAggregateResolvers<ContextType>;
  UserEdge?: UserEdgeResolvers<ContextType>;
  UserMaxAggregate?: UserMaxAggregateResolvers<ContextType>;
  UserMinAggregate?: UserMinAggregateResolvers<ContextType>;
  Vendor?: VendorResolvers<ContextType>;
  VendorAggregateGroupBy?: VendorAggregateGroupByResolvers<ContextType>;
  VendorConnection?: VendorConnectionResolvers<ContextType>;
  VendorCountAggregate?: VendorCountAggregateResolvers<ContextType>;
  VendorDeleteResponse?: VendorDeleteResponseResolvers<ContextType>;
  VendorEdge?: VendorEdgeResolvers<ContextType>;
  VendorMaxAggregate?: VendorMaxAggregateResolvers<ContextType>;
  VendorMinAggregate?: VendorMinAggregateResolvers<ContextType>;
  VendorVariable?: VendorVariableResolvers<ContextType>;
  VendorVariableAggregateGroupBy?: VendorVariableAggregateGroupByResolvers<ContextType>;
  VendorVariableConnection?: VendorVariableConnectionResolvers<ContextType>;
  VendorVariableCountAggregate?: VendorVariableCountAggregateResolvers<ContextType>;
  VendorVariableDeleteResponse?: VendorVariableDeleteResponseResolvers<ContextType>;
  VendorVariableEdge?: VendorVariableEdgeResolvers<ContextType>;
  VendorVariableMaxAggregate?: VendorVariableMaxAggregateResolvers<ContextType>;
  VendorVariableMinAggregate?: VendorVariableMinAggregateResolvers<ContextType>;
};


export const UserFragmentDoc = gql`
    fragment user on User {
  id
  nickname
  name
  description
  email
  emailVerified
  auth0Id
}
    `;
export const VendorFragmentDoc = gql`
    fragment vendor on Vendor {
  id
  createdAt
  updatedAt
  accountId
  name
  shortname
  description
}
    `;
export const DiamondProductFragmentDoc = gql`
    fragment diamondProduct on DiamondProduct {
  id
  createdAt
  updatedAt
  vendorId
  vendorRef
  certificationId
  certificationProvider
  growthProcess
  caratWeight
  shape
  cutGrade
  polishGrade
  symmetryGrade
  clarityGrade
  lusterGrade
  milkyGrade
  length
  width
  depth
  table
  crownAngle
  pavillionAngle
  lengthWidthRatio
  depthPercentage
  tablePercentage
  crownHeightPercentage
  pavillionDepthPercentage
  girdleMin
  girdleMax
  girdleThicknessPercentage
  colorGrade
  fancyColorPrimary
  fancyColorSecondary
  fancyColorIntensityGrade
  colorDescription
  fluorescenceIntensityGrade
  fluorescenceColorPrimary
  fluorescenceDescription
  currency
  price
  pricePerCarat
  status
  hasVideoMedia
  hasImageMedia
  hasCertificateMedia
  sarineJourney
  sarineLightPerformance
  tableInclusion
  tableInclusionBlack
  crownInclusion
  crownInclusionBlack
  rapnetDiscountPercentage
  origin
  comments
  customPrice
  customPricePerCarat
  upstreamProductId
}
    `;
export const FeedPerspectiveFragmentDoc = gql`
    fragment feedPerspective on DiamondFeedPerspective {
  id
  createdAt
  updatedAt
  vendorId
  name
  description
  filter
  priceExpression
}
    `;
export const UsersDocument = gql`
    query users($filter: UserFilter, $paging: CursorPaging, $sorting: [UserSort!]) {
  users(filter: $filter, paging: $paging, sorting: $sorting) {
    edges {
      node {
        ...user
        vendors {
          ...vendor
        }
      }
    }
  }
}
    ${UserFragmentDoc}
${VendorFragmentDoc}`;

export function useUsersQuery(options: Omit<Urql.UseQueryArgs<never, UsersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UsersQuery>({ query: UsersDocument, ...options });
};
export const SearchExternalUsersDocument = gql`
    query searchExternalUsers($query: String) {
  searchExternalUsers(query: $query) {
    externalId
    name
    nickname
    email
    userId
  }
}
    `;

export function useSearchExternalUsersQuery(options: Omit<Urql.UseQueryArgs<never, SearchExternalUsersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SearchExternalUsersQuery>({ query: SearchExternalUsersDocument, ...options });
};
export const MeDocument = gql`
    query me {
  me {
    ...user
  }
}
    ${UserFragmentDoc}`;

export function useMeQuery(options: Omit<Urql.UseQueryArgs<never, MeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MeQuery>({ query: MeDocument, ...options });
};
export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    ...user
    vendors {
      ...vendor
    }
  }
}
    ${UserFragmentDoc}
${VendorFragmentDoc}`;

export function useUserQuery(options: Omit<Urql.UseQueryArgs<never, UserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};
export const UpdateOneUserDocument = gql`
    mutation updateOneUser($input: UpdateOneUserInput!) {
  updateOneUser(input: $input) {
    ...user
    vendors {
      ...vendor
    }
  }
}
    ${UserFragmentDoc}
${VendorFragmentDoc}`;

export function useUpdateOneUserMutation() {
  return Urql.useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(UpdateOneUserDocument);
};
export const AddVendorsToUserDocument = gql`
    mutation addVendorsToUser($input: AddVendorsToUserInput!) {
  addVendorsToUser(input: $input) {
    ...user
    vendors {
      ...vendor
    }
  }
}
    ${UserFragmentDoc}
${VendorFragmentDoc}`;

export function useAddVendorsToUserMutation() {
  return Urql.useMutation<AddVendorsToUserMutation, AddVendorsToUserMutationVariables>(AddVendorsToUserDocument);
};
export const RemoveVendorsFromUserDocument = gql`
    mutation removeVendorsFromUser($input: RemoveVendorsFromUserInput!) {
  removeVendorsFromUser(input: $input) {
    ...user
    vendors {
      ...vendor
    }
  }
}
    ${UserFragmentDoc}
${VendorFragmentDoc}`;

export function useRemoveVendorsFromUserMutation() {
  return Urql.useMutation<RemoveVendorsFromUserMutation, RemoveVendorsFromUserMutationVariables>(RemoveVendorsFromUserDocument);
};
export const SyncExternalUserDocument = gql`
    mutation syncExternalUser($externalId: String!) {
  syncExternalUser(externalId: $externalId) {
    ...user
    vendors {
      ...vendor
    }
  }
}
    ${UserFragmentDoc}
${VendorFragmentDoc}`;

export function useSyncExternalUserMutation() {
  return Urql.useMutation<SyncExternalUserMutation, SyncExternalUserMutationVariables>(SyncExternalUserDocument);
};
export const VendorsDocument = gql`
    query vendors($filter: VendorFilter, $paging: CursorPaging, $sorting: [VendorSort!]) {
  vendors(filter: $filter, paging: $paging, sorting: $sorting) {
    edges {
      node {
        ...vendor
      }
    }
  }
}
    ${VendorFragmentDoc}`;

export function useVendorsQuery(options: Omit<Urql.UseQueryArgs<never, VendorsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<VendorsQuery>({ query: VendorsDocument, ...options });
};
export const VendorDocument = gql`
    query vendor($id: ID!) {
  vendor(id: $id) {
    ...vendor
  }
}
    ${VendorFragmentDoc}`;

export function useVendorQuery(options: Omit<Urql.UseQueryArgs<never, VendorQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<VendorQuery>({ query: VendorDocument, ...options });
};