/**
 * a helper that will fetch a value from local storage at the given key if it exists,
 * otherwise it will use the given provider to create a value and store it
 * @param key
 * @param provider
 * @returns { value, hit } returns a value and a hit flag if the value was pulled from storage
 */
export async function useStorage<T>(
  key: string,
  provider: () => Promise<T> | T
): Promise<{ value: T; hit: boolean }> {
  let raw = await localStorage.getItem(key);

  if (raw) {
    return { value: JSON.parse(raw) as T, hit: true };
  } else {
    let value = await provider();
    let valueAsString = JSON.stringify(value);

    localStorage.setItem(key, valueAsString);

    return { value, hit: false };
  }
}
