import { IOrder } from "@/utils/IOrder";
import { ref, watch } from "vue";

import { useFulfilment } from "./fulfilment";
import { useVendor } from "./vendor";

const { currentVendor } = useVendor();
const supplier = ref(null as null | any);
const isLoading = ref(false);
const { client } = useFulfilment();

async function fetchSupplier() {
  if (!client.value) throw "Client not ready yet";
  if (!currentVendor.value) throw "No vendor configured";

  isLoading.value = true;
  try {
    console.log(`Getting supplier for ${currentVendor.value.shortname}...`);
    let result = await client.value.get(
      `/fulfilment/supplier/${currentVendor.value.shortname}`
    );

    supplier.value = result.data.supplier;

    console.log({ supplier });
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
}

watch(currentVendor, () => {
  fetchSupplier();
});

export function useSupplier() {
  return {
    isLoading,
    supplier,
  };
}
