<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.95 126.41">
    <title>Landscape Colour</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          style="fill: #8bd5ac"
          d="M55.75,0a62.41,62.41,0,0,0-9.56,1.45l.05,113.74A54.17,54.17,0,0,1,10,82.14H0a63.52,63.52,0,0,0,55.75,44.27Zm19,115.2v-104A54.16,54.16,0,0,1,109,39.69h10.36A63.53,63.53,0,0,0,65.24,0V126.41A63.53,63.53,0,0,0,121,82.14H111A54.14,54.14,0,0,1,74.72,115.2Z"
        />
      </g>
    </g>
  </svg>
</template>
