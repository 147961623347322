import { MeDocument, useMeQuery } from "@/utils/operations";
import { ref, watch } from "vue";
import { useAuth } from "./auth";
import { useUrql } from "./urql";

const auth = useAuth();
const user = ref(null as null | any);
const isLoading = ref(false);
const error = ref(null as null | any);

const { urql } = useUrql();

const fetchUser = () => {
  let client = urql.value;
  if (!client) return;

  isLoading.value = true;
  error.value = null;
  client
    .query(MeDocument)
    .toPromise()
    .then((result) => {
      // user.value = result.data;
      console.log({ result });
      user.value = result.data?.me || null;
    })
    .catch((err) => {
      console.error(err);
      error.value = err;
      user.value = null;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

watch([auth.token], () => {
  fetchUser();
});

/**
 * The current concord user.
 * Depends on the auth composable.
 * @returns
 */
export function useUser() {
  if (auth.token) fetchUser();
  return {
    user,
    isLoading,
    error,
  };
}
