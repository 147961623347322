<template>
  <div class="space-y-4">
    <div
      class="grid grid-cols-5 h-8 gap-4 animate-pulse"
      v-for="i in 3"
      :key="i"
    >
      <div class="col-span-2 bg-gray-300 rounded-md"></div>
      <div class="bg-gray-300 rounded-md"></div>
      <div class="bg-gray-300 rounded-md"></div>
      <div class="bg-gray-300 rounded-md"></div>
    </div>
  </div>
</template>
