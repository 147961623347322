import { IOrder } from "@/utils/IOrder";
import { ref, watch } from "vue";

import { useFulfilment } from "./fulfilment";
import { useVendor } from "./vendor";

const { currentVendor } = useVendor();
const orders = ref([] as IOrder[]);
const isLoading = ref(false);
const isReady = ref(false);
const { client } = useFulfilment();

async function fetchOrders() {
  if (!client.value) throw "Client not ready yet";
  if (!currentVendor.value) throw "No vendor configured";

  isLoading.value = true;
  try {
    console.log(`Getting orders for ${currentVendor.value.shortname}...`);
    let result = await client.value.post("/fulfilment/items/list", {
      supplier: currentVendor.value.shortname,
      filter: { supplierShortname: { EQ: currentVendor.value.shortname } },
    });
    let { records } = result.data;
    orders.value = [
      ...records.map((r: any) => ({
        ...r,
        supplier: r.supplierShortname && r.supplierShortname[0],
      })),
    ];
  } catch (error) {
    console.error(error);
  }
  console.log({ orders });
  isLoading.value = false;
  isReady.value = true;
}

watch(currentVendor, () => {
  fetchOrders();
});

export function useOrders() {
  return {
    isLoading,
    isReady,
    orders,
    client,
    refresh: () => fetchOrders(),
  };
}
