
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { defineComponent, onMounted, PropType, ref, watch } from "vue";

export default defineComponent({
  components: {
    ExclamationCircleIcon,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    validate: Function as PropType<
      (value: string | null | undefined) => boolean
    >,
  },

  emits: ["update:modelValue", "update:invalid"],

  setup(props, { emit }) {
    const isInvalid = ref(false);

    watch(
      () => props.invalid,
      (value) => {
        isInvalid.value = value;
      }
    );

    const updateInvalid = (value: string | undefined) => {
      if (props.validate) {
        isInvalid.value = !props.validate(value);
        emit("update:invalid", isInvalid.value);
      }
    };

    const onInput = (value: string | undefined) => {
      console.log("onInput", { value });
      updateInvalid(value);

      if (props.modelValue !== value) emit("update:modelValue", value);
    };

    onMounted(() => updateInvalid(props.modelValue));

    return {
      isInvalid,
      onInput,
    };
  },
});
