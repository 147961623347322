import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./index.css";
import { createRouter, createWebHistory } from "vue-router";

import DashboardView from "./views/Dashboard.vue";
import OrdersView from "./views/Orders.vue";

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/dashboard",
      component: DashboardView,
    },
    {
      path: "/orders",
      component: OrdersView,
    },
  ], // short for `routes: routes`
});

createApp(App).use(router).mount("#app");
