import axios, { AxiosInstance } from "axios";
import { ref, watch } from "vue";

import { useAuth } from "./auth";

const SERVICE_URL = process.env.VUE_APP_JC_FUNCTIONS_URL;

const auth = useAuth();
const client = ref(null as null | AxiosInstance);

function createClient(token: string) {
  client.value = axios.create({
    baseURL: SERVICE_URL,
    headers: {
      authorization: "Bearer " + token,
    },
  });
}

watch(auth.token, (token) => {
  if (token) createClient(token);
});

export function useFulfilment() {
  return {
    client,
  };
}
