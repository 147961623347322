import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative rounded-md shadow-sm" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps(_ctx.$attrs, {
      type: "text",
      class: ["block w-full text-sm rounded-md", [
        _ctx.isInvalid
          ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
          : 'theme border-gray-300',
      ]],
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event.target.value)))
    }), null, 16, _hoisted_2),
    (_ctx.isInvalid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ExclamationCircleIcon, {
            class: "h-5 w-5 text-red-500",
            "aria-hidden": "true"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}