import { Vendor, VendorsDocument } from "@/utils/operations";
import { readonly, ref, watch } from "vue";
import { useAuth } from "./auth";
import { useUrql } from "./urql";
import {
  find,
  first,
  ListIterateeCustom,
  ListIteratorTypeGuard,
  ObjectIterateeCustom,
} from "lodash";
import { useStorage } from "@/utils/useStorage";

const auth = useAuth();
const { urql } = useUrql();

const currentVendor = ref(null as null | Vendor);
const vendors = ref([] as Vendor[]);
const isLoading = ref(false);
const error = ref(null) as null | any;

async function fetchVendors() {
  let client = urql.value!;

  isLoading.value = true;
  try {
    error.value = null;
    let result = await client
      .query(VendorsDocument, {
        paging: {
          first: 100,
        },
      })
      .toPromise();

    if (result.error) {
      throw result.error;
    }

    vendors.value = [
      ...(result.data.vendors.edges.map((e: any) => e.node) as Vendor[]),
    ];

    if (!currentVendor.value) {
      let cv = await useStorage("jc-vendor", () => {
        let f = first(vendors.value);
        return f;
      });

      console.log("Current vendor", { cv });
      if (cv.value) currentVendor.value = cv.value;
    }
    console.log({ result });
  } catch (err) {
    console.error(err);
    window.alert(err);
    error.value = err;
    isLoading.value = false;
  }
}

watch([auth.token], () => {
  fetchVendors();
});

export function useVendor() {
  return {
    isLoading,
    error,
    currentVendor: readonly(currentVendor),
    vendors: readonly(vendors),
    setVendor<S extends Vendor>(
      predicate: ListIterateeCustom<Vendor, boolean>
    ) {
      let v = find(vendors.value, predicate);

      if (!v) throw "No vendor mathed the given predicate";

      currentVendor.value = v;
      localStorage.setItem("jc-vendor", JSON.stringify(v));
    },
  };
}
